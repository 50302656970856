
import { FC } from 'react';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Box from '@mui/material/Box';
import StickyFooter from '../Components/Partials/Footer';
import { useTheme } from '@mui/material';

const Layout: FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Box sx={ { display: 'flex' } }>
      <Topbar />
      <Sidebar />
      <Box sx={ { width: '100%', my: 1 } }>
        <Box sx={ { ...theme.mixins.toolbar } } />
        <Box sx={{ width: '100%', minHeight: '480px', mb: 10, clear: 'both'}}>
        { children }
        </Box>
        <StickyFooter />
      </Box>
    </Box>
  )
}

export default Layout;
