
import { FC } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ProtectedRoute from './HOCs/ProtectedRoute';
// import UnPrivateRoute from './HOCs/UnPrivateRoute';
// Pages
import Layout from './Layout/Layout';
import Login from './Login';
import Home from './Home';
import Profile from './Pages/Profile';
import Compliance from './Compliance';
import Authorities from './Authorities';
import List from './List';
import Page from './Page';
import { msalConfig, scopes } from './Auth/config.auth';
import UserContext from './Context/User.context';
import ThemeContext from './Context/Theme.context';

// localhost start: npm run build && swa start build --api-location ../control-functions
// or 'swa start' - kicks off dev environment from config file (swa-cli.config.json)

export const instance = new PublicClientApplication(msalConfig);
instance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    instance.setActiveAccount(account);
    const request = { account, scopes }
    instance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    });
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log('login failure', JSON.stringify(event));
  }
});

const App: FC = () => {
  return (
    <ThemeContext>
      <Router>
        <MsalProvider instance={ instance }>
          <AuthenticatedTemplate>
            <UserContext>
              <Layout>
                <Routes>
                  <Route path='/' element={ <Home /> } />
                  <Route path='/profile' element={ <Profile /> } />
                  <Route path='/authorities' element={ <Authorities /> } />
                  <Route path='/compliance' element={ <Compliance /> } />
                  <Route path='/:collection' element={ <List /> } />
                  <Route path='/:collection/:id' element={ <Page /> } />
                </Routes>
              </Layout>
            </UserContext>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </MsalProvider>
      </Router>
    </ThemeContext>
  )
}

export default App;
