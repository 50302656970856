
import { FC, useContext, useState } from 'react';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import logo from '../Components/Images/GNCI_Logo.png';
import { useColorMode } from '../Context/Theme.context';
import { useTheme } from '@mui/material';
import { TopbarIcons } from '../Helpers/IconMap.helper';
import { useMsal } from '@azure/msal-react';
import { oddCaps } from '../Helpers/Tools.helper';
import { useUserContext } from '../Context/User.context';
import { Utility } from '../Services/General.service';

const PrimarySearchAppBar: FC = () => {
  const { user } = useUserContext();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { toggleMode } = useColorMode();
  const theme = useTheme();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ mobileMoreAnchorEl, setMobileMoreAnchorEl ] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleLogOut = () => {
    return instance.logoutRedirect({ account });
  }
  const handleUtility = () => {
    Utility().then((data) => {
      // console.log(data);
    });
  }
  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const DarkMode: FC = () => {
    return (
      <>
        Dark Mode <TopbarIcons fontSize='small' sx={ { ml: 1 } } item='dark' />
      </>
    )
  }
  const LightMode: FC = () => {
    return (
      <>
        Light Mode <TopbarIcons fontSize='small' sx={ { ml: 1 } } item='light' />
      </>
    )
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={ anchorEl }
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      id={ menuId }
      keepMounted
      transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      open={ isMenuOpen }
      onClose={ handleMenuClose }
    >
      <MenuItem onClick={ handleMenuClose } component={ Link } href='/profile'>Profile</MenuItem>
      <MenuItem onClick={ () => {toggleMode(); handleMenuClose()}  }>
        { theme.palette.mode === 'light' ?
          <DarkMode />
          :
          <LightMode />
        }
      </MenuItem>
      <MenuItem onClick={ handleMenuClose } component={ Link } href='/authorities'>Authorities</MenuItem>
      { user.username === 'markm@gnciwireless.com' ?
        <MenuItem onClick={ handleUtility }>Utility</MenuItem>
        : null }
      <MenuItem onClick={ handleLogOut }>Sign Out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={ mobileMoreAnchorEl }
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      id={ mobileMenuId }
      keepMounted
      transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      open={ isMobileMenuOpen }
      onClose={ handleMobileMenuClose }
    >
      {/* <MenuItem>
        <IconButton size='large' aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={ 4 } color='error'>
            <TopbarIcons item='mail' />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton
          size='large'
          aria-label='show 17 new notifications'
          color='inherit'
        >
          <Badge badgeContent={ 17 } color='error'>
            <TopbarIcons item='notification' />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={ handleProfileMenuOpen }>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <TopbarIcons item='account' />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={ { flexGrow: 1 } }>
      <AppBar
        position='fixed'
        sx={ { zIndex: (theme) => theme.zIndex.drawer + 1 } }
      >
        <Toolbar>
          <Link href='/' underline='none'>
            <img
              src={ logo }
              alt='GNCI'
              style={ {
                height: '40px',
                marginRight: '20px',
              } }
            />
          </Link>
          <Typography
            variant='h1'
            style={ {
              fontSize: 28,
              fontWeight: 500,
            } }
            noWrap
            component='div'
            sx={ { display: { xs: 'none', sm: 'block' } } }
          >
            Resource Planning
          </Typography>
          <Box sx={ { flexGrow: 1 } } />
          <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
            {/* <IconButton
              size='large'
              aria-label='show 4 new mails'
              color='inherit'
            >
              <Badge badgeContent={ 4 } color='error'>
                <TopbarIcons item='mail' />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size='large'
              aria-label='show 17 new notifications'
              color='inherit'
            >
              <Badge badgeContent={ 17 } color='error'>
                <TopbarIcons item='notification' />
              </Badge>
            </IconButton> */}
            <IconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-controls={ menuId }
              aria-haspopup='true'
              onClick={ handleProfileMenuOpen }
              color='inherit'
            >
              <TopbarIcons item='account' />
            </IconButton>
          </Box>
          <Box sx={ { display: { xs: 'flex', md: 'none' } } }>
            <IconButton
              size='large'
              aria-label='show more'
              aria-controls={ mobileMenuId }
              aria-haspopup='true'
              onClick={ handleMobileMenuOpen }
              color='inherit'
            >
              <TopbarIcons item='more' />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      { renderMobileMenu }
      { renderMenu }
    </Box>
  );
}

export default PrimarySearchAppBar;
