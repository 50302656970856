
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Job, JobOptions } from '../../lib/classes';
import { InputText, InputSelect, InputMultiSelectTags, InputTextarea, InputNumber } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

interface Props {
  job: Job;
  options: JobOptions | any;
}

const JobForm: FC<Props> = ({ job, options }) => {
  const methods = useForm({ defaultValues: job, shouldUnregister: true });
  const { watch } = methods;
  const executive = watch('title') === 'ceo' || watch('title') === 'vp';
  const ceo = watch('title') === 'ceo';

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid id='organization' item xs={ 12 } container spacing={ 2 }>
              <Grid item md={ 2 } xs={ 12 }>
                <InputSelect
                  name='title'
                  label='Title'
                  options={ options.title }
                  tip
                  required
                  value_as_label
                />
              </Grid>
              <Grid item md={ 3 } xs={ 12 }>
                { !ceo ? <InputText label='Descriptor' name='descriptor' /> : null }
              </Grid>
              <Grid item md={ 7 } xs={ 12 }>
                <InputText label='Abstract' name='description' />
              </Grid>
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item xs={ 12 }>
                  { executive ?
                    <InputMultiSelectTags name='domains' label='Domains' options={ options.domains } value_as_label />
                    : <InputMultiSelectTags name='departments' label='Departments' options={ options.departments } value_as_label />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid id='hr' item xs={ 12 } container spacing={ 2 }>
              <Grid item xs={ 12 } >
                <Typography variant='h3'>HR</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <InputText label='Job Description' name='job_description' multiline minRows={ 3 } />
              </Grid>
              <Grid item md={ 6 } xs={ 12 }>
                <InputTextarea label='Required Skills' name='skills.required' multiline minRows={ 5 } />
              </Grid>
              <Grid item md={ 6 } xs={ 12 }>
                <InputTextarea label='Preferred Skills' name='skills.preferred' multiline minRows={ 5 } />
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='h4'>Compensation</Typography>
              </Grid>
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item md={ 4 } xs={ 12 } container spacing={ 2 } sx={{ mt: 0 }}>
                  <Grid item xs={ 12 }>
                    <Typography variant='body1'>Minimum</Typography>
                  </Grid>
                  <Grid item xs={ 6 }>
                    <InputNumber name='compensation.min.rate' label='Pay' />
                  </Grid>
                  <Grid item xs={ 6 }>
                    <InputSelect name='compensation.min.per' label='Per' options={ options.pay_per } tip value_as_label />
                  </Grid>
                </Grid>
                <Grid item md={ 4 } xs={ 12 } container spacing={ 2 } sx={{ mt: 0 }}>
                  <Grid item xs={ 12 }>
                    <Typography variant='body1'>Maximum</Typography>
                  </Grid>
                  <Grid item xs={ 6 }>
                    <InputNumber name='compensation.max.rate' label='Pay' />
                  </Grid>
                  <Grid item xs={ 6 }>
                    <InputSelect name='compensation.max.per' label='Per' options={ options.pay_per } tip value_as_label />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default JobForm;
