
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
// import ActivityForm from '../Components/Forms/Activity.form';
import Grid from '@mui/material/Grid';
import { Activity, ActivityOptions } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';
import PagerHeader from './_PageHeader';
import Paper from '@mui/material/Paper';

const ActivityPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Activity>(new Activity());
  const [ edit, setEdit ] = useState<Activity>(new Activity());
  const [ options, setOptions ] = useState<ActivityOptions>(new ActivityOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }

  return (
    <Grid container>
      { isLoaded ?
      <Grid container>
      <PagerHeader title={ read.name } handler={ readEditHandler } hideForm={ hideForm } />
      { hideForm  && id !== 'add' ?
        <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }></Grid>
        : null }
        { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
            {/* <ActivityForm activity={ edit } options={ options } /> */}
          </Grid>
          : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default ActivityPage;
