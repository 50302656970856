
import { FC, useState, useEffect } from 'react';
import { ProfileRead, ProfileUpdate } from '../Services/General.service'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProfileForm from '../Components/Forms/Profile.form';
import Waiting from '../Components/Partials/Waiting';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper } from '@mui/material';
import { User, UserOptions } from '../lib/classes';
import { formatDistanceToNow, intervalToDuration } from 'date-fns';
import PagerHeader from './_PageHeader';
import { ExpandMore } from '@mui/icons-material';
import pluralize from 'pluralize';
import { PhoneData, EmailData, StaffData, UserJobs, UserManagers, UserRoles } from '../Components/Tables/User.table';
import { getCountry } from '../Helpers/StaticData.helper';
import { dateFormatted } from '../Helpers/Tools.helper';
interface Props {
  read: User;
  options: UserOptions;
  edit: User;
}

const ProfilePage: FC = () => {
  const [ read, setRead ] = useState<User>(new User());
  const [ edit, setEdit ] = useState<User>(new User());
  const [ options, setOptions ] = useState<UserOptions>(new UserOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    ProfileRead().then((data: Props) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, []);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const emailData = [
    { type: 'work', address: read.username },
    { type: 'recovery', address: read.alt_email },
  ]
  const siteAddress: any = read.site ? read.site.real_estate.address : null;
  const tenure = intervalToDuration({ start: new Date(read.dates.start), end: read.dates.end ? new Date(read.dates.end) : new Date() });

  return (
    <Box m={ 1 }>
      <Grid container>
        { isLoaded ?
          <Grid container>
            <PagerHeader title={ read.display_name.def } handler={ readEditHandler } hideForm={ hideForm } />
            { hideForm ?
              <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
                <Grid id='name' item xs={ 12 } container spacing={ 8 } direction='row' border='20px white'>
                  <Grid id='formalAndTitle' item>
                    <Typography variant='h2'>
                      { read.display_name.formal }
                      { !read.active ?
                        <Typography variant='overline' sx={ { border: '2px solid red', px: 2, ml: 2 } } color='action'>Inactive</Typography>
                        : null }
                    </Typography>
                    { read.job ?
                      <Link href={ `/job/${read.job._id}` } underline='hover'>
                        <Typography variant='h3' sx={ { mt: 1 } }>{ read.job.title }</Typography>
                      </Link>
                      : null }
                  </Grid>
                  { read.name.nick ?
                    <Grid item>
                      <Typography variant='overline'><small>Nickname</small></Typography>
                      <Typography variant='body2'>{ read.name.nick }</Typography>
                    </Grid>
                    : null }
                  { read.name.preferred ?
                    <Grid item>
                      <Typography variant='overline'><small>Preferred First Name</small></Typography>
                      <Typography variant='body2'>{ read.name.preferred }</Typography>
                    </Grid>
                    : null }
                </Grid>
                <Grid id='middle' item xs={ 12 } container spacing={ 4 }>
                  <Grid id='contact' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                      <Typography variant='h5'>Phones</Typography>
                      <PhoneData data={ read.phones } />
                      <Typography variant='h5' sx={ { mt: 2 } }>Email</Typography>
                      <EmailData data={ emailData } />
                    </Grid>
                  </Grid>
                  <Grid id='role' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                      <Typography variant='h5'>Manager</Typography>
                      <Link href={ `/user/${read.manager._id}` } underline='hover'>
                        <Typography sx={ { ml: 3 } }>{ read.manager.name }</Typography>
                      </Link>
                      <Typography sx={ { ml: 3 } } variant='body2'>{ read.manager.title }</Typography>
                    </Grid>
                    { read.staff && read.staff.length > 0 ?
                      <Grid item xs={ 12 }>
                        <Typography variant='h5'>Staff</Typography>
                        <StaffData data={ read.staff } />
                      </Grid>
                      : null }
                  </Grid>
                  <Grid id='trivia' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                      <Typography>You { read.dates.end ? 'were' : 'have been' } with GNCI for { formatDistanceToNow(new Date(read.dates.start)) }.</Typography>
                      <Box ml={ 2 }>
                        <Typography variant='body2'>
                          { tenure.years ? `${tenure.years} ${pluralize('Year', tenure.years)},` : null }
                          { tenure.months ? `${tenure.months} ${pluralize('Month', tenure.months)},` : null }
                          { tenure.days ? `${tenure.days} ${pluralize('Day', tenure.days)}.` : null }
                        </Typography>
                        { read.dates.end ?
                          <Typography variant='body2'>From { dateFormatted(read.dates.start) } to { dateFormatted(read.dates.end) }</Typography>
                          : <Typography variant='body2'>Since { dateFormatted(read.dates.start) }</Typography>
                        }
                      </Box>
                      { siteAddress ?
                        <Box mt={ 2 }>
                          <Typography >Your primary work location { read.dates.end ? 'was' : 'is' }:</Typography>
                          <Box ml={ 3 }>
                            <Typography>{ siteAddress.name }</Typography>
                            <Typography variant='body2'>{ siteAddress.street1 }</Typography>
                            { siteAddress.street2 ?
                              <Typography variant='body2'>{ siteAddress.street2 }</Typography>
                              : null }
                            <Typography variant='body2'>{ siteAddress.city }, { siteAddress.state }  { siteAddress.zip }</Typography>
                            { siteAddress.country !== 'US' ?
                              <Typography variant='body2'>{ getCountry({ value: siteAddress.country, response_element: 'proper', value_element: 'tld' }) }</Typography>
                              : null }
                          </Box>
                        </Box>
                        : null }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={ 12 }>
                  <Accordion>
                    <AccordionSummary expandIcon={ <ExpandMore /> }>Job History ({ read.jobs.length })</AccordionSummary>
                    <AccordionDetails>
                      <UserJobs data={ read.jobs } />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={ <ExpandMore /> }>Managers ({ read.managers.length })</AccordionSummary>
                    <AccordionDetails>
                      <UserManagers data={ read.managers } />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={ <ExpandMore /> }>Compliance Roles ({ read.roles.length })</AccordionSummary>
                    <AccordionDetails>
                      <UserRoles data={ read.roles } />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              : null }
            { !hideForm ?
              <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
                <ProfileForm user={ edit } options={ options } />
              </Grid>
              : null }
          </Grid>
          : <Waiting /> }
      </Grid>
    </Box>
  )
}

export default ProfilePage;
