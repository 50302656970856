
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Hardware from './AssetHardware';
import Software from './AssetSoftware';
import ServiceProvider from './AssetServiceProvider';
import System from './AssetSystem';
import Data from './AssetData';
import RealEstate from './AssetRealEstate';
import SupportContract from './AssetSupportContract';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import { Asset, AssetOptions } from '../../lib/classes';
import { InputDate, InputSelect, InputText, InputMultiSelectTags } from './_Input';
import Typography from '@mui/material/Typography';

export interface AssetFormProps {
  asset?: Asset | any;
  options: AssetOptions | any;
}

const AssetForm: FC<AssetFormProps> = ({ asset, options }) => {
  const methods = useForm({ defaultValues: asset, shouldUnregister: true });
  const { watch } = methods;

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid id='basics' item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='category'
                label='Asset Category'
                required
                options={ options.category }
                tip
                value_as_label
              />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputText name='name' label='Name' required />
            </Grid>
            <Grid item md={ 6 } xs={ 12 }>
              <InputText name='description' label='Description' required />
            </Grid>
          </Grid>
          <Grid id='data_protection' item container>
            <Grid item xs={ 12 } sx={ { pb: 1 } }>
              <Typography variant='h2'>Data Protection</Typography>
            </Grid>
            <Grid item xs={ 12 } container spacing={ 2 }>
              <Grid item md={ 3 } xs={ 12 }>
                <InputSelect
                  name='custodian'
                  label='Asset Manager'
                  required
                  options={ options.users }
                  tip
                />
              </Grid>
              <Grid item md={ 3 } xs={ 12 }>
                <InputSelect
                  name='data_protection.security.classification'
                  label='Security Classification'
                  required
                  options={ options.data_protection.security.classification }
                  tip
                />
              </Grid>
              <Grid item md={ 6 } xs={ 12 }>
                <InputText name='data_protection.security.rationale' label='Security Classification Rationale' required />
              </Grid>
              <Grid item xs={ 12 }>
                <InputMultiSelectTags
                  name='data_protection.tags'
                  label='Security Tags'
                  options={ options.data_protection.tags }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id='ownership' item container>
            <Grid item xs={ 12 } sx={ { pb: 1 } }>
              <Typography variant='h2'>Ownership</Typography>
            </Grid>
            <Grid item xs={ 12 } container spacing={ 2 }>
              <Grid item md={ 3 } xs={ 12 }>
                <InputDate
                  label='Date Acquired'
                  required
                  name='dates.acquired'
                />
              </Grid>
              <Grid item md={ 3 } xs={ 12 }>
                <InputSelect
                  label='Ownership'
                  required
                  options={ options.ownership }
                  tip
                  value_as_label
                  name='ownership'
                />
              </Grid>
              { watch('ownership') === 'user' ?
                <Grid item md={ 3 } xs={ 12 }>
                  <InputSelect
                    label='User'
                    required={ watch('ownership') === 'user' }
                    options={ options.users }
                    tip
                    name='owner'
                  />
                </Grid>
                : watch('ownership') === 'service_provider' ?
                  <Grid item md={ 3 } xs={ 12 }>
                    <InputSelect
                      label='Owner Organization'
                      required={ watch('ownership') === 'service_provider' }
                      options={ options.organizations }
                      tip
                      name='owner_org'
                    />
                  </Grid>
                  : null }
            </Grid>
          </Grid>
          <Grid item xs={ 12 } container spacing={ 2 }>
            { watch('category') === 'hardware' ?
              <Hardware asset={ asset } options={ options } />
              : watch('category') === 'software' ?
                <Software asset={ asset } options={ options } />
                : watch('category') === 'service_provider' ?
                  <ServiceProvider options={ options } />
                  : watch('category') === 'system' ?
                    <System asset={ asset } options={ options } />
                    : watch('category') === 'data' ?
                      <Data asset={ asset } options={ options } />
                      : watch('category') === 'real_estate' ?
                        <RealEstate options={ options } />
                        : watch('category') === 'support_contract' ?
                          <SupportContract options={ options } />
                          : null }
            <Grid item xs={ 12 }>
              <InputText name='notes' label='Notes' multiline minRows={ 3 } />
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid>
  )
}

export default AssetForm
