
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import ControlForm from '../Components/Forms/Control.form';
import Grid from '@mui/material/Grid';
import { Approval, Control, ControlOptions, Mechanism, Request } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import pluralize from 'pluralize';
import PagerHeader from './_PageHeader';
import startCase from 'lodash/startCase';
import { CollectionIcons, MiscIcons } from '../Helpers/IconMap.helper';
import ApprovalPart from '../Components/Partials/Approval.partial';

const ControlPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Control>(new Control());
  const [ edit, setEdit ] = useState<Control>(new Control());
  const [ options, setOptions ] = useState<ControlOptions>(new ControlOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      // console.log(data)
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const approval: any = read.approval || new Approval();
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader
            title={ `${read.name} (${read.control_id})` }
            handler={ readEditHandler }
            hideForm={ hideForm }
            approval={ edit.approval }
            options={ options.approval }
          />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ read.status.value === 'approved' ? 12 : 9 }>
                <Typography variant='h4'>Objective</Typography>
                <Typography variant='body1' whiteSpace='pre-wrap' color='primary'>{ read.objective }</Typography>
                <Typography variant='body2' whiteSpace='pre-wrap'>{ read.notes }</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='h4'>Standard</Typography>
                <Typography variant='body2' whiteSpace='pre-wrap'>{ read.standard }</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='h4'>Guidelines</Typography>
                <List dense sx={ { pr: 2 } }>
                  { read.guidelines?.map((item, index) => {
                    return (
                      <ListItem key={ `guideline-${index}` } dense>
                        <ListItemIcon sx={ { mx: 0 } }>
                          <MiscIcons item='checkmark' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary={ item } />
                      </ListItem>
                    )
                  }) }
                </List>
              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Typography variant='body2'>
                  { read.name } is supported by { read.mechanisms.length } Control { pluralize('Mechanism', read.mechanisms.length) }:
                </Typography>
                <List sx={ { pr: 2 } } dense>
                  { read.mechanisms.map((mechanism: Mechanism) => {
                    mechanism.name = mechanism.documents.length > 0 ? `${mechanism.name} - ${mechanism.documents.length} ${pluralize('attachment', mechanism.documents.length)}` : mechanism.name;
                    return (
                      <Link key={ mechanism._id } href={ `/mechanism/${mechanism._id}` } underline='hover'>
                        <ListItem dense>
                          <ListItemIcon>
                            <CollectionIcons collection='mechanism' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                            primary={ `${mechanism.name} (${startCase(mechanism.status)})` }
                            secondary={ mechanism.description }
                          />
                        </ListItem>
                      </Link>
                    )
                  }) }
                </List>
              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Typography variant='body2'>
                  Customers requesting compliance information on this Control:
                </Typography>
                <List sx={ { pr: 2 } } dense>
                  { read.requests.map((request: any) => {
                    return (
                      <Link key={ request._id } href={ `/request/${request._id}` } underline='hover'>
                        <ListItem dense>
                          <ListItemIcon>
                            <CollectionIcons collection='request' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                            primary={ request.customer.name }
                            secondary={ request.name }
                          />
                        </ListItem>
                      </Link>
                    )
                  }) }
                </List>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='h3'>Approval</Typography>
                <ApprovalPart approval={ approval } status={ read.status } />
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <ControlForm control={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default ControlPage;
