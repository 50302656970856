
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import startCase from 'lodash/startCase';
import { dateFormatted } from '../../Helpers/Tools.helper';
import Box from '@mui/material/Box';

const ByLine: FC<any> = ({ approval }) => {
  const { collection } = useParams();
  const original_status = approval && approval.status ? approval.status : null;
  const pending_status = original_status === 'pending';
  const text = () => {
    const prepend = collection !== 'mechanism' ? <strong>{ startCase(collection) }: </strong> : null;
    const status = original_status && !pending_status ? startCase(original_status) : 'Pending Review';
    const approver = !pending_status && approval.by && approval.by.display_name ? `by ${approval.by.display_name.def}` : null;
    const date = !pending_status && approval.date ? `on ${dateFormatted(approval.date)}` : null;
    return <span>{ prepend } { status } { approver } { date }</span>
  }
  return <Typography variant='body1'>{ text() }</Typography>
}
const Notes: FC<any> = ({ approval }) => {
  const explanation = () => {
    switch (approval.status) {
      default: return approval.explanation ? approval.explanation : null;
      case 'approved': return approval.explanation ? `Approval Reasoning: ${approval.explanation}` : null;
      case 'denied': return approval.explanation ? `Denial Reasoning: ${approval.explanation}` : null;
    }
  }
  return (
    <Box ml={ 2 }>
      <Typography variant='body2'>{ explanation() }</Typography>
      <Typography variant='body2'>{ approval.notes }</Typography>
    </Box>
  )
}
const Mechanisms: FC<any> = ({ status }) => {
  const mechanisms = status.mechanisms;
  return (
    <>
      <Typography variant='body1'>
        <strong>Mechanisms:</strong> { mechanisms }
      </Typography>
    </>
  )
}
const Controls: FC<any> = ({ status }) => {
  const controls = status.controls
  return (
    <>
      <Typography variant='body1'>
        <strong>Controls:</strong> { controls }
      </Typography>
    </>
  )
}

type IApprovalPartial = {
  approval: any;
  status?: any;
}
const ApprovalPart: FC<IApprovalPartial> = ({ approval, status }) => {
  const { collection } = useParams();
  return (
    <Box>
      { collection === 'mechanism' ?
        <Box>
          <ByLine approval={ approval } />
          <Notes approval={ approval } />
        </Box>
        : collection === 'control' ?
          <Box>
            <Typography variant='body1'><strong>Status: </strong>{ startCase(status.value) }</Typography>
            <ByLine approval={ approval } />
            <Notes approval={ approval } />
            <Mechanisms status={ status } />
          </Box>
          : collection === 'policy' ?
            <Box>
              <Typography variant='body1'><strong>Status: </strong>{ startCase(status.value) }</Typography>
              <ByLine approval={ approval } />
              <Notes approval={ approval } />
              <Controls status={ status } />
            </Box>
            : null }
    </Box>

  )
}

export default ApprovalPart;
