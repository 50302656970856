
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AllDocuments } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';
import { Document } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';

interface Props {
  documents: Document[];
}

const Documents: FC = () => {
  const { collection } = useParams();
  const [ documents, setDocuments ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { documents } = data;
      setDocuments(documents);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <AllDocuments data={ documents } />
        : <Waiting /> }
    </>
  )
}

export default Documents;
