
 import { FC } from 'react';
 import Link from '@mui/material/Link';
 import Typography from '@mui/material/Typography';

 const Copyright = () => {
	return (
		<Typography variant='body2' style={{paddingTop: '5px'}}>
			{`© ${new Date().getFullYear()} `}
			<Link color='inherit' href='https://gnciwireless.com/'>
				GNCI
			</Link>
			{'.  All rights reserved.'}
		</Typography>
	);
}

export default Copyright;
