
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Mechanism, MechanismOptions } from '../../lib/classes';
import { InputText, InputNumber, InputMultiSelectTags, InputDate, InputSwitch } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Stack } from '@mui/material';

interface Props {
  mechanism: Mechanism | any;
  options: MechanismOptions | any;
}

const MechanismForm: FC<Props> = ({ mechanism, options }: any) => {
  const methods = useForm({ defaultValues: mechanism, shouldUnregister: true });
  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item md={ 3 } xs={ 12 }>
            <InputText label='Name' name='name' required />
          </Grid>
          <Tooltip title="Types description" placement='top-start'>
            <Grid item md={ 3 } xs={ 12 }>
              <InputMultiSelectTags
                name='type'
                label='Protection Modalities'
                options={ options.type }
                value_as_label
                tip
                required
              />
            </Grid>
          </Tooltip>
          <Tooltip title="Mechanisms are designed to Prevent, Detect and/or Correct (fix) access to Assets." placement='top-start'>
            <Grid item md={ 3 } xs={ 12 }>
              <InputMultiSelectTags
                name='goals'
                label='Aims'
                options={ options.goals }
                value_as_label
                required
              />
            </Grid>
          </Tooltip>
          <Grid item xs={ 12 }>
            <InputText name='description' label='Description' multiline minRows={ 2 } />
          </Grid>
          <Tooltip title="Controls addressed by this Mechanism" placement='top-start'>
            <Grid item xs={ 12 }>
              <InputMultiSelectTags
                name='controls'
                label='Controls'
                options={ options.controls }
              />
            </Grid>
          </Tooltip>
          <Tooltip title="Additional documentation for this Mechanism" placement='top-start'>
            <Grid item xs={ 12 }>
              <InputMultiSelectTags
                name='documents'
                label='Additional Documentation'
                options={ options.documents }
              />
            </Grid>
          </Tooltip>
          <Tooltip title="Assets used to provide this Mechanism">
            <Grid item md={ 6 } xs={ 12 }>
              <InputMultiSelectTags
                name='providers'
                label='Provider Assets'
                options={ options.assets }
              />
            </Grid>
          </Tooltip>
          <Tooltip title="Assets protected by this Mechanism">
            <Grid item md={ 6 } xs={ 12 }>
              <InputMultiSelectTags
                name='consumers'
                label='Protected Assets'
                options={ options.assets }
              />
            </Grid>
          </Tooltip>
          <Grid item xs={ 12 }>
            <InputText name='notes' label='Notes' multiline minRows={ 3 } />
          </Grid>
          <Grid id='initial' item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 4 }>
              <Stack spacing={ 1 }>
                <InputDate name='initial.start' label='Effort Start' />
                <InputNumber name='initial.est_duration' label='Time to Complete (days)' />
                <InputSwitch name='initial.complete' label='Ready for Audit' />
              </Stack>
            </Grid>
            <Grid item xs={ 4 }>
              <InputText name='initial.notes' label='Audit Readiness Notes' multiline minRows={ 4 } />
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default MechanismForm;
