
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import ContactForm from '../Components/Forms/Contact.form';
import Waiting from '../Components/Partials/Waiting';
import Grid from '@mui/material/Grid';
import { Contact, ContactOptions } from '../lib/classes';
import Paper from '@mui/material/Paper';
import PagerHeader from './_PageHeader';

const ContactPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Contact>(new Contact());
  const [ edit, setEdit ] = useState<Contact>(new Contact());
  const [ options, setOptions ] = useState<ContactOptions>(new ContactOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const headerTitle = (name: string, company: any) => {
    return `${name} - ${company.name}`
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ headerTitle(read.display_name.def, read.company) } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }></Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <ContactForm contact={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default ContactPage;
