
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AllControls } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';
import { Policy } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';

interface Props {
  controls: Policy[];
}

const Controls: FC = () => {
  const { collection } = useParams();
  const [ controls, setControls ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { controls } = data;
      setControls(controls);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <AllControls data={ controls } />
        : <Waiting /> }
    </>
  )
}

export default Controls;
