
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import RequestForm from '../Components/Forms/Request.form';
import InquiriesTable from '../Components/Tables/Inquiries.table';
import Waiting from '../Components/Partials/Waiting';
import Grid from '@mui/material/Grid';
import { Request, RequestOptions } from '../lib/classes';
import Paper from '@mui/material/Paper';
import PagerHeader from './_PageHeader';
import { Typography } from '@mui/material';

const RequestPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Request>(new Request());
  const [ edit, setEdit ] = useState<Request>(new Request());
  const [ options, setOptions ] = useState<RequestOptions>(new RequestOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const headerTitle = (name: string, company: any) => {
    return `${name} - ${company.name!}`;
  }
  const customerStuff = (company: any) => {
    return company.name;
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ headerTitle(read.name, read.customer) } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Typography color='primary'>{ read.description }</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='body2'>
                  This Compliance Request from { customerStuff(read.customer) } has { read.inquiries.length } inquiries:
                </Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <InquiriesTable data={ read.inquiries } />
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <RequestForm request={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default RequestPage;
