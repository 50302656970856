
export const compliance_roles = {
  category: [
    { code: 'sp', description: 'Conceptualizes, designs, procures, and/or builds secure information technology (IT) systems, with responsibility for aspects of system and/or network development.' },
    { code: 'om', description: 'Provides the support, administration, and maintenance necessary to ensure effective and efficient information technology (IT) system performance and security.' },
    { code: 'ov', description: 'Provides leadership, management, direction, or development and advocacy so the organization may effectively conduct cybersecurity work.' },
    { code: 'pr', description: 'Identifies, analyzes, and mitigates threats to internal information technology (IT) systems and/or networks.' },
    { code: 'an', description: 'Performs highly-specialized review and evaluation of incoming cybersecurity information to determine its usefulness for intelligence.' },
    { code: 'co', description: 'Provides specialized denial and deception operations and collection of cybersecurity information that may be used to develop intelligence.' },
    { code: 'in', description: 'Investigates cybersecurity events or crimes related to information technology (IT) systems, networks, and digital evidence.' },
  ],
  specialty: [
    { code: 'rsk', description: "Oversees, evaluates, and supports the documentation, validation, assessment, and authorization processes necessary to assure that existing and new information technology (IT) systems meet the organization's cybersecurity and risk requirements. Ensures appropriate treatment of risk, compliance, and assurance from internal and external perspectives." },
    { code: 'dev', description: 'Develops and writes/codes new (or modifies existing) computer applications, software, or specialized utility programs following software assurance best practices.' },
    { code: 'arc', description: 'Develops system concepts and works on the capabilities phases of the systems development life cycle; translates technology and environmental conditions (e.g., law and regulation) into system and security designs and processes.' },
    { code: 'trd', description: 'Conducts technology assessment and integration processes; provides and supports a prototype capability and/or evaluates its utility.' },
    { code: 'srp', description: 'Consults with customers to gather and evaluate functional requirements and translates these requirements into technical solutions. Provides guidance to customers about applicability of systems to meet business needs.' },
    { code: 'tst', description: 'Develops and conducts tests of systems to evaluate compliance with specifications and requirements by applying principles and methods for cost- effective planning, evaluating, verifying, and validating of technical, functional, and performance characteristics (including interoperability) of systems or elements of systems incorporating IT.' },
    { code: 'sys', description: 'Works on the development phases of the systems development life cycle.' },
    { code: 'dta', description: 'Develops and administers databases and/or data management systems that allow for the storage, query, protection, and utilization of data.' },
    { code: 'kmg', description: 'Manages and administers processes and tools that enable the organization to identify, document, and access intellectual capital and information content.' },
    { code: 'sts', description: 'Addresses problems; installs, configures, troubleshoots, and provides maintenance and training in response to customer requirements or inquiries (e.g., tiered-level customer support). Typically provides initial incident information to the Incident Response (IR) Specialty.' },
    { code: 'net', description: 'Installs, configures, tests, operates, maintains, and manages networks and their firewalls, including hardware (e.g., hubs, bridges, switches, multiplexers, routers, cables, proxy servers, and protective distributor systems) and software that permit the sharing and transmission of all spectrum transmissions of information to support the security of information and systems.' },
    { code: 'adm', description: 'Installs, configures, troubleshoots, and maintains server configurations (hardware and software) to ensure their confidentiality, integrity, and availability. Manages accounts, firewalls, and patches. Responsible for access control, passwords, and account creation and administration.' },
    { code: 'ana', description: "Studies an organization's current computer systems and procedures, and designs systems solutions to help the organization operate more securely, efficiently, and effectively. Brings business and information technology (IT) together by understanding the needs and limitations of both." },
    { code: 'lga', description: 'Provides legally sound advice and recommendations to leadership and staff on a variety of relevant topics within the pertinent subject domain. Advocates legal and policy changes, and makes a case on behalf of client via a wide range of written and oral work products, including legal briefs and proceedings.' },
    { code: 'tea', description: 'Conducts training of personnel within pertinent subject domain. Develops, plans, coordinates, delivers and/or evaluates training courses, methods, and techniques as appropriate.' },
    { code: 'mgt', description: 'Oversees the cybersecurity program of an system or network, including managing information security implications within the organization, specific program, or other area of responsibility, to include strategic, personnel, infrastructure, requirements, policy enforcement, emergency planning, security awareness, and other resources.' },
    { code: 'spp', description: 'Develops policies and plans and/or advocates for changes in policy that support organizational cyberspace initiatives or required changes/enhancements.' },
    { code: 'exl', description: 'Supervises, manages, and/or leads work and workers performing cyber and cyber-related and/or cyber operations work.' },
    { code: 'pma', description: 'Applies knowledge of data, information, processes, organizational interactions, skills, and analytical expertise, as well as systems, networks, and information exchange capabilities to manage acquisition programs. Executes duties governing hardware, software, and system acquisition programs and other program management policies. Provides direct support for acquisitions that use information technology (IT) (including National Security Systems), applying IT-related laws and policies, and provides IT-related guidance throughout the total acquisition life cycle.' },
    { code: 'cda', description: 'Uses defensive measures and information collected from a variety of sources to identify, analyze, and report events that occur or might occur within the network to protect information, systems, and networks from threats.' },
    { code: 'inf', description: 'Tests, implements, deploys, maintains, reviews, and administers the infrastructure hardware and software that are required to effectively manage the computer network defense service provider network and resources. Monitors network to actively remediate unauthorized activities.' },
    { code: 'cir', description: 'Responds to crises or urgent situations within the pertinent domain to mitigate immediate and potential threats. Uses mitigation, preparedness, and response and recovery approaches, as needed, to maximize survival of life, preservation of property, and information security. Investigates and analyzes all relevant response activities.' },
    { code: 'vam', description: 'Conducts assessments of threats and vulnerabilities; determines deviations from acceptable configurations, enterprise or local policy; assesses the level of risk; and develops and/or recommends appropriate mitigation countermeasures in operational and nonoperational situations.' },
    { code: 'twa', description: 'Identifies and assesses the capabilities and activities of cybersecurity criminals or foreign intelligence entities; produces findings to help initialize or support law enforcement and counterintelligence investigations or activities.' },
    { code: 'exp', description: 'Analyzes collected information to identify vulnerabilities and potential for exploitation.' },
    { code: 'asa', description: 'Analyzes threat information from multiple sources, disciplines, and agencies across the Intelligence Community. Synthesizes and places intelligence information in context; draws insights about the possible implications.' },
    { code: 'tgt', description: 'Applies current knowledge of one or more regions, countries, non-state entities, and/or technologies.' },
    { code: 'lng', description: 'Applies language, cultural, and technical expertise to support information collection, analysis, and other cybersecurity activities.' },
    { code: 'clo', description: 'Executes collection using appropriate strategies and within the priorities established through the collection management process.' },
    { code: 'opl', description: 'Performs in-depth joint targeting and cybersecurity planning process. Gathers information and develops detailed Operational Plans and Orders supporting requirements. Conducts strategic and operational-level planning across the full range of operations for integrated information and cyberspace operations.' },
    { code: 'ops', description: 'Performs activities to gather evidence on criminal or foreign intelligence entities to mitigate possible or real-time threats, protect against espionage or insider threats, foreign sabotage, international terrorist activities, or to support other intelligence activities.' },
    { code: 'inv', description: 'Applies tactics, techniques, and procedures for a full range of investigative tools and processes to include, but not limited to, interview and interrogation techniques, surveillance, counter surveillance, and surveillance detection, and appropriately balances the benefits of prosecution versus intelligence gathering.' },
    { code: 'for', description: 'Collects, processes, preserves, analyzes, and presents computer-related evidence in support of network vulnerability mitigation and/or criminal, fraud, counterintelligence, or law enforcement investigations.' },
  ],
}
type stateProps = {
  abbr?: string;
  proper?: string;
}
type countryProps = {
  proper: string;
  dial_code: string;
  tld: string;
  abbr: string;
}
type geographyProps = {
  states: stateProps[]
  countries: countryProps[]
}
const geography: geographyProps = {
  states: [
    { proper: 'Alabama', abbr: 'AL' },
    { proper: 'Alaska', abbr: 'AK' },
    { proper: 'Arizona', abbr: 'AZ' },
    { proper: 'Arkansas', abbr: 'AR' },
    { proper: 'California', abbr: 'CA' },
    { proper: 'Colorado', abbr: 'CO' },
    { proper: 'Connecticut', abbr: 'CT' },
    { proper: 'Delaware', abbr: 'DE' },
    { proper: 'District of Columbia', abbr: 'DC' },
    { proper: 'Florida', abbr: 'FL' },
    { proper: 'Georgia', abbr: 'GA' },
    { proper: 'Guam', abbr: 'GU' },
    { proper: 'Hawaii', abbr: 'HI' },
    { proper: 'Idaho', abbr: 'ID' },
    { proper: 'Illinois', abbr: 'IL' },
    { proper: 'Indiana', abbr: 'IN' },
    { proper: 'Iowa', abbr: 'IA' },
    { proper: 'Kansas', abbr: 'KS' },
    { proper: 'Kentucky', abbr: 'KY' },
    { proper: 'Louisiana', abbr: 'LA' },
    { proper: 'Maine', abbr: 'ME' },
    { proper: 'Maryland', abbr: 'MD' },
    { proper: 'Massachusetts', abbr: 'MA' },
    { proper: 'Michigan', abbr: 'MI' },
    { proper: 'Minnesota', abbr: 'MN' },
    { proper: 'Mississippi', abbr: 'MS' },
    { proper: 'Missouri', abbr: 'MO' },
    { proper: 'Montana', abbr: 'MT' },
    { proper: 'Nebraska', abbr: 'NE' },
    { proper: 'Nevada', abbr: 'NV' },
    { proper: 'New Hampshire', abbr: 'NH' },
    { proper: 'New Jersey', abbr: 'NJ' },
    { proper: 'New Mexico', abbr: 'NM' },
    { proper: 'New York', abbr: 'NY' },
    { proper: 'North Carolina', abbr: 'NC' },
    { proper: 'North Dakota', abbr: 'ND' },
    { proper: 'Ohio', abbr: 'OH' },
    { proper: 'Oklahoma', abbr: 'OK' },
    { proper: 'Oregon', abbr: 'OR' },
    { proper: 'Pennsylvania', abbr: 'PA' },
    { proper: 'Puerto Rico', abbr: 'PR' },
    { proper: 'Rhode Island', abbr: 'RI' },
    { proper: 'South Carolina', abbr: 'SC' },
    { proper: 'South Dakota', abbr: 'SD' },
    { proper: 'Tennessee', abbr: 'TN' },
    { proper: 'Texas', abbr: 'TX' },
    { proper: 'Utah', abbr: 'UT' },
    { proper: 'Vermont', abbr: 'VT' },
    { proper: 'Virgin Islands', abbr: 'VI' },
    { proper: 'Virginia', abbr: 'VA' },
    { proper: 'Washington', abbr: 'WA' },
    { proper: 'West Virginia', abbr: 'WV' },
    { proper: 'Wisconsin', abbr: 'WI' },
    { proper: 'Wyoming', abbr: 'WY' },
    { proper: 'Newfoundland and Labrador', abbr: 'NL' },
    { proper: 'Prince Edward Island', abbr: 'PE' },
    { proper: 'Nova Scotia', abbr: 'NS' },
    { proper: 'New Brunswick', abbr: 'NB' },
    { proper: 'Quebec', abbr: 'QC' },
    { proper: 'Ontario', abbr: 'ON' },
    { proper: 'Manitoba', abbr: 'MB' },
    { proper: 'Saskatchewan', abbr: 'SK' },
    { proper: 'Alberta', abbr: 'AB' },
    { proper: 'British Columbia', abbr: 'BC' },
    { proper: 'Yukon', abbr: 'YT' },
    { proper: 'Northwest Territories', abbr: 'NT' },
    { proper: 'Nunavut', abbr: 'NU' },
    { proper: 'Unknown', abbr: '' },
  ],
  countries: [
    // as of 9/9/2021
    { proper: 'Afghanistan', dial_code: '93', tld: 'AF', abbr: 'AFG' },
    { proper: 'Albania', dial_code: '355', tld: 'AL', abbr: 'ALB' },
    { proper: 'Algeria', dial_code: '213', tld: 'DZ', abbr: 'DZA' },
    { proper: 'American Samoa', dial_code: '1-684', tld: 'AS', abbr: 'ASM' },
    { proper: 'Andorra', dial_code: '376', tld: 'AD', abbr: 'AND' },
    { proper: 'Angola', dial_code: '244', tld: 'AO', abbr: 'AGO' },
    { proper: 'Anguilla', dial_code: '1-264', tld: 'AI', abbr: 'AIA' },
    { proper: 'Antarctica', dial_code: '672', tld: 'AQ', abbr: 'ATA' },
    {
      proper: 'Antigua and Barbuda',
      dial_code: '1-268',
      tld: 'AG',
      abbr: 'ATG',
    },
    { proper: 'Argentina', dial_code: '54', tld: 'AR', abbr: 'ARG' },
    { proper: 'Armenia', dial_code: '374', tld: 'AM', abbr: 'ARM' },
    { proper: 'Aruba', dial_code: '297', tld: 'AW', abbr: 'ABW' },
    { proper: 'Australia', dial_code: '61', tld: 'AU', abbr: 'AUS' },
    { proper: 'Austria', dial_code: '43', tld: 'AT', abbr: 'AUT' },
    { proper: 'Azerbaijan', dial_code: '994', tld: 'AZ', abbr: 'AZE' },
    { proper: 'Bahamas', dial_code: '1-242', tld: 'BS', abbr: 'BHS' },
    { proper: 'Bahrain', dial_code: '973', tld: 'BH', abbr: 'BHR' },
    { proper: 'Bangladesh', dial_code: '880', tld: 'BD', abbr: 'BGD' },
    { proper: 'Barbados', dial_code: '1-246', tld: 'BB', abbr: 'BRB' },
    { proper: 'Belarus', dial_code: '375', tld: 'BY', abbr: 'BLR' },
    { proper: 'Belgium', dial_code: '32', tld: 'BE', abbr: 'BEL' },
    { proper: 'Belize', dial_code: '501', tld: 'BZ', abbr: 'BLZ' },
    { proper: 'Benin', dial_code: '229', tld: 'BJ', abbr: 'BEN' },
    { proper: 'Bermuda', dial_code: '1-441', tld: 'BM', abbr: 'BMU' },
    { proper: 'Bhutan', dial_code: '975', tld: 'BT', abbr: 'BTN' },
    { proper: 'Bolivia', dial_code: '591', tld: 'BO', abbr: 'BOL' },
    {
      proper: 'Bosnia and Herzegovina',
      dial_code: '387',
      tld: 'BA',
      abbr: 'BIH',
    },
    { proper: 'Botswana', dial_code: '267', tld: 'BW', abbr: 'BWA' },
    { proper: 'Brazil', dial_code: '55', tld: 'BR', abbr: 'BRA' },
    {
      proper: 'British Indian Ocean Territory',
      dial_code: '246',
      tld: 'IO',
      abbr: 'IOT',
    },
    {
      proper: 'British Virgin Islands',
      dial_code: '1-284',
      tld: 'VG',
      abbr: 'VGB',
    },
    { proper: 'Brunei', dial_code: '673', tld: 'BN', abbr: 'BRN' },
    { proper: 'Bulgaria', dial_code: '359', tld: 'BG', abbr: 'BGR' },
    { proper: 'Burkina Faso', dial_code: '226', tld: 'BF', abbr: 'BFA' },
    { proper: 'Burundi', dial_code: '257', tld: 'BI', abbr: 'BDI' },
    { proper: 'Cambodia', dial_code: '855', tld: 'KH', abbr: 'KHM' },
    { proper: 'Cameroon', dial_code: '237', tld: 'CM', abbr: 'CMR' },
    { proper: 'Canada', dial_code: '1', tld: 'CA', abbr: 'CAN' },
    { proper: 'Cape Verde', dial_code: '238', tld: 'CV', abbr: 'CPV' },
    { proper: 'Cayman Islands', dial_code: '1-345', tld: 'KY', abbr: 'CYM' },
    {
      proper: 'Central African Republic',
      dial_code: '236',
      tld: 'CF',
      abbr: 'CAF',
    },
    { proper: 'Chad', dial_code: '235', tld: 'TD', abbr: 'TCD' },
    { proper: 'Chile', dial_code: '56', tld: 'CL', abbr: 'CHL' },
    { proper: 'China', dial_code: '86', tld: 'CN', abbr: 'CHN' },
    { proper: 'Christmas Island', dial_code: '61', tld: 'CX', abbr: 'CXR' },
    { proper: 'Cocos Islands', dial_code: '61', tld: 'CC', abbr: 'CCK' },
    { proper: 'Colombia', dial_code: '57', tld: 'CO', abbr: 'COL' },
    { proper: 'Comoros', dial_code: '269', tld: 'KM', abbr: 'COM' },
    { proper: 'Cook Islands', dial_code: '682', tld: 'CK', abbr: 'COK' },
    { proper: 'Costa Rica', dial_code: '506', tld: 'CR', abbr: 'CRI' },
    { proper: 'Croatia', dial_code: '385', tld: 'HR', abbr: 'HRV' },
    { proper: 'Cuba', dial_code: '53', tld: 'CU', abbr: 'CUB' },
    { proper: 'Curacao', dial_code: '599', tld: 'CW', abbr: 'CUW' },
    { proper: 'Cyprus', dial_code: '357', tld: 'CY', abbr: 'CYP' },
    { proper: 'Czech Republic', dial_code: '420', tld: 'CZ', abbr: 'CZE' },
    {
      proper: 'Democratic Republic of the Congo',
      dial_code: '243',
      tld: 'CD',
      abbr: 'COD',
    },
    { proper: 'Denmark', dial_code: '45', tld: 'DK', abbr: 'DNK' },
    { proper: 'Djibouti', dial_code: '253', tld: 'DJ', abbr: 'DJI' },
    { proper: 'Dominica', dial_code: '1-767', tld: 'DM', abbr: 'DMA' },
    {
      proper: 'Dominican Republic',
      dial_code: '1-809, 1-829, 1-849',
      tld: 'DO',
      abbr: 'DOM',
    },
    { proper: 'East Timor', dial_code: '670', tld: 'TL', abbr: 'TLS' },
    { proper: 'Ecuador', dial_code: '593', tld: 'EC', abbr: 'ECU' },
    { proper: 'Egypt', dial_code: '20', tld: 'EG', abbr: 'EGY' },
    { proper: 'El Salvador', dial_code: '503', tld: 'SV', abbr: 'SLV' },
    { proper: 'Equatorial Guinea', dial_code: '240', tld: 'GQ', abbr: 'GNQ' },
    { proper: 'Eritrea', dial_code: '291', tld: 'ER', abbr: 'ERI' },
    { proper: 'Estonia', dial_code: '372', tld: 'EE', abbr: 'EST' },
    { proper: 'Ethiopia', dial_code: '251', tld: 'ET', abbr: 'ETH' },
    { proper: 'Falkland Islands', dial_code: '500', tld: 'FK', abbr: 'FLK' },
    { proper: 'Faroe Islands', dial_code: '298', tld: 'FO', abbr: 'FRO' },
    { proper: 'Fiji', dial_code: '679', tld: 'FJ', abbr: 'FJI' },
    { proper: 'Finland', dial_code: '358', tld: 'FI', abbr: 'FIN' },
    { proper: 'France', dial_code: '33', tld: 'FR', abbr: 'FRA' },
    { proper: 'French Polynesia', dial_code: '689', tld: 'PF', abbr: 'PYF' },
    { proper: 'Gabon', dial_code: '241', tld: 'GA', abbr: 'GAB' },
    { proper: 'Gambia', dial_code: '220', tld: 'GM', abbr: 'GMB' },
    { proper: 'Georgia', dial_code: '995', tld: 'GE', abbr: 'GEO' },
    { proper: 'Germany', dial_code: '49', tld: 'DE', abbr: 'DEU' },
    { proper: 'Ghana', dial_code: '233', tld: 'GH', abbr: 'GHA' },
    { proper: 'Gibraltar', dial_code: '350', tld: 'GI', abbr: 'GIB' },
    { proper: 'Greece', dial_code: '30', tld: 'GR', abbr: 'GRC' },
    { proper: 'Greenland', dial_code: '299', tld: 'GL', abbr: 'GRL' },
    { proper: 'Grenada', dial_code: '1-473', tld: 'GD', abbr: 'GRD' },
    { proper: 'Guam', dial_code: '1-671', tld: 'GU', abbr: 'GUM' },
    { proper: 'Guatemala', dial_code: '502', tld: 'GT', abbr: 'GTM' },
    { proper: 'Guernsey', dial_code: '44-1481', tld: 'GG', abbr: 'GGY' },
    { proper: 'Guinea', dial_code: '224', tld: 'GN', abbr: 'GIN' },
    { proper: 'Guinea-Bissau', dial_code: '245', tld: 'GW', abbr: 'GNB' },
    { proper: 'Guyana', dial_code: '592', tld: 'GY', abbr: 'GUY' },
    { proper: 'Haiti', dial_code: '509', tld: 'HT', abbr: 'HTI' },
    { proper: 'Honduras', dial_code: '504', tld: 'HN', abbr: 'HND' },
    { proper: 'Hong Kong', dial_code: '852', tld: 'HK', abbr: 'HKG' },
    { proper: 'Hungary', dial_code: '36', tld: 'HU', abbr: 'HUN' },
    { proper: 'Iceland', dial_code: '354', tld: 'IS', abbr: 'ISL' },
    { proper: 'India', dial_code: '91', tld: 'IN', abbr: 'IND' },
    { proper: 'Indonesia', dial_code: '62', tld: 'ID', abbr: 'IDN' },
    { proper: 'Iran', dial_code: '98', tld: 'IR', abbr: 'IRN' },
    { proper: 'Iraq', dial_code: '964', tld: 'IQ', abbr: 'IRQ' },
    { proper: 'Ireland', dial_code: '353', tld: 'IE', abbr: 'IRL' },
    { proper: 'Isle of Man', dial_code: '44-1624', tld: 'IM', abbr: 'IMN' },
    { proper: 'Israel', dial_code: '972', tld: 'IL', abbr: 'ISR' },
    { proper: 'Italy', dial_code: '39', tld: 'IT', abbr: 'ITA' },
    { proper: 'Ivory Coast', dial_code: '225', tld: 'CI', abbr: 'CIV' },
    { proper: 'Jamaica', dial_code: '1-876', tld: 'JM', abbr: 'JAM' },
    { proper: 'Japan', dial_code: '81', tld: 'JP', abbr: 'JPN' },
    { proper: 'Jersey', dial_code: '44-1534', tld: 'JE', abbr: 'JEY' },
    { proper: 'Jordan', dial_code: '962', tld: 'JO', abbr: 'JOR' },
    { proper: 'Kazakhstan', dial_code: '7', tld: 'KZ', abbr: 'KAZ' },
    { proper: 'Kenya', dial_code: '254', tld: 'KE', abbr: 'KEN' },
    { proper: 'Kiribati', dial_code: '686', tld: 'KI', abbr: 'KIR' },
    { proper: 'Kosovo', dial_code: '383', tld: 'XK', abbr: 'XKX' },
    { proper: 'Kuwait', dial_code: '965', tld: 'KW', abbr: 'KWT' },
    { proper: 'Kyrgyzstan', dial_code: '996', tld: 'KG', abbr: 'KGZ' },
    { proper: 'Laos', dial_code: '856', tld: 'LA', abbr: 'LAO' },
    { proper: 'Latvia', dial_code: '371', tld: 'LV', abbr: 'LVA' },
    { proper: 'Lebanon', dial_code: '961', tld: 'LB', abbr: 'LBN' },
    { proper: 'Lesotho', dial_code: '266', tld: 'LS', abbr: 'LSO' },
    { proper: 'Liberia', dial_code: '231', tld: 'LR', abbr: 'LBR' },
    { proper: 'Libya', dial_code: '218', tld: 'LY', abbr: 'LBY' },
    { proper: 'Liechtenstein', dial_code: '423', tld: 'LI', abbr: 'LIE' },
    { proper: 'Lithuania', dial_code: '370', tld: 'LT', abbr: 'LTU' },
    { proper: 'Luxembourg', dial_code: '352', tld: 'LU', abbr: 'LUX' },
    { proper: 'Macau', dial_code: '853', tld: 'MO', abbr: 'MAC' },
    { proper: 'Macedonia', dial_code: '389', tld: 'MK', abbr: 'MKD' },
    { proper: 'Madagascar', dial_code: '261', tld: 'MG', abbr: 'MDG' },
    { proper: 'Malawi', dial_code: '265', tld: 'MW', abbr: 'MWI' },
    { proper: 'Malaysia', dial_code: '60', tld: 'MY', abbr: 'MYS' },
    { proper: 'Maldives', dial_code: '960', tld: 'MV', abbr: 'MDV' },
    { proper: 'Mali', dial_code: '223', tld: 'ML', abbr: 'MLI' },
    { proper: 'Malta', dial_code: '356', tld: 'MT', abbr: 'MLT' },
    { proper: 'Marshall Islands', dial_code: '692', tld: 'MH', abbr: 'MHL' },
    { proper: 'Mauritania', dial_code: '222', tld: 'MR', abbr: 'MRT' },
    { proper: 'Mauritius', dial_code: '230', tld: 'MU', abbr: 'MUS' },
    { proper: 'Mayotte', dial_code: '262', tld: 'YT', abbr: 'MYT' },
    { proper: 'Mexico', dial_code: '52', tld: 'MX', abbr: 'MEX' },
    { proper: 'Micronesia', dial_code: '691', tld: 'FM', abbr: 'FSM' },
    { proper: 'Moldova', dial_code: '373', tld: 'MD', abbr: 'MDA' },
    { proper: 'Monaco', dial_code: '377', tld: 'MC', abbr: 'MCO' },
    { proper: 'Mongolia', dial_code: '976', tld: 'MN', abbr: 'MNG' },
    { proper: 'Montenegro', dial_code: '382', tld: 'ME', abbr: 'MNE' },
    { proper: 'Montserrat', dial_code: '1-664', tld: 'MS', abbr: 'MSR' },
    { proper: 'Morocco', dial_code: '212', tld: 'MA', abbr: 'MAR' },
    { proper: 'Mozambique', dial_code: '258', tld: 'MZ', abbr: 'MOZ' },
    { proper: 'Myanmar', dial_code: '95', tld: 'MM', abbr: 'MMR' },
    { proper: 'Namibia', dial_code: '264', tld: 'NA', abbr: 'NAM' },
    { proper: 'Nauru', dial_code: '674', tld: 'NR', abbr: 'NRU' },
    { proper: 'Nepal', dial_code: '977', tld: 'NP', abbr: 'NPL' },
    { proper: 'Netherlands', dial_code: '31', tld: 'NL', abbr: 'NLD' },
    {
      proper: 'Netherlands Antilles',
      dial_code: '599',
      tld: 'AN',
      abbr: 'ANT',
    },
    { proper: 'New Caledonia', dial_code: '687', tld: 'NC', abbr: 'NCL' },
    { proper: 'New Zealand', dial_code: '64', tld: 'NZ', abbr: 'NZL' },
    { proper: 'Nicaragua', dial_code: '505', tld: 'NI', abbr: 'NIC' },
    { proper: 'Niger', dial_code: '227', tld: 'NE', abbr: 'NER' },
    { proper: 'Nigeria', dial_code: '234', tld: 'NG', abbr: 'NGA' },
    { proper: 'Niue', dial_code: '683', tld: 'NU', abbr: 'NIU' },
    { proper: 'North Korea', dial_code: '850', tld: 'KP', abbr: 'PRK' },
    {
      proper: 'Northern Mariana Islands',
      dial_code: '1-670',
      tld: 'MP',
      abbr: 'MNP',
    },
    { proper: 'Norway', dial_code: '47', tld: 'NO', abbr: 'NOR' },
    { proper: 'Oman', dial_code: '968', tld: 'OM', abbr: 'OMN' },
    { proper: 'Pakistan', dial_code: '92', tld: 'PK', abbr: 'PAK' },
    { proper: 'Palau', dial_code: '680', tld: 'PW', abbr: 'PLW' },
    { proper: 'Palestine', dial_code: '970', tld: 'PS', abbr: 'PSE' },
    { proper: 'Panama', dial_code: '507', tld: 'PA', abbr: 'PAN' },
    { proper: 'Papua New Guinea', dial_code: '675', tld: 'PG', abbr: 'PNG' },
    { proper: 'Paraguay', dial_code: '595', tld: 'PY', abbr: 'PRY' },
    { proper: 'Peru', dial_code: '51', tld: 'PE', abbr: 'PER' },
    { proper: 'Philippines', dial_code: '63', tld: 'PH', abbr: 'PHL' },
    { proper: 'Pitcairn', dial_code: '64', tld: 'PN', abbr: 'PCN' },
    { proper: 'Poland', dial_code: '48', tld: 'PL', abbr: 'POL' },
    { proper: 'Portugal', dial_code: '351', tld: 'PT', abbr: 'PRT' },
    {
      proper: 'Puerto Rico',
      dial_code: '1-787, 1-939',
      tld: 'PR',
      abbr: 'PRI',
    },
    { proper: 'Qatar', dial_code: '974', tld: 'QA', abbr: 'QAT' },
    {
      proper: 'Republic of the Congo',
      dial_code: '242',
      tld: 'CG',
      abbr: 'COG',
    },
    { proper: 'Reunion', dial_code: '262', tld: 'RE', abbr: 'REU' },
    { proper: 'Romania', dial_code: '40', tld: 'RO', abbr: 'ROU' },
    { proper: 'Russia', dial_code: '7', tld: 'RU', abbr: 'RUS' },
    { proper: 'Rwanda', dial_code: '250', tld: 'RW', abbr: 'RWA' },
    { proper: 'Saint Barthelemy', dial_code: '590', tld: 'BL', abbr: 'BLM' },
    { proper: 'Saint Helena', dial_code: '290', tld: 'SH', abbr: 'SHN' },
    {
      proper: 'Saint Kitts and Nevis',
      dial_code: '1-869',
      tld: 'KN',
      abbr: 'KNA',
    },
    { proper: 'Saint Lucia', dial_code: '1-758', tld: 'LC', abbr: 'LCA' },
    { proper: 'Saint Martin', dial_code: '590', tld: 'MF', abbr: 'MAF' },
    {
      proper: 'Saint Pierre and Miquelon',
      dial_code: '508',
      tld: 'PM',
      abbr: 'SPM',
    },
    {
      proper: 'Saint Vincent and the Grenadines',
      dial_code: '1-784',
      tld: 'VC',
      abbr: 'VCT',
    },
    { proper: 'Samoa', dial_code: '685', tld: 'WS', abbr: 'WSM' },
    { proper: 'San Marino', dial_code: '378', tld: 'SM', abbr: 'SMR' },
    {
      proper: 'Sao Tome and Principe',
      dial_code: '239',
      tld: 'ST',
      abbr: 'STP',
    },
    { proper: 'Saudi Arabia', dial_code: '966', tld: 'SA', abbr: 'SAU' },
    { proper: 'Senegal', dial_code: '221', tld: 'SN', abbr: 'SEN' },
    { proper: 'Serbia', dial_code: '381', tld: 'RS', abbr: 'SRB' },
    { proper: 'Seychelles', dial_code: '248', tld: 'SC', abbr: 'SYC' },
    { proper: 'Sierra Leone', dial_code: '232', tld: 'SL', abbr: 'SLE' },
    { proper: 'Singapore', dial_code: '65', tld: 'SG', abbr: 'SGP' },
    { proper: 'Sint Maarten', dial_code: '1-721', tld: 'SX', abbr: 'SXM' },
    { proper: 'Slovakia', dial_code: '421', tld: 'SK', abbr: 'SVK' },
    { proper: 'Slovenia', dial_code: '386', tld: 'SI', abbr: 'SVN' },
    { proper: 'Solomon Islands', dial_code: '677', tld: 'SB', abbr: 'SLB' },
    { proper: 'Somalia', dial_code: '252', tld: 'SO', abbr: 'SOM' },
    { proper: 'South Africa', dial_code: '27', tld: 'ZA', abbr: 'ZAF' },
    { proper: 'South Korea', dial_code: '82', tld: 'KR', abbr: 'KOR' },
    { proper: 'South Sudan', dial_code: '211', tld: 'SS', abbr: 'SSD' },
    { proper: 'Spain', dial_code: '34', tld: 'ES', abbr: 'ESP' },
    { proper: 'Sri Lanka', dial_code: '94', tld: 'LK', abbr: 'LKA' },
    { proper: 'Sudan', dial_code: '249', tld: 'SD', abbr: 'SDN' },
    { proper: 'Suriname', dial_code: '597', tld: 'SR', abbr: 'SUR' },
    {
      proper: 'Svalbard and Jan Mayen',
      dial_code: '47',
      tld: 'SJ',
      abbr: 'SJM',
    },
    { proper: 'Swaziland', dial_code: '268', tld: 'SZ', abbr: 'SWZ' },
    { proper: 'Sweden', dial_code: '46', tld: 'SE', abbr: 'SWE' },
    { proper: 'Switzerland', dial_code: '41', tld: 'CH', abbr: 'CHE' },
    { proper: 'Syria', dial_code: '963', tld: 'SY', abbr: 'SYR' },
    { proper: 'Taiwan', dial_code: '886', tld: 'TW', abbr: 'TWN' },
    { proper: 'Tajikistan', dial_code: '992', tld: 'TJ', abbr: 'TJK' },
    { proper: 'Tanzania', dial_code: '255', tld: 'TZ', abbr: 'TZA' },
    { proper: 'Thailand', dial_code: '66', tld: 'TH', abbr: 'THA' },
    { proper: 'Togo', dial_code: '228', tld: 'TG', abbr: 'TGO' },
    { proper: 'Tokelau', dial_code: '690', tld: 'TK', abbr: 'TKL' },
    { proper: 'Tonga', dial_code: '676', tld: 'TO', abbr: 'TON' },
    {
      proper: 'Trinidad and Tobago',
      dial_code: '1-868',
      tld: 'TT',
      abbr: 'TTO',
    },
    { proper: 'Tunisia', dial_code: '216', tld: 'TN', abbr: 'TUN' },
    { proper: 'Turkey', dial_code: '90', tld: 'TR', abbr: 'TUR' },
    { proper: 'Turkmenistan', dial_code: '993', tld: 'TM', abbr: 'TKM' },
    {
      proper: 'Turks and Caicos Islands',
      dial_code: '1-649',
      tld: 'TC',
      abbr: 'TCA',
    },
    { proper: 'Tuvalu', dial_code: '688', tld: 'TV', abbr: 'TUV' },
    {
      proper: 'U.S. Virgin Islands',
      dial_code: '1-340',
      tld: 'VI',
      abbr: 'VIR',
    },
    { proper: 'Uganda', dial_code: '256', tld: 'UG', abbr: 'UGA' },
    { proper: 'Ukraine', dial_code: '380', tld: 'UA', abbr: 'UKR' },
    {
      proper: 'United Arab Emirates',
      dial_code: '971',
      tld: 'AE',
      abbr: 'ARE',
    },
    { proper: 'United Kingdom', dial_code: '44', tld: 'GB', abbr: 'GBR' },
    { proper: 'United States', dial_code: '1', tld: 'US', abbr: 'USA' },
    { proper: 'Uruguay', dial_code: '598', tld: 'UY', abbr: 'URY' },
    { proper: 'Uzbekistan', dial_code: '998', tld: 'UZ', abbr: 'UZB' },
    { proper: 'Vanuatu', dial_code: '678', tld: 'VU', abbr: 'VUT' },
    { proper: 'Vatican', dial_code: '379', tld: 'VA', abbr: 'VAT' },
    { proper: 'Venezuela', dial_code: '58', tld: 'VE', abbr: 'VEN' },
    { proper: 'Vietnam', dial_code: '84', tld: 'VN', abbr: 'VNM' },
    { proper: 'Wallis and Futuna', dial_code: '681', tld: 'WF', abbr: 'WLF' },
    { proper: 'Western Sahara', dial_code: '212', tld: 'EH', abbr: 'ESH' },
    { proper: 'Yemen', dial_code: '967', tld: 'YE', abbr: 'YEM' },
    { proper: 'Zambia', dial_code: '260', tld: 'ZM', abbr: 'ZMB' },
    { proper: 'Zimbabwe', dial_code: '263', tld: 'ZW', abbr: 'ZWE' },
  ],
};
export const getState = ({ abbr, proper }: stateProps) => {
  if (abbr && abbr.length === 2) return geography.states.find((o: stateProps) => o.abbr === abbr)?.proper;
  if (proper) return geography.states.find((o: stateProps) => o.proper === proper)?.abbr;
}
type countryArgumentProps = {
  value: string;
  value_element: 'proper' | 'dial_code' | 'tld' | 'abbr';
  response_element: 'proper' | 'dial_code' | 'tld' | 'abbr';
}
export const getCountry = ({ value, value_element, response_element }: countryArgumentProps) => {
  const response = geography.countries.find((o: countryProps) => o[ value_element ] === value) || null;
  return response ? response[ response_element ] : null;
}