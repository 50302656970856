
import { FC } from 'react';
import { InputSelect, InputNumber, InputDate, InputSwitch } from './_Input';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AssetFormProps } from './Asset.form';
import { useFormContext } from 'react-hook-form';


const AssetSupportContract: FC<AssetFormProps> = ({ options }) => {
  const { watch } = useFormContext();
  return (
    <Grid id='support_contract' item xs={ 12 } container spacing={ 2 }>
      <Grid id='support_contract_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>Support Contract Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='support_contract.provider'
              label='Provider'
              required={ watch('category') === 'software' }
              options={ options.organizations }
              tip
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='support_contract.type'
              label='Type'
              required
              options={ options.support_contract.type }
              tip
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid id='support_contract_renewal' item container>
        <Grid item xs={ 12 } sx={ { pt: 1, pb: 1 } }>
          <Typography variant='h4'>Renewal</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='support_contract.renewal.frequency'
              label='Frequency'
              required
              options={ options.support_contract.renewal.frequency }
            />
          </Grid>
          { watch('support_contract.renewal.frequency') === 'multiyear' ?
            <Grid item xs={ 12 } md={ 1 }>
              <InputNumber
                name='support_contract.renewal.years'
                label='Years'
                required={ watch('support_contract.renewal.frequency') === 'multiyear' }
                min={ 2 }
                max={ 10 }
              />
            </Grid>
            : null }
          { watch('support_contract.renewal.frequency') === 'annual' || watch('support_contract.renewal.frequency') === 'multiyear' ?
            <Grid item xs={ 12 } md={ 2 }>
              <InputSelect
                name='support_contract.renewal.month'
                label='Month'
                required
                options={ options.support_contract.renewal.month }
              />
            </Grid>
            : null }
          { watch('support_contract.renewal.frequency') !== 'per_use' ?
            <Grid item xs={ 12 } md={ 1 }>
              <InputNumber
                name='support_contract.renewal.day'
                label='Day'
                required
                min={ 1 }
                max={ 31 }
              />
            </Grid>
            : null }
          { watch('support_contract.renewal.frequency') === 'annual' || watch('support_contract.renewal.frequency') === 'multiyear' ?
            <Grid item xs={ 12 } md={ 2 }>
              <InputDate
                name='support_contract.renewal.last_payment'
                label='Last Renewal'
                required
              />
            </Grid>
            : null }
          { watch('support_contract.renewal.frequency') !== 'per_use' ?
            <Grid item xs={ 12 } md={ 3 }>
              <InputSwitch
                name='support_contract.renewal.auto'
                label='Automatic Payment'
              />
            </Grid>
            : null }
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetSupportContract;
