
import { createContext, FC, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { UserContextClass } from '../lib/classes';

const UserContext = createContext({ user: new UserContextClass() });
const Context: FC = ({ children }) => {
  const { instance } = useMsal();
  const account: any = instance.getActiveAccount()?.idTokenClaims;
  const user: any = {
    username: account.email,
    name: {
      first: account.given_name,
      last: account.family_name,
      full: account.name,
    },
    groups: account.groups,
    roles: account.roles,
  }
  return (
    <UserContext.Provider value={{ user }}>
      { children }
    </UserContext.Provider>
  )
}

export default Context;
export const useUserContext = () => useContext(UserContext);