
import { FC } from 'react';
import { Grid } from '@mui/material';
import { InputSelect, InputSwitch } from './_Input';
import Typography from '@mui/material/Typography';
import { AssetFormProps } from './Asset.form';


const AssetRealEstate: FC<AssetFormProps> = ({ options }) => {
  return (
    <Grid id='software' item xs={ 12 } container spacing={ 2 }>
      <Grid id='software_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>Property Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='real_estate.address'
              label='Address'
              required
              options={ options.addresses }
              tip
            />
          </Grid>
          <Grid item xs={ 12 } md={ 9 }>
            <p>Multiselect for Purposes - when it works</p>
          </Grid>
          <Grid item xs={ 12 }>
            <Typography variant='h5'>Attributes</Typography>
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSwitch
              name='real_estate.attributes.shipping'
              label='Shipping'
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSwitch
              name='real_estate.attributes.receiving'
              label='Receiving'
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSwitch
              name='real_estate.attributes.operations'
              label='Operations'
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSwitch
              name='real_estate.attributes.staff'
              label='Staff'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetRealEstate;
