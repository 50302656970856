
import { FC, useState, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { startCase } from 'lodash';
import { Link } from '@mui/material';
import { FormIcons, MiscIcons } from '../Helpers/IconMap.helper';
import ApprovalDialog from '../Components/Dialogs/Approval.dialog';
import { oddCaps } from '../Helpers/Tools.helper';
import { useUserContext } from '../Context/User.context';

type Props = {
  title: string;
  approval?: any;
  options?: any;
  handler: any;
  hideForm: boolean;
}
const PagerHeader: FC<Props> = ({ title, approval, options, handler, hideForm }) => {
  const { user } = useUserContext();
  const { collection, id } = useParams();
  const [ show, setShow ] = useState<boolean>(false);

  const buttonDisplay = () => {
    let result: any = { marginLeft: 'auto' };
    if (id === 'add') result.display = 'none'
    return result;
  }
  const Title = () => {
    const urlArray = window.location.href.split('/');
    const route = urlArray[ urlArray.length - 1 ];
    const collectionLink = collection ? <Link href={ `/${collection}`} underline='hover'>{oddCaps(collection!)}</Link> : <Link href={ `#` } underline='hover'>{oddCaps(route)}</Link>;
    if (id === 'add') return <Typography variant='h1'>New {collectionLink}</Typography>
    return <Typography variant='h1'>{collectionLink}: {title}</Typography>
  }
  const approveClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setShow(true);
  }
  const roles: string[] = user.roles;

  return (
    <>
    {/* <Grid item xs={ 12 }>
      <Typography textAlign='center'>&lt; Previous - Next &gt;</Typography>
    </Grid> */}
    <Grid id='header-grid' item xs={ 12 } container sx={ { mx: 1, mt: 1, pb: 2 } }>
      { Title() }
      { roles.includes('GlobalAdmin') ?
      <span style={ buttonDisplay() }>
        { collection === 'control' || collection === 'mechanism' || collection === 'policy' ?
        <Button
          startIcon={ <MiscIcons item='approve' />}
          color='success'
          sx={{ mr: 3 }}
          onClick={ approveClick }
        >
          Update Status
        </Button>
        : null }
        <Button
          type='button'
          size='large'
          color='warning'
          onClick={ handler }
          startIcon={ hideForm ? <FormIcons item='edit' /> : <FormIcons item='read' /> }
        >
          { hideForm ? `Edit ${startCase(collection)}` : `Read ${startCase(collection)}` }
        </Button>
      </span>
      : null }
      <ApprovalDialog show={ show } setShow={ setShow } approval={ approval } options={ options } />
    </Grid>
    </>
  )
}

export default PagerHeader;
