
import { FC } from 'react';
import DataTable, { MUIDataTableColumnDef, MUIDataTableProps } from 'mui-datatables';     // https://www.npmjs.com/package/mui-datatables
import { useNavigate } from 'react-router-dom';
import { map, startCase, orderBy, isObject, isArray, isEmpty, isString, filter } from 'lodash';
import { dateFormatted, oddCaps, phoneFormat } from '../../Helpers/Tools.helper';
import { createTheme } from '@mui/material/styles';
import TableToolbar from './_Toolbar.table';
import Typography from '@mui/material/Typography';
import pluralize from 'pluralize';
import { TableIcons } from '../../Helpers/IconMap.helper';
import { Job, User } from '../../lib/classes';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import InfoTwoTone from '@mui/icons-material/InfoTwoTone';

const tableOptions: MUIDataTableProps['options'] = {
  responsive: 'vertical',
  print: false,
  download: false,
  filter: false,
  viewColumns: false,
  search: false,
  pagination: false,
  elevation: 1,
  selectableRows: 'none',
  textLabels: {
    body: {
      noMatch: 'Add a Phone Number'
    }
  },
  enableNestedDataAccess: '.',
  setTableProps: () => ({ size: 'small' }),
}

const useTableOptions = (collection: string) => {
  const navigate = useNavigate();
  const result: MUIDataTableProps['options'] = {
    ...tableOptions,
    onRowClick: (data: any, meta: any) => {
      navigate(`/${collection}/${data[ 0 ]}`)
    },
  }
  return result;
}
const cco: any = {
  // Common Column Options
  _id: { display: 'excluded' },
  center: {
    // setCellHeaderProps: () => ({ style: { display: 'flex', justifyContent: 'center' } }),
    setCellProps: () => ({ style: { textAlign: 'center' } }),
  },
  center_nowrap: {
    // setCellHeaderProps: () => ({ style: { display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' } }),
    setCellProps: () => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
  },
  center_dense: {
    // setCellHeaderProps: () => ({ style: { padding: '0px 2px', display: 'flex', justifyContent: 'center' } }),
    setCellProps: () => ({ style: { padding: '0px 2px', textAlign: 'center', whiteSpace: 'nowrap' } }),
  },
  dense_field: {
    setCellHeaderProps: () => ({ style: { padding: '2px 10px 0px 20px', border: 'none' } }),
    setCellProps: () => ({ style: { padding: '2px 10px 0px 20px', border: 'none' } }),
  },
  dense_right: {
    setCellHeaderProps: () => ({ style: { padding: '2px 10px 0px 20px', textAlign: 'right', border: 'none' } }),
    setCellProps: () => ({ style: { padding: '2px 10px 0px 20px', textAlign: 'right', border: 'none' } }),
  },
}


export const PhoneData: FC<any> = ({ data }) => {
  const noTexty = <Tooltip title="This user does NOT consent to GNCI sending text/SMS messages to their mobile phone."><small><InfoTwoTone sx={{ fontSize: 12 }} color='error' /></small></Tooltip>
  const table_data = data.map((o: any) => {
    const type = o.other_type ? o.other_type : oddCaps(o.type);
    const number = <Link href={`tel:${o.number}`} underline='hover'>{o.type === 'mobile' && !o.sms_ok ? noTexty : null}  {phoneFormat(o.number, o.country, 'text')}</Link>;
    return { type, number }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: 'type', options: { ...cco.dense_field, customHeadRender: () => null }},
    { name: 'number', options: { ...cco.dense_right, customHeadRender: () => null }},
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ tableOptions }
    />
  )
}
export const EmailData: FC<any> = ({ data }) => {
  const table_data = data.map((o: any) => {
    const type = oddCaps(o.type);
    const address = o.address ? <Link href={`mailto:${o.address}`} underline='hover'>{o.address}</Link> : `Enter ${oddCaps(o.type)} address`;
    return { type, address }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: 'type', options: { ...cco.dense_field, customHeadRender: () => null, }},
    { name: 'address', options: { ...cco.dense_right, customHeadRender: () => null, }},
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('job') }
    />
  )
}
export const StaffData: FC<any> = ({ data }) => {
  data = data.filter((o: any) => o.active)
  const table_data = data.map((o: any) => {
    const _id = o._id;
    const name = o.display_name.full;
    const title = o.title;
    return { _id, name, title }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', options: { ...cco.dense_field, customHeadRender: () => null, }},
    { name: 'title', options: { ...cco.dense_right, customHeadRender: () => null, }},
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('user') }
    />
  )
}

export const UserJobs: FC<any> = ({ data }) => {
  data = data.map((datum: any) => {
    datum.start = new Date(datum.start);
    datum.end = datum.end ? new Date(datum.end) : null;
    return datum;
  });
  data = orderBy(data, [ 'start' ], [ 'desc' ]);
  const table_data = map(data, (o) => {
    const _id = o.job._id;
    const title = o.job.display_title;
    const description = o.job.description;
    const start = dateFormatted(o.start);
    const end = o.end ? dateFormatted(o.end) : 'to Present';
    return { _id, title, description, start, end }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'title', label: 'Title' },
    { name: 'description', label: 'Description' },
    { name: 'start', label: 'Start Date' },
    { name: 'end', label: 'End Date' },
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('job') }
    />
  )
}
export const UserRoles: FC<any> = ({ data }) => {
  data = data.map((datum: any) => {
    datum.start = new Date(datum.start);
    datum.end = datum.end ? new Date(datum.end) : null;
    return datum;
  });
  data = orderBy(data, [ 'start' ], [ 'desc' ]);
  const table_data = map(data, (o) => {
    const _id = o.role._id;
    const title = o.role.title;
    const description = o.role.description;
    const start = dateFormatted(o.start);
    const end = o.end ? dateFormatted(o.end) : 'to Present';
    return { _id, title, description, start, end }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'title', label: 'Role' },
    { name: 'description', label: 'Description' },
    { name: 'start', label: 'Start Date' },
    { name: 'end', label: 'End Date' },
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('role') }
    />
  )
}
export const UserManagers: FC<any> = ({ data }) => {
  data = data.map((datum: any) => {
    datum.start = new Date(datum.start);
    datum.end = datum.end ? new Date(datum.end) : null;
    return datum;
  });
  data = orderBy(data, [ 'start' ], [ 'desc' ]);
  const table_data = map(data, (o) => {
    const _id = o.manager._id;
    const name = o.manager.display_name.def;
    const title = o.manager.title;
    const start = dateFormatted(o.start);
    const end = o.end ? dateFormatted(o.end) : 'to Present';
    return { _id, name, title, start, end }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'title', label: 'Title' },
    { name: 'start', label: 'Start Date' },
    { name: 'end', label: 'End Date' },
  ];
  return (
    <DataTable
      title=''
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('manager') }
    />
  )
}