
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Contact, ContactOptions } from '../../lib/classes';
import { InputText, InputSelect, InputSwitch, InputPhones, InputEmails, InputAddresses } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

interface Props {
  contact: Contact | any;
  options: ContactOptions | any;
}

const ContactForm: FC<Props> = ({ contact, options }: any) => {
  const methods = useForm({ defaultValues: contact, shouldUnregister: true });
  const { watch } = methods;

  const tipText = {
    first: "Please make this one their true given name - such as the one on their birth certificate.  There's a spot below for a preferred name.",
    preferred: 'If they go by another first name (e.g. Beth for Elizabeth or Bill for William), put it here.  It will be substituted for their first name.',
    nick: "If they go by a nickname (e.g. 'Magic' for Earvin Johnson), put it here.  It will be substituted for their full name.",
    company: "The company or Organization they work for.",
    title: "Their official job title",
    role: "The role they play in the company in the context of their relationship with GNCI.",
    authority: "Switch on if this person works for some sort of authority (e.g. law enforcement, government agency, regulatory body, etc.).",
    active: "Switch off if this person is no longer working with the company/organization or GNCI",
    do_not_call: "Switch on if this person requests that GNCI does not contact them in any sort of marketing effort.",
  }

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid id='name' item xs={ 12 } container spacing={ 2 }>
            <Tooltip title={ tipText.first }>
              <Grid item xs={ 3 }>
                <InputText name='name.first' label='First Name' required />
              </Grid>
            </Tooltip>
            <Grid item md={ 3 } xs={ 12 }>
              <InputText name='name.last' label='Last Name' required />
            </Grid>
            <Tooltip title={ tipText.preferred }>
              <Grid item xs={ 3 }>
                <InputText name='name.preferred' label='Preferred First Name' />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.nick }>
              <Grid item xs={ 3 }>
                <InputText name='name.nick' label='Nick Name' />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid id='company' item xs={ 12 } container spacing={ 2 }>
            <Tooltip title={ tipText.company }>
              <Grid item xs={ 3 }>
                <InputSelect
                  name='company'
                  label='Company/Organization'
                  options={ options.company }
                  required
                  tip
                />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.title }>
              <Grid item xs={ 3 }>
                <InputText name='title' label='Job Title' />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.role }>
              <Grid item xs={ 4 }>
                <InputText name='role' label='Role' required={ !!watch('authority') } />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.authority }>
              <Grid item xs={ 2 } sx={ { textAlign: 'right' } }>
                <InputSwitch name='authority' label='Authority' />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid id='contact_info' item xs={ 12 } container spacing={ 1 }>
            <Grid item xs={ 12 }>
              <Typography variant='h4'>Contact Information</Typography>
            </Grid>
            <Grid id='phones' item md={ 4 } xs={ 12 }>
              <InputPhones name='phones' options={ options.phone.type } />
            </Grid>
            <Grid id='emails' item md={ 4 } xs={ 12 }>
              <InputEmails name='emails' options={ options.email.type } />
            </Grid>
            <Grid id='addresses' item md={ 4 } xs={ 12 }>
              <InputAddresses name='addresses' options={ { type: options.address.type, addresses: options.addresses } } />
            </Grid>
          </Grid>
          <Grid id='switches' item xs={ 12 } container spacing={ 2 }>
            <Tooltip title={ tipText.active }>
              <Grid item xs={ 3 }>
                <InputSwitch name='active' label='Active' />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.authority }>
              <Grid item xs={ 3 }>
                <InputSwitch name='do_not_call' label='Do Not Call' />
              </Grid>
            </Tooltip>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default ContactForm;
