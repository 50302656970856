
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Request, RequestOptions } from '../../lib/classes';
import { InputText, InputSelect, InputTextarea, InputDate, InputInquiries } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';

interface Props {
  request: Request | any;
  options: RequestOptions;
}

const RequestForm: FC<Props> = ({ request, options }: any) => {
  const methods = useForm({ defaultValues: request, shouldUnregister: true });
  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item md={ 3 } xs={ 12 }>
            <InputSelect
              name='customer'
              label='Customer'
              options={ options.customer }
              tip
              required
            />
          </Grid>
          <Grid item md={ 3 } xs={ 12 }>
            <InputText label='Name' name='name' required />
          </Grid>
          <Grid item xs={ 6 }>
            <InputText name='description' label='Description' />
          </Grid>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 3 } xs={ 12 }>
              <InputDate label='Received' name='dates.received' required />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputDate label='Due' name='dates.due' />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputDate label='Started' name='dates.start' />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputDate label='Completed' name='dates.complete' />
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            <InputTextarea name='notes' label='Notes' multiline minRows={ 3 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputInquiries name='inquiries' options={ { controls: options.controls, priority: options.priority } } />
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default RequestForm;
