
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import JobForm from '../Components/Forms/Job.form';
import Grid from '@mui/material/Grid';
import Waiting from '../Components/Partials/Waiting';
import { Job, JobOptions, Options } from '../lib/classes';
import PagerHeader from './_PageHeader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { oddCaps } from '../Helpers/Tools.helper';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import pluralize from 'pluralize';

type DomainListProps = {
  domains: string[];
  options: Options[];
}
const DomainList: FC<DomainListProps> = ({ domains, options }) => {
  return (
    <List dense sx={ { display: 'flex', flexDirection: 'row', p: 0 } }>
      { domains.map((domain) => {
        const secondary: any = options.find((o: Options) => o.value === domain);
        return (
          <ListItem>
            <ListItemText primary={ oddCaps(domain) } secondary={ secondary.label } />
          </ListItem>
        )
      }) }
    </List>
  )
}
type DeptListProps = {
  departments: string[];
}
const DeptList: FC<DeptListProps> = ({ departments }) => {
  return (
    <List dense sx={ { display: 'flex', flexDirection: 'row', p: 0 } }>
      { departments.map((dept) => {
        return (
          <ListItem>
            <ListItemText primary={ oddCaps(dept) } />
          </ListItem>
        )
      }) }
    </List>
  )
}
const deptShortList = (departments: string[]) => {
  if (!departments || departments.length < 1) return 'Select at least 1 department';
  if (departments.length === 1) return oddCaps(departments[ 0 ]);
  departments = departments.map((dept) => oddCaps(dept));
  return departments.slice(0, -1).join(', ') + ' and ' + departments.slice(-1);
}

const JobPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Job>(new Job());
  const [ edit, setEdit ] = useState<Job>(new Job());
  const [ options, setOptions ] = useState<JobOptions>(new JobOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ read.display_title } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Typography variant='body1' color='primary'>{ read.description }</Typography>
                { read.index <= 10 ?    // vp+
                  <>
                    <Typography variant='body1' sx={ { mt: 2 } }>The { read.display_title } is charged with managing the people, processes and activities of following Domains:</Typography>
                    <DomainList domains={ read.domains } options={ options.domains } />
                  </>
                  : read.index <= 30 ?    // manager+
                    <>
                      <Typography variant='body1' sx={ { mt: 2 } }>A { read.display_title } manages the people, processes and activities of the following Departments:</Typography>
                      <DeptList departments={ read.departments } />
                    </>
                    : read.index <= 40 ?    // supervisor
                      <>
                        <Typography variant='body1' sx={ { mt: 2 } }>A { read.display_title } manages the people and activities of the following { pluralize('Department', read.departments.length) }: { deptShortList(read.departments) }</Typography>
                      </>
                      : read.index <= 50 ?    // associate
                        <>
                          <Typography variant='body1' sx={ { mt: 2 } }>A { read.display_title } manages the tasks and activities of the following { pluralize('Department', read.departments.length) }: { deptShortList(read.departments) }</Typography>
                        </>
                        :                       // contractor
                        <>
                          <Typography variant='body1' sx={ { mt: 2 } }>A { read.display_title } manages the activities associated with the following { pluralize('Department', read.departments.length) }: { deptShortList(read.departments) }</Typography>
                        </>
                }
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <JobForm job={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default JobPage;
