
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Organization, OrganizationOptions } from '../../lib/classes';
import { InputText, InputSelect, InputMultiSelectTags, InputSwitch } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';

interface Props {
  organization: Organization;
  options: OrganizationOptions | any;
}

const OrganizationForm: FC<Props> = ({ organization, options }: any) => {
  const methods = useForm({ defaultValues: organization, shouldUnregister: true });
  const { watch } = methods;

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 3 } xs={ 6 }>
              <InputText label='Name' name='name' />
            </Grid>
            <Grid item md={ 3 } xs={ 6 }>
              <InputText label='Website' name='website' type='url' />
            </Grid>
            <Grid item md={ 3 } xs={ 6 }>
              <InputSelect
                name='status'
                label='Status'
                options={ options.status }
                tip
                value_as_label
                required
              />
            </Grid>
            <Grid item md={ 3 } xs={ 6 } sx={ { textAlign: 'right' } }>
              <InputSwitch
                name='do_not_call'
                label='Do Not Call'
              />
            </Grid>
            <Grid item md={ 12 } >
              <InputMultiSelectTags
                options={ options.types }
                label='Relationships'
                name='types'
                value_as_label
              />
            </Grid>
            { watch('types').includes('customer') || watch('types').includes('prospect') ?
              <Grid id='customer_section' item xs={ 12 } container spacing={ 2 }>
                <Grid item md={ 3 } xs={ 8 }>
                  <InputSwitch
                    name='franchise_holding'
                    label='Franchise Holding'
                  />
                </Grid>
              </Grid>
              : null }
            <Grid item md={ 4 } xs={ 12 }>
              {/* <InputSelect
                label='Primary Contact'
                options={ options.contact }
                tip
                name='contact'
              /> */}
            </Grid>
            <Grid item md={ 5 } xs={ 12 }>
              {/* <InputSelect
                label='Main Address'
                options={ options.address }
                tip
                name='address'
              /> */}
            </Grid>
            <Grid item xs={ 12 }>
              <InputText name='notes' label='Notes' multiline minRows={3} />
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default OrganizationForm;
