import { AccountInfo } from '@azure/msal-browser';
import { dateFormatted } from '../Helpers/Tools.helper';
import {
  IUserContext,
  IAcknowledgement,
  IAcknowledgementOptions,
  IActivity,
  IActivityOptions,
  IAddress,
  IAddressOptions,
  IApproval,
  IAsset,
  IAssetOptions,
  IClassification,
  IClassificationOptions,
  IContact,
  IContactOptions,
  IControl,
  IControlOptions,
  IDocument,
  IDocumentOptions,
  IException,
  IExceptionOptions,
  IJob,
  IJobOptions,
  IMechanism,
  IMechanismOptions,
  IOptions,
  IOrganization,
  IOrganizationOptions,
  IPolicy,
  IPolicyOptions,
  IRequest,
  IRequestOptions,
  IResponse,
  IResponseOptions,
  IService,
  IServiceOptions,
  IUser,
  IUserOptions,
  IRole,
  IRoleOptions,
} from './interfaces';

const todays_date = dateFormatted('today');
const this_month = new Date(todays_date).getMonth();
const this_day = new Date(todays_date).getDate();

export interface APIReturns {
  read:
    | Acknowledgement[]
    | Activity[]
    | Address[]
    | Asset[]
    | Classification[]
    | Contact[]
    | Control[]
    | Document[]
    | Exception[]
    | Job[]
    | Mechanism[]
    | Organization[]
    | Policy[]
    | Request[]
    | Response[]
    | Service[]
    | User[];
  edit:
    | Acknowledgement[]
    | Activity[]
    | Address[]
    | Asset[]
    | Classification[]
    | Contact[]
    | Control[]
    | Document[]
    | Exception[]
    | Job[]
    | Mechanism[]
    | Organization[]
    | Policy[]
    | Request[]
    | Response[]
    | Service[]
    | User[];
  options:
    | AcknowledgementOptions[]
    | ActivityOptions[]
    | AddressOptions[]
    | AssetOptions[]
    | ClassificationOptions[]
    | ContactOptions[]
    | ControlOptions[]
    | DocumentOptions[]
    | ExceptionOptions[]
    | JobOptions[]
    | MechanismOptions[]
    | OrganizationOptions[]
    | PolicyOptions[]
    | RequestOptions[]
    | ResponseOptions[]
    | ServiceOptions[]
    | User[];
}
export interface AADAccount extends AccountInfo {
  name: string;
  idTokenClaims: {
    given_name: string;
    family_name: string;
    roles: string[];
    groups: string[];
  };
}
export class UserContextClass implements IUserContext {
  username = '';
  name = {
    first: '',
    last: '',
    full: '',
  };
  roles = [];
  groups = [];
}

export class Approval implements IApproval {
  status = 'pending';
  date = null;
  by = '';
  explanation = '';
  notes = '';
}
export class Options implements IOptions {
  value = '';
  label = '';
  tip = '';
}

export class Acknowledgement implements IAcknowledgement {
  _id = '';
  author = '';
  type = '';
  text = '';
  exception = '';
  approval = {
    approved: false,
    by: '',
    date: null,
  };
}
export class AcknowledgementOptions implements IAcknowledgementOptions {
  type = [];
}
export class Activity implements IActivity {
  _id = '';
  name = '';
  description = '';
  category = '';
  status = '';
  result = '';
  owner = '';
  start = null; // date
}
export class ActivityOptions implements IActivityOptions {
  status = [];
  result = [];
  category = [];
}
export class Address implements IAddress {
  _id = '';
  type = '';
  name = '';
  street1 = '';
  street2? = '';
  city = '';
  state = '';
  zip = '';
  country = 'US';
}
export class AddressOptions implements IAddressOptions {
  type = [];
  state = [];
  country = [];
}
export class Asset implements IAsset {
  _id = '';
  name = '';
  description = '';
  notes = '';
  dates = {
    acquired: null,
    retired: null,
  };
  category = '';
  ownership = 'gnci';
  owner = ''; // oid
  owner_org = ''; // oid
  custodian = '61afbbe66ac90dc9212d0caf'; // oid
  data_protection = {
    security: {
      classification: '', // oid
      rationale: '',
    },
    tags: [],
    controls: [],
  };
  hardware = {
    vm: false,
    vm_host: '',
    type: '',
    hostname: '',
    serial_number: '',
    manufacturer: '',
    model: '',
    os: '',
    printer: {
      type: '',
      tech: '',
      color: false,
      network: false,
    },
    network: {
      type: '',
    },
  };
  software = {
    type: '',
    manufacturer: '',
    version: '',
    license_key: '',
    os: {
      family: '',
      name: '',
    },
  };
  service_provider = {
    provider: '',
    type: '',
    renewal: {
      frequency: '',
      years: 1,
      month: this_month,
      day: this_day,
      last_payment: null,
      auto: false,
    },
  };
  real_estate = {
    address: '',
    purposes: [],
    attributes: {
      shipping: false,
      receiving: false,
      operations: false,
      staff: false,
    },
  };
  system = {
    components: [],
  };
  data = {
    type: '',
    hosts: [],
  };
  support_contract = {
    provider: '',
    covers: {
      users: [],
      assets: [],
    },
    renewal: {
      frequency: '',
      years: 1,
      month: this_month,
      day: this_day,
      last_payment: null,
      auto: false,
    },
  };
}
export class AssetOptions implements IAssetOptions {
  assets = [];
  addresses = [];
  activities = [];
  controls = [];
  organizations = [];
  services = [];
  users = [];
  data_protection = {
    security: {
      classification: [],
    },
    tags: [],
    controls: [],
  };
  ownership = [];
  category = [];
  business_function = [];
  state = [];
}
export class Classification implements IClassification {
  _id = '';
  name = '';
  description = '';
  type = 'tag';
  restrictive_index = 20;
  notes = '';
}
export class ClassificationOptions implements IClassificationOptions {
  type = [];
  classifications = [];
}
export class Contact implements IContact {
  _id = '';
  name = {
    first: '',
    last: '',
    nick: '',
  };
  display_name = {
    first: '',
    full: '',
    def: '',
    both: '',
  };
  company = '';
  emails = [{ type: '', address: '', preferred: false }];
  phones = [{ type: '', number: '', preferred: false }];
  addresses = [{ type: '', address: '', preferred: false }];
  role = '';
  notes = '';
  authority = false;
  active = true;
  do_not_call = false;
}
export class ContactOptions implements IContactOptions {
  company = [];
}
export class Control implements IControl {
  _id = '';
  name = '';
  policy = '';
  policy_index = '';
  control_id = '';
  objective = '';
  standard = '';
  guidelines? = [];
  active = false;
  notes = '';
  approval = new Approval();
  mechanisms = [];
  requests = [];
  status = {
    value: '',
    control: '',
    mechanisms: '',
    date_active: null,
  };
}
export class ControlOptions implements IControlOptions {
  policy = [];
  customers = [];
  approval = {
    status: [],
  };
}
export class Document implements IDocument {
  _id = '';
  title = '';
  type = '';
  author = new User();
  version = '';
  filename = '';
  comments = '';
  description = '';
  content = '';
  revisions = [];
}
export class DocumentOptions implements IDocumentOptions {
  type = [];
  author = [];
  documents = [];
}
export class Exception implements IException {
  _id = '';
  requestor = '';
  users = [];
  exception = '';
  reason = '';
  data? = '';
  custodians = [];
  duration = {
    requested: {
      start: null,
      end: null,
    },
    approved: {
      start: null,
      end: null,
    },
  };
  disposition = {
    approved: false,
    by: '',
    date: null,
    deny_reason: '',
  };
  notes = '';
}
export class ExceptionOptions implements IExceptionOptions {
  exception = [];
}
export class Job implements IJob {
  _id = '';
  title = '';
  descriptor = '';
  description = '';
  job_description = '';
  index = 1000;
  domains = [];
  departments = [];
  compensation = {
    min: {
      rate: 0,
      per: 'pay_period',
    },
    max: {
      rate: 0,
      per: 'pay_period',
    },
  };
  skills = {
    required: [],
    preferred: [],
  };
  display_title = '';
}
export class JobOptions implements IJobOptions {
  title = [];
  domains = [];
  departments = [];
  pay_per = [];
}
export class Mechanism implements IMechanism {
  _id = '';
  name = '';
  audit_ready = false;
  controls = [];
  type = [];
  description = '';
  goals = [];
  notes = '';
  providers = [];
  consumers = [];
  documents = [];
  links = [];
  approval = new Approval();
  initial = {
    start: null,
    est_duration: '',
    est_complete: null,
    complete: false,
    notes: '',
  };
  status = 'pending';
}
export class MechanismOptions implements IMechanismOptions {
  controls = [];
  type = [];
  goals = [];
  assets = [];
  approval = [];
}
export class Organization implements IOrganization {
  _id = '';
  name = '';
  website = '';
  franchise_holding = false;
  franchisor = [];
  phone = '';
  address = '';
  contact = '';
  types = [];
  status = 'active';
  do_not_call = false;
  notes = '';
}
export class OrganizationOptions implements IOrganizationOptions {
  types = [];
  status = [];
  address = [];
  contact = [];
}
export class Policy implements IPolicy {
  _id = '';
  name = '';
  code = '';
  policy = '';
  management_intent = '';
  framework = 'iso_27001';
  active = false;
  approval = new Approval();
  notes = '';
  controls = [];
  customers = [];
  requests = [];
  status = {
    value: 'pending',
    policy: 'pending',
    controls: '',
  };
}
export class PolicyOptions implements IPolicyOptions {
  framework = [];
  approval = [];
}
export class Request implements IRequest {
  _id = '';
  name = '';
  description = '';
  customer = '';
  inquiries = [
    {
      inquiry: '',
      reference: '',
      controls: [],
      priority: 3,
    },
  ];
  notes = [];
  dates = {
    start: null,
    due: null,
    complete: null,
    received: null,
  };
}
export class RequestOptions implements IRequestOptions {
  customer = [];
  controls = [];
  priority = [];
}
export class Response implements IResponse {
  _id = '';
  name = '';
  description = '';
  system = '';
}
// export class CRisk {        // not done
//     _id?: string;       // oid
//     name: string;
//     description: string;
// }
// export class CRole {
//     _id?: string;       // oid
//     type: string;
//     description: string;
// }
// export class CRoleOptions {
//     // not done
// }
export class Role implements IRole {
  _id = '';
  type = '';
  title = '';
  description = '';
  compliance = {
    category: '',
    specialty: '',
    role_id: '',
    work: '',
  }
  keyholder = {
    description: '',
    type: '',
    locations: [],
  }
  users = [];
  controls = [];
}
export class RoleOptions implements IRoleOptions {
  type = [];
  compliance = {
    category: [],
    specialty: [],
  }
  keyholder = {
    type: []
  }
}
export class Service implements IService {
  _id = '';
  name = '';
  description = '';
}
export class ServiceOptions implements IServiceOptions {}
// export class CTouch {        // not done
//     _id?: string;       // oid
//     name: string;
//     description: string;
// }
// export class CTouchOptions {
//     method: string[];
// }
// export class CTraining {        // not done
//     _id?: string;       // oid
//     name: string;
//     description: string;
// }
// export class CTrainingOptions {
//     type: string[];
// }
export class ResponseOptions implements IResponseOptions {
  system = [];
}
export class User implements IUser {
  _id = '';
  active = false;
  username = '';
  name = {
    first: '',
    last: '',
    nick: '',
    preferred: '',
  };
  gui = {
    mode: 'light',
    drawer: 'closed',
  };
  display_name = {
    first: '',
    full: '',
    def: '',
    formal: '',
  };
  person = true;
  title = '';
  source = '';
  source_id = '';
  dates = {
    start: new Date(),
    end: undefined,
  };
  alt_email = '';
  jobs = [];
  job = {
    _id: '',
    start: new Date(),
    title: '',
    title_value: '',
    description: ''
  }
  managers = [];
  manager = {
    _id: '',
    start: new Date(),
    name: '',
    title: '',
  }
  staff = [];
  roles = [];
  site = new Asset();
  phones = [];
  addresses = [];
  emergency_contact = {
    name: '',
    phone: '',
    email: '',
    relationship: '',
  };
  compensation = [];
}
export class UserOptions implements IUserOptions {
  phones = {
    type: [],
    country: [],
  };
  address = {
    type: [],
  };
  addresses = [];
  compensation = {
    per: [],
  };
  gui = {
    mode: [],
    drawer: [],
  };
  site = [];
  acknowledgements = [];
  training = [];
  roles = [];
  managers = [];
  jobs = [];
}
