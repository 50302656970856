
import { startCase } from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import NumberFormat, { NumberFormatProps } from 'react-number-format';     // https://github.com/s-yadav/react-number-format#readme
import MenuItem from '@mui/material/MenuItem';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Options } from '../lib/classes';

interface OptionsProps {
  options: Options[] | Options;
  value_as_label?: boolean;
  tip_placement?: TooltipProps[ 'placement' ];
}

export const optionsTip = ({ options, value_as_label, tip_placement }: OptionsProps) => {
  const createOption = (option: Options) => {
    const value = option.value;
    const label = value === null ? 'Please Choose...' : value_as_label ? oddCaps(option.value) : option.label;
    const tip = option.tip || option.label || '';
    tip_placement = tip_placement ? tip_placement : 'right';
    return (
      <MenuItem key={ value } value={ value }>
        <Tooltip placement={ tip_placement } title={ tip }>
          <span>{ label }</span>
        </Tooltip>
      </MenuItem>
    )
  }
  if (Array.isArray(options)) {
    return options.map((option: Options) => createOption(option));
  }
  return createOption(options);
}
export const optionsPlain = ({ options, value_as_label }: OptionsProps) => {
  const createOption = (option: Options) => {
    const value = option.value;
    const label = value === null ? 'please Choose...' : value_as_label ? oddCaps(option.value) : option.label;
    return (<MenuItem key={ value } value={ value }>{ label }</MenuItem>)
  }
  if (Array.isArray(options)) {
    return options.map((option: Options) => createOption(option));
  }
  return createOption(options);
}
export const MultiSelectValuesConvert = (values: any) => {
  const result = values.map((value: string | Options) => {
    value = typeof value === 'string' ? value : value.value;
    return value;
  });
  return result;
}
export const phoneFormat = (value: string, country?: string, displayType?: NumberFormatProps[ 'displayType' ]) => {
  switch (country) {
    default: return (<NumberFormat format='(###) ###-####' displayType={ displayType ? displayType : 'input' } value={ value } />);
    case 'MX': return (<NumberFormat format='###-###-######' displayType={ displayType } value={ value } />);
  }
}
type dateFormat = 'input' | 'month_day' | 'schedule' | 'long' | 'file';
export const dateFormatted = (date: Date | 'today', desired_format?: dateFormat) => {
  // if (!date) return null;
  date = date === 'today' ? new Date() : date;
  switch (desired_format) {
    default: return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'M/d/yyyy');
    case 'input': return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'yyyy-MM-dd');
    case 'month_day': return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'M/d');
    case 'schedule': return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'M/d/yyyy h:mm a');
    case 'long': return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'PPPP');
    case 'file': return formatInTimeZone(new Date(date), 'America/Los_Angeles', 'yyyy-MM-dd');
  }
}
export const oddCaps = (item: string) => {
  interface Oddball {
    value: string;
    label: string;
  }
  const oddballs: Oddball[] = [
    { value: 'vmware', label: 'VMWare' },
    { value: 'gnci', label: 'GNCI' },
    { value: 'macos', label: 'MacOS' },
    { value: 'ios', label: 'iOS' },
    { value: 'ipados', label: 'iPadOS' },
    { value: 'iso_27001', label: 'ISO 27001' },
    { value: 'nist', label: 'NIST' },
    { value: 'fedex', label: 'FedEx' },
    { value: 'mechanism', label: 'Control Mechanism' },
    { value: 'user', label: 'GNCI Person' },
    { value: 'request', label: 'Compliance Request' },
    { value: 'voip', label: 'VOIP' },
    { value: 'keyholder', label: 'Key Holder' },
    { value: 'vp', label: 'VP' },
    { value: 'ceo', label: 'CEO' },
    { value: 'it_support', label: 'IT Support' },
  ]
  const oddball = oddballs.find((o) => {return o.value === item});
  return oddball ? oddball.label : startCase(item);
}

