import { FC } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Copyright from './Components/Partials/Copyright';
import GNCILogo from './Components/Images/GNCI_Logo.png';
import Globe from './Components/Images/globe.png';
import { loginRequest } from './Auth/config.auth';
import { useMsal } from '@azure/msal-react';
import { Link, useTheme } from '@mui/material';

const Login: FC = () => {
  const theme = useTheme();
  const { instance } = useMsal();
  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid container component='main' sx={ { height: '100vh' } }>
      <CssBaseline />
      <Grid item xs={ 12 } sm={ 8 } md={ 3 } component={ Paper } elevation={ 6 } square>
        <Box
          sx={ {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          } }
        >
          <img src={ GNCILogo } alt='GNCI Logo' style={ { height: '100px', marginBottom: '10px' } } />
          <Box marginBottom={3}>
            <Typography sx={{ fontSize: 22, textAlign: 'center' }}><span style={{ fontWeight: 700 }}>Global Net</span> Commerce, Inc.</Typography>
            <Typography variant='body1' sx={{ textAlign: 'center' }}><small>Secure In Wireless * Solid In Solutions</small></Typography>
          </Box>
          <Typography variant='h1'>
            GNCI Control
          </Typography>
          <Button
            type='button'
            onClick={ () => handleLogin() }
            fullWidth
            variant='contained'
            color='primary'
            sx={ { margin: theme.spacing(3, 0, 2) } }
          >
            Click Here to Sign In
          </Button>
          <Box mt={ 10 } position='fixed' bottom={ 10 }>
            <Copyright />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sx={ {
          backgroundImage: `url(${Globe})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        } }
        xs={ false }
        sm={ 4 }
        md={ 9 }
      />
    </Grid>
  );
};

export default Login;
