
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Waiting from '../Components/Partials/Waiting';
import { Request } from '../lib/classes';
import { AllRequests } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';

interface Props {
  requests: Request[];
}

const Requests: FC = () => {
  const { collection } = useParams();
  const [ requests, setRequests ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { requests } = data;
      setRequests(requests);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <AllRequests data={ requests } />
        : <Waiting /> }
    </>
  )
}

export default Requests;
