
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Control, ControlOptions } from '../../lib/classes';
import { InputText, InputSelect, InputNumber, InputTextarea, InputMultiSelectTags } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';

interface Props {
  control: Control;
  options: ControlOptions | any;
}

const ControlForm: FC<Props> = ({ control, options }: any) => {
  const methods = useForm({ defaultValues: control, shouldUnregister: true });
  console.log(options)

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item md={ 4 } xs={ 12 }>
            <InputText label='Name' name='name' />
          </Grid>
          <Grid item md={ 2 } xs={ 12 }>
            <InputNumber label='Control Index' name='policy_index' min={ 1 } max={ 99 } />
          </Grid>
          <Grid item md={ 4 } xs={ 12 }>
            <InputSelect
              name='policy'
              label='Policy'
              options={ options.policy }
              tip
              required
            />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='objective' label='Objective of the Control' multiline minRows={ 2 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='standard' label='Standard' multiline minRows={ 4 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputTextarea name='guidelines' label='Control Guidelines' multiline minRows={ 4 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='notes' label='Notes' multiline minRows={ 3 } />
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default ControlForm;
