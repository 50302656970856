import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

const Copyright = () => {
  const copyright = `©  ${new Date().getFullYear()} `;
  const link = <Link href='https://gnciwireless.com' color='inherit'>GNCI</Link>
  return (
    <Typography variant='body2'>
      { copyright }  { link }  All rights reserved.
    </Typography>
  )
}

const StickyFooter: FC = () => {

  return (
    <Container maxWidth='sm'>
      <Typography variant='body1'>
        Secure in Wireless + Solid in Solutions
      </Typography>
      <Copyright />
    </Container>
  );
}
export default StickyFooter
