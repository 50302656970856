
import { FC } from 'react';
import DataTable, { MUIDataTableColumnDef } from 'mui-datatables';     // https://www.npmjs.com/package/mui-datatables
import startCase from 'lodash/startCase';
import pluralize from 'pluralize';
import { dateFormatted } from '../../Helpers/Tools.helper';
import { Control } from '../../lib/classes';
import TableToolbar from './_Toolbar.table';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack';

const useTableOptions = (collection: string, total: number) => {
  const result: any = {
    responsive: 'vertical',
    print: false,
    downloadOptions: {
      filename: `${pluralize(startCase(collection))}_${dateFormatted('today', 'file')}`,
    },
    pagination: false,
    searchPlaceholder: `Find Inquiry`,
    elevation: 0,
    selectableRows: 'none',
    fixedHeader: true,
    enableNestedDataAccess: '.',
    setTableProps: () => ({ size: 'small' }),
    customToolbar: ({ displayData }: any) => {
      return (<TableToolbar total={ total } qty={ displayData.length } />)
    }
  }
  return result;
}
const getControlIds = (controls: Control[]) => {
  return (
    <Stack>
      { controls.map((control: Control) => {
        return <Link key={ `control-${control._id}` } href={ `/control/${control._id}` }>{ control.control_id }</Link>
      })
      }
    </Stack>
  )
}
const getControlsStatus = (controls: Control[]) => {
  const result = controls.map((control: Control) => {
    return startCase(control.status.value);
  });
  return result.join('\n');
}

export const InquiriesTable: FC<any> = ({ data }) => {
  const table_data = data.map((o: any) => {
    const _id = o._id;
    const reference = o.reference;
    const inquiry = o.inquiry;
    const controls = getControlIds(o.controls);
    const priority = o.priority;
    const status = getControlsStatus(o.controls);
    return { _id, reference, inquiry, controls, priority, status }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: { display: 'excluded' } },
    { name: 'reference', label: 'Ref' },
    { name: 'inquiry', label: 'Inquiry' },
    { name: 'controls', label: 'Controls', options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: 'priority', label: 'Priority', options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: 'status', label: 'Status' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h4' sx={ { paddingLeft: '0px' } }>Compliance Inquiries</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('address', data.length) }
    />
  )
}

export default InquiriesTable
