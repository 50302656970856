
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import User from './Pages/User';
import Policy from './Pages/Policy';
import Control from './Pages/Control';
import Mechanism from './Pages/Mechanism';
import Document from './Pages/Document';
import Response from './Pages/Response';
import Asset from './Pages/Asset';
import Request from './Pages/Request';
import Service from './Pages/Service';
import Address from './Pages/Address';
import Activity from './Pages/Activity';
import Job from './Pages/Job';
import Role from './Pages/Role';
import Organization from './Pages/Organization';
import Classification from './Pages/Classification';
import Contact from './Pages/Contact';
import Box from '@mui/material/Box';

const Page: FC = () => {
  const { collection } = useParams();
  return (
    <Box m={ 1 }>
      {
        collection === 'user' ?
          <User />
          : collection === 'policy' ?
            <Policy />
            : collection === 'control' ?
              <Control />
              : collection === 'mechanism' ?
                <Mechanism />
                : collection === 'document' ?
                  <Document />
                  : collection === 'response' ?
                    <Response />
                    : collection === 'asset' ?
                      <Asset />
                      : collection === 'service' ?
                        <Service />
                        : collection === 'address' ?
                          <Address />
                          : collection === 'activity' ?
                            <Activity />
                            : collection === 'job' ?
                              <Job />
                              : collection === 'role' ?
                                <Role />
                                : collection === 'organization' ?
                                  <Organization />
                                  : collection === 'classification' ?
                                    <Classification />
                                    : collection === 'contact' ?
                                      <Contact />
                                      : collection === 'request' ?
                                        <Request />
                                        : null }
    </Box>
  )
}

export default Page;
