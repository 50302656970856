
import { CSSProperties, FC } from 'react';
import { Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './_Input.RichText.css';

type RichTextProps = {
  name: string;
  onUpdate?: any;
}

const getEditorState = (name: any) => {
  const { contentBlocks, entityMap } = htmlToDraft(name || '');
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};
const toolbarOptions = {
  options: [ 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'embedded', 'remove', 'history' ],
  blockType: [ 'Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code' ],
  fontSize: [ 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96 ],
  fontFamily: [ 'Roboto', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana' ],
}
const InputRichText: FC<RichTextProps> = ({ name, onUpdate }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => {
        const onInternalChange = (currentContentState: any) => {
          const html = draftToHtml(currentContentState);
          onChange(html);
          if (onUpdate) onUpdate(html);
        }
        const defaultEditorState = getEditorState(value);
        const editorStyle: CSSProperties = {
          border: '1px solid #565c65',
          height: '75vh',
          padding: '20px',
          backgroundColor: 'white',
          color: 'black',
        }
        const toolbar = {     // defaults can be found here: https://jpuri.github.io/react-draft-wysiwyg/#/docs
          options: toolbarOptions.options,
          inline: {
            inDropdown: false,
            options: [ 'bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript' ],
          },
          blockType: {
            inDropdown: true,
            options: toolbarOptions.blockType,
            className: 'draft-dropdown',
            dropdownClassName: 'draft-dropdown',
          },
          fontSize: {
            options: toolbarOptions.fontSize,
            className: 'draft-dropdown',
            dropdownClassName: 'draft-dropdown',
          },
          fontFamily: {
            options: toolbarOptions.fontFamily,
            className: 'draft-dropdown',
            dropdownClassName: 'draft-dropdown',
          },
          list: {
            inDropdown: false,
            options: [ 'unordered', 'ordered', 'indent', 'outdent' ],
          },
          textAlign: {
            inDropdown: false,
            options: [ 'left', 'center', 'right', 'justify' ],
          },
          colorPicker: {
            colors: [ 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
              'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
              'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
              'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
              'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
              'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)' ],
          },
          link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: [ 'link', 'unlink' ],
          },
        }
        const myBlockStyleFn = (contentBlock: any) => {
          const type = contentBlock.getType();

        }
        return (
          // <html>
          //   <meta charSet='utf-8' />
          //   <title>Document Editor</title>

            <Editor
              spellCheck
              defaultEditorState={ defaultEditorState }
              tabIndex={ 0 }
              onChange={ onInternalChange }
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              toolbar={ toolbar }
              editorStyle={ editorStyle }
            // customStyleMap={}
            // blockStyleFn={ myBlockStyleFn }
            />
          // </html>
        )
      } }
    />
  )
}
export default InputRichText
