
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import PolicyForm from '../Components/Forms/Policy.form';
import Grid from '@mui/material/Grid';
import { Approval, Control, Organization, Policy, PolicyOptions, Request } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import pluralize from 'pluralize';
import PagerHeader from './_PageHeader';
import { CollectionIcons } from '../Helpers/IconMap.helper';
import startCase from 'lodash/startCase';
import ApprovalPart from '../Components/Partials/Approval.partial';

const PolicyPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Policy>(new Policy());
  const [ edit, setEdit ] = useState<Policy>(new Policy());
  // const [ requests, setRequests ] = useState<Request[]>([]);
  const [ options, setOptions ] = useState<PolicyOptions>(new PolicyOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      console.log(data)
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const approval: any = read.approval || new Approval();
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader
            title={ `${read.name} Policy (${read.code})` }
            handler={ readEditHandler }
            hideForm={ hideForm }
            approval={ edit.approval }
            options={ options.approval }
          />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Typography variant='body1' whiteSpace='pre-wrap' color='primary'>{ read.policy }</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='body2'>{ read.management_intent }</Typography>
              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Typography variant='body2'>
                  The { read.name } Policy is supported by { read.controls.length } { pluralize('Control', read.controls.length) }:
                </Typography>
                <List sx={ { pr: 2 } } dense>
                  { read.controls.map((control: Control) => {
                    return (
                      <Link key={ control._id } href={ `/control/${control._id}` } underline='hover'>
                        <ListItem dense disablePadding>
                          <ListItemIcon>
                            <CollectionIcons collection='control' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                            primary={ `${control.name} (${startCase(control.status.value)})` }
                            secondary={ control.objective }
                          />
                        </ListItem>
                      </Link>
                    )
                  }) }
                </List>
              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Typography variant='body2'>
                  This Policy has been referenced on { read.requests.length } customer { pluralize('request', read.requests.length) }:
                </Typography>
                <List sx={ { pr: 2 } } dense>
                  { read.requests.map((request: any) => {
                    return (
                      <Link key={ request._id } href={ `/request/${request._id}` } underline='hover'>
                        <ListItem dense disablePadding>
                          <ListItemIcon>
                            <CollectionIcons collection='request' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                            primary={ request.customer.name }
                            secondary={ request.name }
                          />
                        </ListItem>
                      </Link>
                    )
                  }) }
                </List>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='h3'>Approval</Typography>
                <ApprovalPart approval={ approval } status={ read.status } />
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <PolicyForm policy={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default PolicyPage;
