import axios, { AxiosRequestConfig, Method } from 'axios';
import { loginRequest } from '../Auth/config.auth';
import { instance } from '../App'

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const getToken = async (request: any) => {
  request.account = instance.getActiveAccount();
  try {
    const silent = await instance.acquireTokenSilent(request);
    // console.log(silent)
    return silent.idToken;
  }
  catch (silentError) {
    // console.log(silentError);
    try {
      const popUp = await instance.acquireTokenPopup(request);
      return popUp.idToken;
    }
    catch (popupError) {
      // console.log(popupError)
    }
  }
}

export type API_Props = {
  collection: string;
  id?: string;
  data?: any;
}

export type API_Options = {
  method: Method;
  url: string;
  data?: any;
}
export const control = async ({ method, url, data }: API_Options) => {
  const token = await getToken(loginRequest);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  const options: AxiosRequestConfig = {
    baseURL: '/api',
    url,
    method,
    headers,
    data,
  }
  const res = await axios(options);
  return res;
}
