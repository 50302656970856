
import { FC, useState, useEffect } from 'react';
import { Organization, OrganizationOptions } from '../lib/classes';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import OrganizationForm from '../Components/Forms/Organization.form';
import Grid from '@mui/material/Grid';
import Waiting from '../Components/Partials/Waiting';
import Paper from '@mui/material/Paper';
import PagerHeader from './_PageHeader';
import { Link, List, ListItem, Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { oddCaps } from '../Helpers/Tools.helper';

const OrganizationPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Organization>(new Organization());
  const [ edit, setEdit ] = useState<Organization>(new Organization());
  const [ options, setOptions ] = useState<OrganizationOptions>(new OrganizationOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ read.name } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Link href={ read.website || '#' }>
                  <Typography variant='h1'>{ read.name }</Typography>
                </Link>
              </Grid>
              <Grid item xs={ 12 } sx={ { textAlign: 'center' } }>
                <Typography variant='h2'>{ pluralize('Relationship', read.types.length) } </Typography>
                <List component={ Stack } direction='row' spacing={ 2 }>
                  { read.types.map((type) => {
                    <ListItem>{ oddCaps(type) }</ListItem>
                  }) }
                </List>
              </Grid>
              <Grid item xs={ 12 }>
                <Typography variant='body1'>{ read.notes }</Typography>
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <OrganizationForm organization={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default OrganizationPage;
