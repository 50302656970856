
import { FC } from 'react';
import { InputSelect, InputDate } from './_Input';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Options } from '../../lib/classes';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { orderBy, startCase } from 'lodash';
import { FormIcons } from '../../Helpers/IconMap.helper';


type ManagersProps = {
  name: string;
  options: Options[];
}
const InputManagers: FC<ManagersProps> = ({ name, options }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name,
  });
  return (
    <Grid item container spacing={ 1 } sx={ { m: 1 } }>
      <Grid id={ `${name}-header` } item xs={ 12 } container spacing={ 2 }>
        <Typography variant='body1'>
          { startCase(name) }
          <span>
            <IconButton color='success' onClick={ () => append({ manager: '', start: new Date(), end: null }) }>
              <FormIcons item='add' />
            </IconButton>
          </span>
        </Typography>
      </Grid>
      <Grid id={ `${name}-table` } item xs={ 12 } container spacing={ 2 }>
        <Table size='small' sx={ { width: '100%' } }>
          <TableHead>
            <TableRow>
              <TableCell sx={ { px: 1, py: 0 } }>Manager</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }>Start</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }>End</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* { fields.map((item: any, i: number) => { */}
            { orderBy(fields, ['start'], ['desc']).map((item: any, i: number) => {
              return (
                <TableRow key={ `${name}-${i}` }>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <InputSelect
                      name={ `${name}[${i}].manager` }
                      label=''
                      options={ options }
                      table
                      tip
                    />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <InputDate name={ `${name}[${i}].start` } label='' />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>
                    <InputDate name={ `${name}[${i}].end` } label='' />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <IconButton color='error' size='small' onClick={ () => remove(i) }>
                      <FormIcons item='nuke' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
export default InputManagers
