
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import { User, UserOptions } from '../lib/classes';
import UserForm from '../Components/Forms/User.form';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Waiting from '../Components/Partials/Waiting';
import Paper from '@mui/material/Paper';
import PagerHeader from './_PageHeader';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { EmailData, PhoneData, StaffData, UserJobs, UserManagers, UserRoles } from '../Components/Tables/User.table'
import { dateFormatted } from '../Helpers/Tools.helper';
import Link from '@mui/material/Link';
import { formatDistanceToNow, intervalToDuration } from 'date-fns';
import Box from '@mui/material/Box';
import { getCountry } from '../Helpers/StaticData.helper';
import pluralize from 'pluralize';
interface Props {
  read: User;
  options: UserOptions;
  edit: User;
}

const UserPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<User>(new User());
  const [ edit, setEdit ] = useState<User>(new User());
  const [ options, setOptions ] = useState<UserOptions>(new UserOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: Props) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const emailData = [
    { type: 'work', address: read.username },
    { type: 'recovery', address: read.alt_email },
  ]
  const siteAddress: any = read.site ? read.site.real_estate.address : null;
  const tenure = intervalToDuration({ start: new Date(read.dates.start), end: read.dates.end ? new Date(read.dates.end) : new Date() });

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ read.display_name.def } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid id='name' item xs={ 12 } container spacing={ 8 } direction='row' border='20px white'>
                <Grid id='formalAndTitle' item>
                  <Typography variant='h2'>
                    { read.display_name.formal }
                    { !read.active ?
                      <Typography variant='overline' sx={ { border: '2px solid red', px: 2, ml: 2 } } color='action'>Inactive</Typography>
                      : null }
                  </Typography>
                  { read.job ?
                    <Link href={ `/job/${read.job._id}` } underline='hover'>
                      <Typography variant='h3' sx={ { mt: 1 } }>{ read.job.title }</Typography>
                    </Link>
                    : null }
                </Grid>
                { read.name.nick ?
                  <Grid item>
                    <Typography variant='overline'><small>Nickname</small></Typography>
                    <Typography variant='body2'>{ read.name.nick }</Typography>
                  </Grid>
                  : null }
                { read.name.preferred ?
                  <Grid item>
                    <Typography variant='overline'><small>Preferred First Name</small></Typography>
                    <Typography variant='body2'>{ read.name.preferred }</Typography>
                  </Grid>
                  : null }
              </Grid>
              <Grid id='middle' item xs={ 12 } container spacing={ 4 }>
                <Grid id='contact' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <Typography variant='h5'>Phones</Typography>
                    <PhoneData data={ read.phones } />
                    <Typography variant='h5' sx={ { mt: 2 } }>Email</Typography>
                    <EmailData data={ emailData } />
                  </Grid>
                </Grid>
                <Grid id='role' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <Typography variant='h5'>Manager</Typography>
                    <Link href={ `/user/${read.manager._id}` } underline='hover'>
                      <Typography sx={ { ml: 3 } }>{ read.manager.name }</Typography>
                    </Link>
                    <Typography sx={ { ml: 3 } } variant='body2'>{ read.manager.title }</Typography>
                  </Grid>
                  { read.staff && read.staff.length > 0 ?
                    <Grid item xs={ 12 }>
                      <Typography variant='h5'>Staff</Typography>
                      <StaffData data={ read.staff } />
                    </Grid>
                    : null }
                </Grid>
                <Grid id='trivia' item md={ 4 } xs={ 12 } container spacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <Typography>{ read.display_name.first } { read.dates.end ? 'was' : 'has been' } with GNCI for { formatDistanceToNow(new Date(read.dates.start)) }.</Typography>
                    <Box ml={ 2 }>
                      <Typography variant='body2'>
                        { tenure.years ? `${tenure.years} ${pluralize('Year', tenure.years)},` : null }
                        { tenure.months ? `${tenure.months} ${pluralize('Month', tenure.months)},` : null }
                        { tenure.days ? `${tenure.days} ${pluralize('Day', tenure.days)}.` : null }
                      </Typography>
                      { read.dates.end ?
                        <Typography variant='body2'>From { dateFormatted(read.dates.start) } to { dateFormatted(read.dates.end) }</Typography>
                        : <Typography variant='body2'>Since { dateFormatted(read.dates.start) }</Typography>
                      }
                    </Box>
                    { siteAddress ?
                      <Box mt={ 2 }>
                        <Typography >{ read.display_name.first }'s primary work location { read.dates.end ? 'was' : 'is' }:</Typography>
                        <Box ml={ 3 }>
                          <Typography>{ siteAddress.name }</Typography>
                          <Typography variant='body2'>{ siteAddress.street1 }</Typography>
                          { siteAddress.street2 ?
                            <Typography variant='body2'>{ siteAddress.street2 }</Typography>
                            : null }
                          <Typography variant='body2'>{ siteAddress.city }, { siteAddress.state }  { siteAddress.zip }</Typography>
                          { siteAddress.country !== 'US' ?
                            <Typography variant='body2'>{ getCountry({ value: siteAddress.country, response_element: 'proper', value_element: 'tld' }) }</Typography>
                            : null }
                        </Box>
                      </Box>
                      : null }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 12 }>
                <Accordion>
                  <AccordionSummary expandIcon={ <ExpandMore /> }>Job History ({ read.jobs.length })</AccordionSummary>
                  <AccordionDetails>
                    <UserJobs data={ read.jobs } />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={ <ExpandMore /> }>Managers ({ read.managers.length })</AccordionSummary>
                  <AccordionDetails>
                    <UserManagers data={ read.managers } />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={ <ExpandMore /> }>Compliance Roles ({ read.roles.length })</AccordionSummary>
                  <AccordionDetails>
                    <UserRoles data={ read.roles } />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <UserForm user={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default UserPage;
