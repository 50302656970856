
import { FC } from 'react';
import { InputText, InputSelect, InputSwitch } from './_Input';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AssetFormProps } from './Asset.form';
import { useFormContext } from 'react-hook-form';

const AssetHardware: FC<AssetFormProps> = ({ asset, options }) => {
  const { watch } = useFormContext();
  return (
    <Grid id='hardware' item container xs={ 12 } spacing={ 2 }>
      <Grid id='hardware_basics' item xs={ 12 } container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>Hardware Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='hardware.type'
              label='Hardware Type'
              required
              options={ options.hardware.type }
              tip
              value_as_label
            />
          </Grid>
          { watch('hardware.type') !== 'printer' ?
            <Grid item xs={ 12 } md={ 3 }>
              <InputSelect
                name='hardware.os'
                label='Operating System'
                // required={ watch('hardware.type') !== 'network' }
                options={ options.hardware.os }
                tip
                none
              />
            </Grid>
            : null }
          { watch('hardware.type') === 'server' || watch('hardware.type') === 'client' ?
            <Grid item xs={ 12 } md={ 3 }>
              <InputSwitch name='hardware.vm' label='Virtual Machine' />
            </Grid>
            : null }
          { watch('hardware.vm') ?
            <Grid item xs={ 12 } md={ 3 }>
              <InputSelect
                name='hardware.vm_host'
                label='Virtual Machine Host'
                required
                options={ options.hardware.vm_host }
                tip
                value_as_label
              />
            </Grid>
            : null }
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 12 } md={ 2 }>
              <InputText name='hardware.hostname' label='Hostname' required />
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <InputText name='hardware.serial_number' label='Serial Number' required />
            </Grid>
            <Grid item xs={ 12 } md={ 2 }>
              <InputSelect
                name='hardware.manufacturer'
                label='Manufacturer'
                required
                options={ options.manufacturer }
                tip
              />
            </Grid>
            <Grid item xs={ 12 } md={ 2 }>
              <InputText name='hardware.model' label='Model' required />
            </Grid>
            <Grid item xs={ 12 } md={ 2 }>
              <InputText name='hardware.part_number' label='Part Number' />
            </Grid>
          </Grid>
          { watch('hardware.type') === 'network' ?
            <Grid item xs={ 12 } container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 3 }>
                <InputSelect
                  name='hardware.network.type'
                  label='Network Device Type'
                  required={ watch('hardware.type') === 'network' }
                  options={ options.hardware.network.type }
                  tip
                  value_as_label
                />
              </Grid>
            </Grid>
            : watch('hardware.type') === 'printer' ?
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 3 }>
                  <InputSelect
                    name='hardware.printer.type'
                    label='Printer Type'
                    required={ watch('hardware.type') === 'printer' }
                    options={ options.hardware.printer.type }
                    tip
                    value_as_label
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                  <InputSelect
                    name='hardware.printer.tech'
                    label='Print Technology'
                    required={ watch('hardware.type') === 'printer' }
                    options={ options.hardware.printer.tech }
                    tip
                    value_as_label
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                  <InputSwitch
                    sx={ { ml: 2 } }
                    name='hardware.printer.color'
                    label='Color'
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                  <InputSwitch
                    sx={ { ml: 2 } }
                    name='hardware.printer.network'
                    label='Network'
                  />
                </Grid>
              </Grid>
              : null }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetHardware;