
import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import FormLayout from './_form.Layout';
import { FormProvider, useForm } from 'react-hook-form';
import { InputAddresses, InputDate, InputJobs, InputPhones, InputRoles, InputSelect, InputText, InputRichText } from './_Input';
import { User, UserOptions } from '../../lib/classes';
import InputManagers from './_Input.Managers';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Edit, ExpandMore } from '@mui/icons-material';

interface Props {
  user: User;
  options: UserOptions;
  // options: any;
}

const UserForm: FC<Props> = ({ user, options }) => {
  const methods = useForm({ defaultValues: user, shouldUnregister: true });
  const tipText = {
    first: "Please make this one your true given name - such as the one on your birth certificate.  There's a spot below for a preferred name.",
    preferred: 'If you go by another first name (e.g. Beth for Elizabeth or Bill for William), put it here.  It will be substituted for your first name.',
    nick: "If you go by a nickname (e.g. 'Magic' for Earvin Johnson), put it here.  It will be substituted for your full name (first/preferred & last).",
    username: "This is the email address used to authenticate the user.  It MUST be registered in Azure to enable authentication.",
    recovery: "This email address will be used in the event you have to reset your GNCI password (you can't get to your @gnciwireless.com email without your GNCI password)."
  }

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid id='name' item xs={ 12 } container spacing={ 2 }>
            <Tooltip title={ tipText.first }>
              <Grid item md={ 3 } xs={ 12 }>
                <InputText name='name.first' label='First Name' required />
              </Grid>
            </Tooltip>
            <Grid item md={ 3 } xs={ 12 }>
              <InputText name='name.last' label='Last Name' required />
            </Grid>
            <Tooltip title={ tipText.preferred }>
              <Grid item md={ 3 } xs={ 12 }>
                <InputText name='name.preferred' label='Preferred First Name' />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.nick }>
              <Grid item md={ 3 } xs={ 12 }>
                <InputText name='name.nick' label='Nick Name' />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid id='login' item md={ 6 } xs={ 12 } container spacing={ 2 }>
            <Tooltip title={ tipText.username }>
              <Grid item md={ 6 } xs={ 12 }>
                <InputText name='username' label='Username' type='email' />
              </Grid>
            </Tooltip>
            <Tooltip title={ tipText.recovery }>
              <Grid item md={ 6 } xs={ 12 }>
                <InputText name='alt_email' label='Recovery Email Address' type='email' />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid id='dates' item md={ 6 } xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 4 } xs={ 0 } />
            <Grid item md={ 4 } xs={ 12 }>
              <InputDate name='dates.start' label='Start Date' required />
            </Grid>
            <Grid item md={ 4 } xs={ 12 }>
              <InputDate name='dates.end' label='Last Day' />
            </Grid>
          </Grid>
          <Grid id='site' item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 5 } xs={ 12 }>
              <InputSelect name='site' label='Primary Work Location' options={ options.site } />
            </Grid>
          </Grid>
          <Grid id='accordion' item xs={ 12 }>
            <Accordion>
              <AccordionSummary expandIcon={ <ExpandMore /> }>Contact Information</AccordionSummary>
              <AccordionDetails>
                <Grid id='contact' item xs={ 12 } container spacing={ 2 }>
                  <Grid id='phones' item md={ 6 } xs={ 12 }>
                    <InputPhones name='phones' options={ options.phones.type } />
                  </Grid>
                  <Grid id='addresses' item md={ 6 } xs={ 12 }>
                    <InputAddresses name='addresses' options={ { type: options.address.type, addresses: options.addresses } } />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={ <ExpandMore /> }>HR Information</AccordionSummary>
              <AccordionDetails>
                <Grid id='hr' item container spacing={ 2 }>
                  <Grid id='jobs' item md={ 6 } xs={ 12 }>
                    <InputJobs name='jobs' options={ options.jobs } />
                  </Grid>
                  <Grid id='managers' item md={ 6 } xs={ 12 }>
                    <InputManagers name='managers' options={ options.managers } />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={ <ExpandMore /> }>Compliance Roles ({ user.roles.length })</AccordionSummary>
              <AccordionDetails>
                <Grid id='compliance' item container spacing={ 2 }>
                  <Grid id='roles' item xs={ 12 }>
                    <InputRoles name='roles' options={ options.roles } />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid>
  );
};

export default UserForm;
