
import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CollectionIcons, SidebarIcons, PageIcons } from '../Helpers/IconMap.helper';

const ClippedDrawer: FC = () => {
  const main = [
    { title: 'Policies', icon: <CollectionIcons collection='policy' />, link: '/policy' },
    { title: 'Controls', icon: <CollectionIcons collection='control' />, link: '/control' },
    { title: 'Control Mechanisms', icon: <CollectionIcons collection='mechanism' />, link: '/mechanism' },
    { title: 'Documents', icon: <CollectionIcons collection='document' />, link: '/document' },
    { title: 'Roles', icon: <CollectionIcons collection='role' />, link: '/role' },
    { title: 'Compliance Requests', icon: <CollectionIcons collection='request' />, link: '/request' },
    // { title: 'Incidents', icon: <CollectionIcons collection='incident' />, link: '/incident' },
    // { title: 'Responses', icon: <CollectionIcons collection='response' />, link: '/response' },
    { title: 'Assets', icon: <CollectionIcons collection='asset' />, link: '/asset' },
    // { title: 'Compliance', icon: <PageIcons item='compliance' />, link: '/compliance' },
    // { title: 'Services', icon: <CollectionIcons collection='service' />, link: '/service' },
  ]
  const ancillary = [
    { title: 'GNCI People', icon: <CollectionIcons collection='user' />, link: '/user' },
    { title: 'Addresses', icon: <CollectionIcons collection='address' />, link: '/address' },
    { title: 'Activities', icon: <CollectionIcons collection='activity' />, link: '/activity' },
    { title: 'Jobs', icon: <CollectionIcons collection='job' />, link: '/job' },
    { title: 'Organizations', icon: <CollectionIcons collection='organization' />, link: '/organization' },
    { title: 'Contacts', icon: <CollectionIcons collection='contact' />, link: '/contact' },
    { title: 'Classifications', icon: <CollectionIcons collection='classification' />, link: '/classification' },
  ]
  const [ drawerWidth, setDrawerWidth ] = useState<number>(60);
  const expandClick = () => {
    return drawerWidth === 60 ? setDrawerWidth(240) : setDrawerWidth(60);
  }

  return (
    <Box sx={ { display: 'flex' } }>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={ {
          width: drawerWidth,
          flexShrink: 0,
          [ `& .MuiDrawer-paper` ]: { width: drawerWidth, boxSizing: 'border-box' },
        } }
      >
        <Toolbar />
        <Box sx={ { overflow: 'auto' } }>
          <List>
            <ListItem key='expand' button onClick={ expandClick }>
              <ListItemIcon>
                { drawerWidth === 60 ? <SidebarIcons item='increase' /> : <SidebarIcons item='decrease' /> }
              </ListItemIcon>
            </ListItem>
            { main.map((item) => (
              <Tooltip key={ item.title } title={ item.title } placement='right'>
                <ListItem button key={ item.title } component='a' href={ item.link }>
                  <ListItemIcon>{ item.icon }</ListItemIcon>
                  <ListItemText primary={ item.title } />
                </ListItem>
              </Tooltip>
            )) }
          </List>
          <Divider />
          <List>
            { ancillary.map((item) => (
              <Tooltip key={ item.title } title={ item.title } placement='right'>
                <ListItem button key={ item.title } component='a' href={ item.link }>
                  <ListItemIcon>{ item.icon }</ListItemIcon>
                  <ListItemText primary={ item.title } />
                </ListItem>
              </Tooltip>
            )) }
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default ClippedDrawer;