
import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

const Waiting: FC = () => {
  return (
    <Grid item xs={ 12 }>
      <Typography variant='h1' sx={{ textAlign: 'center', mt: 5 }}>
        Getting your information...
      </Typography>
    </Grid>
  )
}
export default Waiting;
