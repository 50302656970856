
import { FC } from 'react';
import { InputMultiSelectTags } from './_Input';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AssetFormProps } from './Asset.form';


const AssetSystem: FC<AssetFormProps> = ({ asset, options }) => {
  return (
    <Grid id='system' item xs={ 12 } container spacing={ 0 }>
      <Grid id='system_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>System Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } sx={ { ml: 'auto' } }>
            <InputMultiSelectTags
              name='system.components'
              label='Component Assets'
              options={ options.assets }
            />
          </Grid>
          <Grid item xs={ 12 }>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetSystem;
