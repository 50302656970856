
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText, InputSelect, InputSlider } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import { map } from 'lodash';
import { Classification, ClassificationOptions } from '../../lib/classes';

interface Props {
  classification: Classification;
  options: ClassificationOptions | any;
}

const ClassificationForm: FC<Props> = ({ classification, options }: any) => {
  const methods = useForm({ defaultValues: classification, shouldUnregister: true });
  const { watch } = methods;
  const marks = map(options.classifications, (o) => {
    const value: number = o.restrictive_index;
    const label: string = o.name;
    return { value, label };
  });

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 3 } xs={ 12 }>
              <InputText name='name' label='Classification Name' />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='type'
                label='Type'
                options={ options.type }
                tip
                required
                value_as_label
              />
            </Grid>
            <Grid item md={ 6 } xs={ 12 }>
              <InputText name='description' label='Description' />
            </Grid>
            { watch('type') === 'security' ?
              <Grid item md={ 5 } xs={ 10 }>
                <InputSlider
                  name='restrictive_index'
                  label='Restrictiveness'
                  marks={ marks }
                  min={ 0 }
                  max={ 20 }
                />
              </Grid>
              : null }
            <Grid item xs={ 12 }>
              <InputText name='notes' label='Notes' multiline minRows={3} />
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid>
  )
}

export default ClassificationForm;
