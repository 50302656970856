import { FC, useRef, useState } from 'react';
// import { Document } from '../../lib/classes';
import parse from 'html-react-parser';
import { useReactToPrint } from 'react-to-print';
import Button from '@mui/material/Button';
import PrintTwoTone from '@mui/icons-material/PrintTwoTone';
// import { PDFViewer } from '@react-pdf/renderer';

interface Props {
  document: string;
}

const DocPrintButton: FC<Props> = ({ document }) => {
  const [ showDoc, setShowDoc ] = useState(false)
  const result: any = parse(document);
  // const contentRef = useRef(null);
  // const printStyle = `
  //   @page {
  //     size: letter;
  //     margin: 25mm;
  //     color: black;
  //     // .content {
  //     //   display: none;
  //     // }
  //   }
  //   @media print {
  //     .pagebreak {
  //       page-break-before: always;
  //     }
  //     // .content {
  //     //   display: block;
  //     // }
  //   }
  // `;
  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  //   documentTitle: document.title,
  //   pageStyle: printStyle,
  // });
  const handlePrint = () => {
    setShowDoc(true);
  };
  // console.log(contentRef)

  return (
    <>
    {/* <Button onClick={ handlePrint } startIcon={ <PrintTwoTone /> }>Print</Button>
    { showDoc ?
    <PDFViewer>
      {result}
    </PDFViewer>
    : null } */}
    {/* <div className='content' ref={ contentRef } style={{ display: 'none' }}>
      { parse(document.content) }
    </div> */}
    </>
  )
}

export default DocPrintButton;