
import { FC } from 'react';
import { InputSelect, InputNumber, InputDate, InputSwitch } from './_Input';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AssetFormProps } from './Asset.form';
import { useFormContext } from 'react-hook-form';

// todo: add resilience strategy to this form

const AssetServiceProvider: FC<AssetFormProps> = ({ options }) => {
  const { watch } = useFormContext();
  return (
    <Grid id='service_provider' item xs={ 12 } container spacing={ 2 }>
      <Grid id='service_provider_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>Service Provider Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            {/* <InputSelect
              name='service_provider.provider'
              label='Provider'
              required={ watch('category') === 'software' }
              options={ options.service_provider.provider }
              tip
            /> */}
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='service_provider.type'
              label='Services'
              required
              options={ options.service_provider.type }
              tip
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid id='service_provider_renewal' item container>
        <Grid item xs={ 12 } sx={ { pt: 1, pb: 1 } }>
          <Typography variant='h4'>Renewal</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='service_provider.renewal.frequency'
              label='Frequency'
              required
              options={ options.service_provider.renewal.frequency }
            />
          </Grid>
          {watch('service_provider.renewal.frequency') === 'multiyear' ?
          <Grid item xs={ 12 } md={ 1 }>
            <InputNumber
              name='service_provider.renewal.years'
              label='Years'
              required={watch('service_provider.renewal.frequency') === 'multiyear'}
              min={ 2 }
              max={ 10 }
            />
          </Grid>
          : null}
          {watch('service_provider.renewal.frequency') === 'annual' || watch('service_provider.renewal.frequency') === 'multiyear' ?
          <Grid item xs={ 12 } md={ 2 }>
            <InputSelect
              name='service_provider.renewal.month'
              label='Month'
              required
              options={ options.service_provider.renewal.month }
            />
          </Grid>
          : null}
          {watch('service_provider.renewal.frequency') !== 'per_use' ?
          <Grid item xs={ 12 } md={ 1 }>
            <InputNumber
              name='service_provider.renewal.day'
              label='Day'
              required
              min={ 1 }
              max={ 31 }
            />
          </Grid>
          : null}
          {watch('service_provider.renewal.frequency') === 'annual' || watch('service_provider.renewal.frequency') === 'multiyear' ?
          <Grid item xs={ 12 } md={ 2 }>
            <InputDate
              name='service_provider.renewal.last_payment'
              label='Last Renewal'
              required
            />
          </Grid>
          : null}
          {watch('service_provider.renewal.frequency') !== 'per_use' ?
          <Grid item xs={ 12 } md={ 3 }>
            <InputSwitch
              name='service_provider.renewal.auto'
              label='Automatic Payment'
            />
          </Grid>
          : null}
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetServiceProvider;
