
import { FC } from 'react';
import { InputText, InputMultiSelectTags, InputSelect } from './_Input';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Options } from '../../lib/classes';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import { FormIcons } from '../../Helpers/IconMap.helper';


type InquiriesProps = {
  name: string;
  options: {
    controls: Options[],
    priority: Options[],
  };
}
const InputInquiries: FC<InquiriesProps> = ({ name, options }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name,
  });
  const table_sx = {
    reference: { px: 1, py: 0, width: 100 },
    inquiry: { px: 1, py: 0, width: 900 },
    controls: { px: 1, py: 0 },
    priority: { px: 1, py: 0, width: 150 },
    nuke: { px: 1, py: 0, width: 50 },
  }
  return (
    <Grid item container spacing={ 1 } sx={ { m: 1, width: '100%' } }>
      <Grid id={ `${name}-header` } item xs={ 12 } container spacing={ 2 }>
        <Typography variant='body1'>
          { startCase(name) }
          <span>
            <IconButton color='success' onClick={ () => append({ reference: '', inquiry: '', controls: [], priority: 3 }) }>
              <FormIcons item='add' />
            </IconButton>
          </span>
        </Typography>
      </Grid>
      <Grid id={ `${name}-table` } item xs={ 12 } container spacing={ 2 }>
        <Table size='small' sx={ { width: '100%' } }>
          <TableHead>
            <TableRow>
              <TableCell sx={ table_sx.reference }>Reference</TableCell>
              <TableCell sx={ table_sx.inquiry }>Inquiry</TableCell>
              <TableCell sx={ table_sx.controls }>Controls</TableCell>
              <TableCell sx={ table_sx.priority }>Priority</TableCell>
              <TableCell sx={ table_sx.nuke }></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { fields.map((item: any, i: number) => {
              return (
                <TableRow key={ `${name}-${i}` }>
                  <TableCell sx={ table_sx.reference }>
                    <InputText name={`inquiries.${i}.reference`} label='' table />
                  </TableCell>
                  <TableCell sx={ table_sx.inquiry }>
                    <InputText name={`inquiries.${i}.inquiry`} label='' multiline table />
                  </TableCell>
                  <TableCell sx={ table_sx.controls }>
                    <InputMultiSelectTags
                      sx={{ width: 200 }}
                      name={ `inquiries[${i}].controls` }
                      label=''
                      options={ options.controls }
                      multiline
                      table
                    />
                  </TableCell>
                  <TableCell sx={ table_sx.priority }>
                    <InputSelect
                      name={ `inquiries[${i}].priority` }
                      label=''
                      options={ options.priority }
                      table
                    />
                  </TableCell>
                  <TableCell sx={ table_sx.nuke }>
                    <IconButton color='error' size='small' onClick={ () => remove(i) }>
                      <FormIcons item='nuke' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
export default InputInquiries
