
import { FC } from 'react';
import DataTable, { MUIDataTableColumnDef } from 'mui-datatables';     // https://www.npmjs.com/package/mui-datatables
import { useNavigate } from 'react-router-dom';
import { map, startCase, orderBy, isObject, isArray, isEmpty, isString } from 'lodash';
import { dateFormatted, oddCaps, phoneFormat } from '../../Helpers/Tools.helper';
import TableToolbar from './_Toolbar.table';
import Typography from '@mui/material/Typography';
import pluralize from 'pluralize';
import { TableIcons } from '../../Helpers/IconMap.helper';
import { getCountry, getState } from '../../Helpers/StaticData.helper';

const useTableOptions = (collection: string, total: number) => {
  const navigate = useNavigate();
  const result: any = {
    responsive: 'vertical',
    print: false,
    downloadOptions: {
      filename: `${pluralize(startCase(collection))}_${dateFormatted('today', 'file')}`,
    },
    pagination: false,
    searchPlaceholder: `Find ${pluralize(startCase(collection))}`,
    searchAlwaysOpen: true,
    elevation: 0,
    selectableRows: 'none',
    fixedHeader: true,
    enableNestedDataAccess: '.',
    setTableProps: () => ({ size: 'small' }),
    onRowClick: (data: any, meta: any) => {
      navigate(`/${collection}/${data[ 0 ]}`)
    },
    customToolbar: ({ displayData }: any) => {
      return (<TableToolbar total={ total } qty={ displayData.length } title add />)
    }
  }
  return result;
}
const cco: any = {
  // Common Column Options
  _id: { display: 'excluded' },
  center: {
    // setCellHeaderProps: () => ({ style: { display: 'flex', justifyContent: 'center' } }),
    setCellProps: () => ({ style: { textAlign: 'center' } }),
  },
  center_nowrap: {
    // setCellHeaderProps: () => ({ style: { display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' } }),
    setCellProps: () => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
  },
  center_dense: {
    // setCellHeaderProps: () => ({ style: { padding: '0px 2px', display: 'flex', justifyContent: 'center' } }),
    setCellProps: () => ({ style: { padding: '0px 2px', textAlign: 'center', whiteSpace: 'nowrap' } }),
  },
  dense_field: {
    setCellHeaderProps: () => ({ style: { padding: '0px 2px' } }),
    setCellProps: () => ({ style: { padding: '0px 2px' } }),
  },
}

export const AllAddresses: FC<any> = ({ data }) => {
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const type = startCase(o.type);
    const address = o.street2 ? `${o.street1}, ${o.street2}` : o.street1;
    const city = o.city;
    const state = getState({abbr: o.state});
    const country = getCountry({ value: o.country, value_element: 'tld', response_element: 'proper'})
    return { _id, name, type, address, city, state, country }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'type', label: 'Type' },
    { name: 'address', label: 'Address' },
    { name: 'city', label: 'City' },
    { name: 'state', label: 'State' },
    { name: 'country', label: 'Country' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Addresses</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('address', data.length) }
    />
  )
}
export const AllAssets: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'category', 'name' ], [ 'asc', 'asc' ]);
  const assetOwner = (asset: any) => {
    switch (asset.ownership) {
      case 'user': return asset.owner ? asset.owner.display_name.def : 'Unknown';
      case 'gnci': return 'GNCI';
      case 'service_provider': return asset.hardware?.vm_host ? oddCaps(asset.hardware.vm_host) : 'Unknown';
      default: return oddCaps(asset.ownership);
    }
  }
  const assetType = (asset: any) => {
    if (asset.category === 'hardware' && asset.hardware.vm) return `${oddCaps(asset.hardware.type)} VM`;
    return oddCaps(asset[ asset.category ].type);
  }
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const category = startCase(o.category);
    const type = assetType(o);
    const virtual = o.hardware!.vm ? <TableIcons item='checked' fontSize='small' /> : <TableIcons item='unchecked' fontSize='small' />;
    const device_owner = assetOwner(o);
    const custodian = !isString(o.custodian) ? o.custodian!.display_name.def : 'Unassigned';

    return { _id, name, category, device_owner, custodian, virtual, type }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Asset' },
    { name: 'category', label: 'Category' },
    { name: 'type', label: 'Type' },
    { name: 'device_owner', label: 'Asset Owner' },
    { name: 'custodian', label: 'Asset Manager' },
    { name: 'virtual', label: 'Virtual', options: cco.center },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>Assets</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('asset', data.length) }
    />
  )
}
export const AllClassifications: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'type', 'restrictive_index', 'name' ], [ 'asc', 'desc', 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = startCase(o.name);
    const type = startCase(o.type);
    const description = o.description;
    return { _id, name, type, description }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'type', label: 'Type' },
    { name: 'description', label: 'Description' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Security Classifications & Tags</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('classification', data.length) }
    />
  )
}
export const AllContacts: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'name.last', 'name.first' ], [ 'asc', 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const first = o.name.preferred ? o.name.preferred : o.name.first;
    const last = o.name.last;
    const display_name = o.name.nick ? `${o.display_name.full} (${o.name.nick})` : o.display_name.full;
    const company = o.company.name;
    return { _id, first, last, display_name, company }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'first', label: 'First Name', options: { display: false } },
    { name: 'last', label: 'Last Name', options: { display: false } },
    { name: 'display_name', label: 'Name' },
    { name: 'company', label: 'Company' },
    // { name: 'title', label: 'Title' },
    // { name: 'username', label: 'Email' },
    // { name: 'mobile', label: 'Mobile Number' }
    // {name: 'sms_ok', label: 'SMS Consent'},
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>Contacts</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('contact', data.length) }
    />
  )
}
const control_id = (control: any) => {
  const controlId = (control: any) => {
    const textIndex = String(control.policy_index);
    const suffix = textIndex.length < 2 ? `0${textIndex}` : textIndex;
    return `${control.policy.code}-${suffix}`;
  }
  if (isArray(control)) {
    let result = map(control, (o) => controlId(o));
    return result.join(', ');
  }
  if (isObject(control)) return controlId(control);
  return new Error('Control is neither an Object nor an Array');
}
export const AllControls: FC<any> = ({ data }) => {
  let table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const control_id = o.control_id;
    const objective = o.objective;
    const standard = o.standard;
    const guidelines = o.guidelines.join('; ');
    const requests = o.requests.length;
    const status = oddCaps(o.status.value);
    const mechanisms_status = o.status.mechanisms;
    const control_status = oddCaps(o.status.control);
    // const est_complete = estimatedCompletion(o.status);
    return { _id, name, control_id, objective, standard, guidelines, requests, status, mechanisms_status, control_status /*est_complete*/ }
  });
  table_data = orderBy(table_data, [ 'requests', 'control_id' ], [ 'desc', 'asc' ]);
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'control_id', label: 'Control ID', options: cco.center_nowrap },
    { name: 'objective', label: 'Objective' },
    { name: 'standard', label: 'Standard', options: { display: false } },
    { name: 'guidelines', label: 'Guidelines', options: { display: false } },
    { name: 'requests', label: 'Requests', options: cco.center_nowrap },
    { name: 'status', label: 'Status', options: cco.center },
    { name: 'control_status', label: 'Control Approval', options: { display: false } },
    { name: 'mechanisms_status', label: 'Mechanisms', options: { display: false } },
    // { name: 'est_complete', label: 'Notes'},
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Controls</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('control', table_data.length) }
    />
  )
}
export const AllDocuments: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'type', 'title' ], [ 'desc', 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const title = o.title;
    const type = startCase(o.type);
    const version = o.version;
    const description = o.description;
    return { _id, title, type, version, description }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'title', label: 'Title' },
    { name: 'type', label: 'Type', options: cco.center_nowrap },
    { name: 'version', label: 'Revision', options: cco.center_nowrap },
    { name: 'description', label: 'Description' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>Documents</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('document', data.length) }
    />
  )
}
export const AllJobs: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'index', 'display_title' ], [ 'asc', 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const title = o.display_title;
    const description = o.description;
    return { _id, title, description }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'title', label: 'Title' },
    { name: 'description', label: 'Description' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Positions</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('job', data.length) }
    />
  )
}
export const AllMechanisms: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'name' ], [ 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const controls = isEmpty(o.controls) ? 'None (yet...)' : control_id(o.controls);
    const description = o.description;
    const notes = o.notes
    const status = oddCaps(o.status);
    return { _id, name, controls, description, notes, status }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'controls', label: 'Controls', options: cco.center },
    { name: 'description', label: 'Description' },
    { name: 'status', label: 'Status' },
    { name: 'notes', label: 'Notes', options: { display: false } },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Control Mechanisms</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('mechanism', data.length) }
    />
  )
}
export const AllOrganizations: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'name' ], [ 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const website = o.website;
    const status = startCase(o.status);
    return { _id, name, website, status }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Organization' },
    { name: 'website', label: 'Website' },
    { name: 'status', label: 'Status' },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Organizations</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('organization', data.length) }
    />
  )
}
export const AllPolicies: FC<any> = ({ data }) => {
  // Don't sort this one - they're in the same order as the template
  const table_data = map(data, (o) => {
    const _id = o._id;
    const code = o.code;
    const name = o.name;
    const policy = o.policy;
    const intent = o.management_intent;
    const controls = o.controls.length;
    const requests = o.requests!.length;
    const status = oddCaps(o.status.value);
    const controls_status = o.status.controls;
    const policy_status = oddCaps(o.status.policy);
    return { _id, code, name, policy, intent, controls, requests, status, controls_status, policy_status }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'code', label: 'Code' },
    { name: 'name', label: 'Name' },
    { name: 'policy', label: 'Policy' },
    { name: 'intent', label: 'Management Intent', options: { display: false } },
    { name: 'controls', label: 'Controls', options: cco.center_nowrap },
    { name: 'requests', label: 'Requests', options: { ...cco.center_nowrap, display: false} },
    { name: 'status', label: 'Status' },
    { name: 'policy_status', label: 'Policy Approval', options: { display: false } },
    { name: 'controls_status', label: 'Controls', options: { display: false } },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1' sx={ { paddingLeft: '0px' } }>Policies</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('policy', data.length) }
    />
  )
}
export const AllRequests: FC<any> = ({ data }) => {
  const table_data = map(data, (o) => {
    const _id = o._id;
    const name = o.name;
    const customer = o.customer.name;
    const description = o.description;
    const inquiries = o.inquiries.length;
    return { _id, name, customer, inquiries, description }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'name', label: 'Name' },
    { name: 'customer', label: 'Customer' },
    { name: 'description', label: 'Description' },
    { name: 'inquiries', label: 'Inquiries', options: cco.center },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>Documents</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('request', data.length) }
    />
  )
}
export const AllRoles: FC<any> = ({ data }) => {
  const table_data = map(data, (o) => {
    const _id = o._id;
    const type = startCase(o.type);
    const title = o.title;
    const description = o.description;
    const user_qty = o.users.length;
    return { _id, type, title, description, user_qty }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'type', label: 'Type' },
    { name: 'title', label: 'Title' },
    { name: 'description', label: 'Description' },
    { name: 'user_qty', label: 'Users', options: cco.center },
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>Documents</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('role', data.length) }
    />
  )
}
export const AllUsers: FC<any> = ({ data }) => {
  data = orderBy(data, [ 'name.last', 'name.first' ], [ 'asc', 'asc' ]);
  const table_data = map(data, (o) => {
    const _id = o._id;
    const first = o.name.preferred || o.name.first;
    const last = o.name.last;
    const display_name = o.name.nick ? `${o.display_name.full} (${o.name.nick})` : o.display_name.full;
    const username = o.username;
    const mobile = o.mobile ? phoneFormat(o.mobile.number, o.country, 'text') : 'No Mobile Number';
    const title = o.title;
    return { _id, first, last, display_name, username, mobile, title }
  });
  const columns: MUIDataTableColumnDef[] = [
    { name: '_id', options: cco._id },
    { name: 'first', label: 'First Name', options: { display: false } },
    { name: 'last', label: 'Last Name', options: { display: false } },
    { name: 'display_name', label: 'Name' },
    { name: 'title', label: 'Title' },
    { name: 'username', label: 'Email' },
    { name: 'mobile', label: 'Mobile Number' }
    // {name: 'sms_ok', label: 'SMS Consent'},
  ];
  return (
    <DataTable
      title={ <Typography variant='h1'>GNCI People</Typography> }
      data={ table_data }
      columns={ columns }
      options={ useTableOptions('user', data.length) }
    />
  )
}
