
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Approve } from '../../Services/General.service';
import { InputText, InputSelect, InputDate } from './_Input';
import { Options, Approval } from '../../lib/classes';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';

interface Props {
  approval: Approval;
  options: Options;
  onClose: () => void;
}

const StatusForm: FC<Props> = ({ approval, options, onClose }) => {
  const { collection, id } = useParams();
  const navigate = useNavigate();
  const methods = useForm({ defaultValues: { ...approval, id, collection } })
  const { handleSubmit, watch } = methods;
  const onSubmit = (data: any) => {
    Approve(data).then((data) => {
      const { msg } = data;
      console.log(msg)
      onClose();
      navigate(`/${collection}/${id}`);
    })
  }

  return (
    <Box sx={ { mt: 2 } }>
      <FormProvider { ...methods }>
        <form id={ `${collection}-approval-form` } onSubmit={ handleSubmit(onSubmit) }>
          <InputText name='id' label={ `${collection} ID` } defaultValue={ id } sx={ { display: 'none' } } />
          <InputText name='collection' label='Collection' defaultValue={ collection } sx={ { display: 'none' } } />
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 6 } xs={ 8 }>
              <InputSelect
                name='status'
                label='Status'
                options={ options }
                tip
                required
                value_as_label
              />
            </Grid>
            { watch('status') !== 'pending' ?
              <Grid item md={ 6 } xs={ 12 }>
                <InputDate
                  name='date'
                  label='Active Date'
                  required={ watch('status') !== 'pending' }
                />
              </Grid>
              : null }
            { watch('status') !== 'pending' ?
              <Grid item xs={ 12 }>
                <InputText
                  name='explanation'
                  label={ watch('status') === 'denied' ? 'Denial Reasoning' : 'Approval Reasoning' }
                  multiline minRows={ 2 }
                  required={ watch('status') === 'denied' }
                />
              </Grid>
              : null }
            <Grid item xs={ 12 }>
              <InputText name='notes' label='Notes' multiline minRows={ 2 } />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  )
}

export default StatusForm;
