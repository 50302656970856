
import { FC } from 'react';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { Document, DocumentOptions } from '../../lib/classes';
import { InputText, InputSelect, InputRichText, InputFile } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormAppbar from './_form.Appbar';

interface Props {
  document: Document;
  options: DocumentOptions | any;
}

const DocumentForm: FC<Props> = ({ document, options }: any) => {
  const methods = useForm({ defaultValues: document, shouldUnregister: true });
  const { register, watch, formState } = methods;
  const { dirtyFields } = formState;
  // console.log('isDirty:', isDirty)
  // console.log('dirtyFields:', dirtyFields)
  // const requireComment =
  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        {/* <FormAppbar /> */}
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 4 } xs={ 8 }>
              <InputText label='Title' name='title' required />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='type'
                label='Type'
                options={ options.type }
                tip
                required
                value_as_label
              />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='author'
                label='Author'
                options={ options.author }
                tip
                required
              />
            </Grid>
            {document.version ?
            <Grid item md={ 2 } xs={ 12 }>
              <Typography variant='body1' sx={{ pt: 1, textAlign: 'right' }}>Version {document.version}</Typography>
            </Grid>
            : null }
          </Grid>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <InputText label='Description' name='description' multiline minRows={ 2 } required />
            </Grid>
          </Grid>
          <Grid item xs={ 12 } container spacing={ 2 }>
            {/* <Grid item md={ 4 } xs={ 12 }>
              <input { ...register('file') } type='file' />
            </Grid> */}
            <Grid item md={ 8 } xs={ 12 }>
              <InputText label='Revision Comments' name='comments' required={ !!dirtyFields.content } />
            </Grid>
          </Grid>
          <Grid id='document' item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <InputRichText name='content' />
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default DocumentForm;
