
import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Nuke } from '../../Services/Form.service';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormIcons } from '../../Helpers/IconMap.helper';
import Box from '@mui/material/Box';
import { startCase } from 'lodash';

interface IProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

export const NukeDialog: FC<IProps> = ({ show, setShow }) => {
  const navigate = useNavigate();
  const { collection, id } = useParams();
  const onClose = () => {
    setShow(false);
  };
  const dialogOptions = {
    open: show,
    onClose: onClose,
  };
  const nuke = () => {
    Nuke(collection!, id!).then(data => {
      const { msg } = data;
      console.log(msg)
      navigate(`/${collection}`);
    });
  }

  return (
    <Box id='nuke-dialog'>
      <Dialog { ...dialogOptions }>
        <DialogTitle>
          Do you really want to delete this { startCase(collection) }?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete this { startCase(collection) } permanently.
            Think long and hard before pressing Delete { startCase(collection) }.
            If you'd like to leave it be, click Go Back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ nuke }
            color='error'
            sx={ { float: 'left' } }
            startIcon={ <FormIcons item='nuke' /> }
          >
            Delete { startCase(collection) }
          </Button>
          <Button onClick={ onClose }>Go Back</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
