
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Asset } from '../lib/classes';
import { ListAll } from '../Services/Form.service';
import { AllAssets } from '../Components/Tables/Collections.table'
import Waiting from '../Components/Partials/Waiting';

interface Props {
  assets?: Asset[];
}

const Assets: FC = () => {
  const { collection } = useParams();
  const [ assets, setAssets ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { assets } = data;
      setAssets(assets);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <AllAssets data={ assets } />
        : <Waiting /> }
    </>
  )
}

export default Assets;
