
import { FC } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Controller, useFormContext } from 'react-hook-form';
import { optionsTip, optionsPlain, MultiSelectValuesConvert, oddCaps } from '../../Helpers/Tools.helper';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Slider, { SliderProps } from '@mui/material/Slider';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export { default as InputPhones }  from './_Input.Phones';
export { default as InputEmails }  from './_Input.Emails';
export { default as InputAddresses }  from './_Input.Addresses';
export { default as InputInquiries }  from './_Input.Inquiries';
export { default as InputJobs }  from './_Input.Jobs';
export { default as InputRoles }  from './_Input.Roles';
export { default as InputManagers }  from './_Input.Managers';
export { default as InputRichText }  from './_Input.RichText';

const DATE_FORMAT = 'M/d/yyyy';

interface FormInputProps {
  name: string;
  label: string;
  table?: boolean;
}
type TextInputProps = FormInputProps & TextFieldProps;

export const InputText: FC<TextInputProps> = ({ name, label, table, ...props }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          { ...props }
          inputProps={ { sx: { py: table ? .5 : 1 } } }
          InputLabelProps={ { shrink: table ? false : true } }
          margin='none'
          helperText={ error ? error.message : null }
          size='small'
          error={ !!error }
          onChange={ onChange }
          value={ value }
          fullWidth
          label={ table ? undefined : label }
        />
      ) }
    />
  )
}
type NumberProps = TextInputProps & {
  min?: number;
  max?: number;
}
export const InputNumber: FC<NumberProps> = ({ name, label, min, max, ...props }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          { ...props }
          helperText={ error ? error.message : null }
          size='small'
          error={ !!error }
          onChange={ onChange }
          value={ value }
          fullWidth
          label={ label }
          type='number'
          InputProps={ { inputProps: { min, max } } }
        />
      ) }
    />
  );
};
type SliderInputProps = FormInputProps & SliderProps;
export const InputSlider: FC<SliderInputProps> = ({ name, label, ...props }) => {
  return (
    <Grid item xs={ 12 }>
      <FormLabel component='legend'>{ label }</FormLabel>
      <Controller
        name={ name }
        render={ ({
          field: { value, onChange },
          fieldState,
          formState
        }) => (
          <Slider
            sx={ { ml: 3, mr: 5 } }
            { ...props }
            value={ value }
            onChange={ onChange }
            valueLabelDisplay='auto'
          />
        ) }
      />
    </Grid>
  )
}
export const InputDate: FC<TextInputProps> = ({ name, label, table, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterDateFns }>
      <Controller
        name={ name }
        render={ ({
          field: { onChange, value },
          fieldState: { error },
          formState
        }) => {
          return (
            <DesktopDatePicker
              label={ label }
              showDaysOutsideCurrentMonth
              inputFormat={ DATE_FORMAT }
              value={ value || null }
              onChange={ onChange }
              renderInput={ (params) =>
                <TextField
                  { ...params }
                  { ...props }
                  error={ !!error }
                  size='small'
                  fullWidth
                />
              }
            />
          )
        } }
      />
    </LocalizationProvider>
  );
};
type SwitchInputProps = FormInputProps & SwitchProps;
export const InputSwitch: FC<SwitchInputProps> = ({ name, label, ...props }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => {
        return (
          <FormControlLabel
            label={ label }
            control={
              <Switch
                { ...props }
                onChange={ onChange }
                checked={ value }
              />
            }
          />
        )
      } }
    />
  )
}
type SelectInputProps = TextInputProps & {
  options: any;
  tip?: boolean;
  value_as_label?: boolean;
  none?: boolean;
  table?: boolean;
}
export const InputSelect: FC<SelectInputProps> = ({ name, label, options, tip, value_as_label, none, table, ...props }) => {
  options = tip ? optionsTip({ options, value_as_label }) : optionsPlain({ options, value_as_label });
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          { ...props }
          inputProps={ { sx: { py: table ? .5 : 1 } } }
          InputLabelProps={ { shrink: table ? false : true } }
          size='small'
          helperText={ error ? error.message : null }
          error={ !!error }
          fullWidth
          select
          label={ label }
          value={ value }
          onChange={ onChange }
          onSubmit={ (e) => { alert(e) } }
        >
          { none ? <MenuItem key='none' value={ undefined }>None</MenuItem> : null }
          { options }
        </TextField>
      )
      }
    />
  )
}
export const InputMultiSelectTags: FC<SelectInputProps> = ({ name, label, options, table, value_as_label }) => {
  const TagChip = (props: any) => {
    return (
      <Chip
        { ...props }
        color='default'
        sx={ { borderRadius: 2 } }
        size='small'
      />
    )
  }
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => {
        return (
          <Autocomplete
            multiple
            disableCloseOnSelect
            isOptionEqualToValue={ (option, value) => {
              return (option.value === value)
            } }
            filterSelectedOptions
            size='small'
            value={ value }
            getOptionLabel={ (option) => value_as_label ? oddCaps(option.value) : option.label }
            options={ options }
            onChange={ (e, selection: any) => {
              return onChange(MultiSelectValuesConvert(selection))
            } }
            renderTags={ (
              value: any,
              getTagProps
            ) => {
              return (
                value.map((option: any, index: number) => {
                  const getLabel = () => {
                    const optionObject = options.find((o: any) => o.value === option)
                    return optionObject.label;
                  }
                  const label = value_as_label ? oddCaps(option) : getLabel();
                  return (
                    <TagChip label={ label } { ...getTagProps({ index }) } />
                  )
                })
              )
            } }
            renderInput={ (params) => {
              return (
                <TextField
                  { ...params }
                  InputLabelProps={ { shrink: table ? false : true } }
                  helperText={ error ? error.message : null }
                  error={ !!error }
                  fullWidth
                  label={ label }
                />
              )
            } }
          />
        )
      } }
    />
  )
}
export const InputTextarea: FC<TextInputProps> = ({ name, label, ...props }) => {
  const { setValue } = useFormContext();
  const handleValue = (value: string[]) => {
    const newValue = value.join('\n');
    return newValue;
  }
  const handleChange = (value: string) => {
    const newValue = value.split('\n')
    setValue(name, newValue)
  }
  return (
    <Controller
      name={ name }
      render={ ({
        field: { value },
        fieldState: { error },
        formState,
      }) => {
        return (
          <TextField
            { ...props }
            label={ label }
            fullWidth
            value={ handleValue(value) }
            helperText={ error ? error.message : null }
            error={ !!error }
            onChange={ (e) => handleChange(e.target.value) }
          />
        )
      } }
    />
  )
}
export const InputFile: FC<TextInputProps> = ({ name, label, ...props }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          { ...props }
          type='file'
          helperText={ error ? error.message : null }
          size='small'
          error={ !!error }
          onChange={ onChange }
          value={ value }
          fullWidth
          label={ label }
        />
      ) }
    />
  )
}
type CheckboxLabeledInputProps = { name: string; label: string }
export const InputCheckboxLabeled: FC<CheckboxLabeledInputProps> = ({ name, label }) => {
  return (
    <FormControlLabel
      label={ label }
      control={
        <Controller
          name={ name! }
          render={ ({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => {
            return (
              <Checkbox
                checked={ value }
                onChange={ onChange }
              />
            )
          } }
        />
      }
    />
  )
}
type CheckboxInputProps = { name: string } & CheckboxProps;
export const InputCheckbox: FC<CheckboxInputProps> = ({ name, ...props }) => {
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => {
        return (
          <Checkbox { ...props } checked={ value || false } onChange={ onChange } />
        )
      } }
    />
  )
}
type InputPhoneProps = NumberFormatProps & TextInputProps & {
  table?: boolean; // if true - kills the label that shrinks the label to the top-left of the field
};
export const InputPhone: FC<InputPhoneProps> = ({ name, label, table }) => {
  const DisplayField: FC<TextFieldProps> = ({ ...props }) => {
    return (
      <TextField
        size='small'
        { ...props }
        inputProps={ { sx: { py: table ? .5 : 1 } } }
        InputLabelProps={ { shrink: !table } }
      />
    )
  }
  return (
    <Controller
      name={ name }
      render={ ({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <NumberFormat
          format='(###) ###-####'
          type='tel'
          customInput={ DisplayField }
          helperText={ error ? error.message : null }
          error={ !!error }
          value={ value || '' }
          fullWidth
          label={ label }
          onValueChange={ (val) => { onChange(val.value) } }
        />
      ) }
    />
  )
}
