import { useState, useMemo, createContext, FC, useContext } from 'react';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface IModeContext {
  toggleMode: () => void;
  mode: 'dark' | 'light';
}

const ModeContext = createContext<IModeContext>({
  toggleMode: () => {},
  mode: 'dark',
});
const getMode: any = (defaultValue: IModeContext['mode']) => {
  const stored = localStorage.getItem('mode');
  return stored ? stored : defaultValue;
}
const ThemeContext: FC = ({ children }) => {
  const isClientDark = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const clientMode: IModeContext['mode'] = isClientDark ? 'dark' : 'light';
  const [ mode, setMode ] = useState<IModeContext['mode']>(getMode(clientMode));
  const colorMode = useMemo(
    () => ({
      toggleMode: () => {
        setMode((prevMode) => {
          if (prevMode === 'light') {
            localStorage.setItem('mode', 'dark')
            return 'dark'
          }
          else {
            localStorage.setItem('mode', 'light')
            return 'light'
          }
        });
      },
      mode,
    }), [ mode ]
  )
  const theme = useMemo(
    () => createTheme({
      palette: {
        mode,
      },
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h1: {
          fontSize: '1.6rem',
          fontWeight: 500,
        },
        h2: {
          fontSize: '1.4rem',
          fontWeight: 400,
        },
        h3: {
          fontSize: '1.3rem',
          fontWeight: 400,
        },
        h4: {
          fontSize: '1.2rem',
          fontWeight: 400,
        },
        h5: {
          fontSize: '1.1rem',
          fontWeight: 500,
        },
        h6: {
          fontWeight: 600,
        },
      },
    }), [ mode ]
  );
  return (
    <ModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        { children }
      </ThemeProvider>
    </ModeContext.Provider>
  )
}
// export const themeTableBorderless = () => createTheme({
//   components: {
//     Muidata
//   }
// })

export default ThemeContext;
export const useColorMode = () => useContext(ModeContext);
