
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Role, RoleOptions } from '../../lib/classes';
import { InputText, InputSelect, InputMultiSelectTags } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

interface Props {
  role: Role;
  options: RoleOptions | any;
}

const RoleForm: FC<Props> = ({ role, options }) => {
  const methods = useForm({ defaultValues: role, shouldUnregister: true });
  const { watch } = methods;

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 3 }>
              <InputSelect
                name='type'
                label='Type'
                options={ options.type }
                tip
                required
                value_as_label
              />
            </Grid>
            <Grid item md={ 9 }>
              <InputText label='Description' name='description' />
            </Grid>
          </Grid>
          {watch('type') === 'compliance' ?
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Typography variant='h3'>Compliance</Typography>
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='compliance.category'
                label='Category'
                options={ options.compliance.category }
              />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='compliance.specialty'
                label='Specialty'
                options={ options.compliance.specialty }
              />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputText label='Role Code' name='compliance.role_id' />
            </Grid>
            <Grid item xs={ 12 }>
              <InputText label='Work Description' name='compliance.work' />
            </Grid>
          </Grid>
          : watch('type') === 'keyholder' ?
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Typography variant='h3'>Key Holder</Typography>
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='keyholder.type'
                label='Key Type'
                options={ options.keyholder.category }
              />
            </Grid>
            <Grid item md={ 9 } xs={ 12 }>
              <InputText label='Description' name='keyholder.description' />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputMultiSelectTags
                name='keyholder.specialty'
                label='Specialty'
                options={ options.locations }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <InputText label='Work Description' name='keyholder.work' />
            </Grid>
          </Grid>
          : null}
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default RoleForm;
