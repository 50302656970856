
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MiscIcons } from '../../Helpers/IconMap.helper';
import startCase from 'lodash/startCase';
import StatusForm from '../Forms/Status.form'
import { oddCaps } from '../../Helpers/Tools.helper';
import { useUserContext } from '../../Context/User.context';

interface IProps {
  show: boolean;
  setShow: (value: boolean) => void;
  approval: any;
  options?: any;
}

export const ApprovalDialog: FC<IProps> = ({ show, setShow, approval, options }) => {
  const { user } = useUserContext();

  const { collection } = useParams();
  const onClose = () => {
    setShow(false);
  };
  const dialogOptions = {
    open: show,
    onClose: onClose,
  };

  return (
    <Box id='approval-dialog'>
      <Dialog { ...dialogOptions }>
        <DialogTitle>
          Update { startCase(collection) } Status
        </DialogTitle>
        <DialogContent>
          <Typography textAlign={ 'justify' } variant='body2'>
            Hi { user.name.first }.  You're about to set the approval status for this { oddCaps(collection!) }.  Set the status to 'Pending' when there is a question about whether this { oddCaps(collection!) } will suffice.  Setting the status to 'Approved' means that you're certifying that this { oddCaps(collection!) } is ready for whatever it needs to be ready for (an audit, a customer questionnaire, etc.).
          </Typography>
          <StatusForm approval={ approval } options={ options } onClose={ onClose } />
        </DialogContent>
        <DialogActions>
          <Button onClick={ onClose }>Go Back</Button>
          <Button
            type='submit'
            form={ `${collection}-approval-form` }
            color='success'
            sx={ { float: 'left' } }
            startIcon={ <MiscIcons item='approve' /> }
          >
            Approve { startCase(collection) } Status
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ApprovalDialog
