
import { FC } from 'react';
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import DeveloperBoardTwoToneIcon from '@mui/icons-material/DeveloperBoardTwoTone';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import DangerousTwoToneIcon from '@mui/icons-material/DangerousTwoTone';
import RadarTwoToneIcon from '@mui/icons-material/RadarTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import RoomIcon from '@mui/icons-material/RoomTwoTone';
import BadgeIcon from '@mui/icons-material/BadgeTwoTone';
import SyncProblemIcon from '@mui/icons-material/SyncProblemTwoTone';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportationTwoTone';
import BeachAccessIcon from '@mui/icons-material/BeachAccessTwoTone';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisherTwoTone';
import ContactMailIcon from '@mui/icons-material/ContactMailTwoTone';
import WorkIcon from '@mui/icons-material/WorkTwoTone';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import ControlCameraTwoToneIcon from '@mui/icons-material/ControlCameraTwoTone';
import BusinessIcon from '@mui/icons-material/BusinessTwoTone';
import ClassIcon from '@mui/icons-material/ClassTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import TopicTwoToneIcon from '@mui/icons-material/TopicTwoTone';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ModelTrainingTwoToneIcon from '@mui/icons-material/ModelTrainingTwoTone';
import SaveAltTwoToneIcon from '@mui/icons-material/SaveAltTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import EditTwoTone from '@mui/icons-material/EditTwoTone';
import ArticleTwoTone from '@mui/icons-material/ArticleTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircleTwoTone';
import MailIcon from '@mui/icons-material/MailTwoTone';
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MoreIcon from '@mui/icons-material/MoreVertTwoTone';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncreaseTwoTone';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecreaseTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { get } from 'lodash';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

type IconProps = SvgIconProps & {
  item: string;
}
export const FormIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons: any = {
    add: <AddCircleTwoToneIcon { ...props } />,
    edit: <EditTwoTone { ...props } />,
    read: <ArticleTwoTone { ...props } />,
    save: <SaveAltTwoToneIcon { ...props } />,
    nuke: <DeleteForeverTwoToneIcon { ...props } />
  }
  return get(icons, item);
}
export const TopbarIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons: any = {
    account: <AccountCircle { ...props } />,
    mail: <MailIcon { ...props } />,
    notification: <NotificationsIcon { ...props } />,
    more: <MoreIcon { ...props } />,
    light: <Brightness7Icon { ...props } />,
    dark: <Brightness4Icon { ...props } />,
  }
  return get(icons, item);
}
export const SidebarIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons: any = {
    increase: <FormatIndentIncreaseIcon { ...props } />,
    decrease: <FormatIndentDecreaseIcon { ...props } />,
  }
  return get(icons, item);
}
export const MiscIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons: any = {
    checkmark: <CheckTwoToneIcon { ...props } />,
    info: <InfoTwoToneIcon { ...props } />,
    approve: <ApprovalTwoToneIcon { ...props } />,
  }
  return get(icons, item);
}
export const TableIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons = {
    add: <AddBoxIcon { ...props } />,
    checked: <CheckBoxTwoToneIcon { ...props } />,
    unchecked: <CheckBoxOutlineBlankTwoToneIcon { ...props } />,
  }
  return get(icons, item);
}

type CollectionIconProps = SvgIconProps & {
  collection: string;
  category?: string;
  item?: string;
}
export const CollectionIcons: FC<CollectionIconProps> = ({ collection, category, item, ...props }) => {
  item = item ? item : 'icon';
  const icons = {
    activity: {
      icon: <EngineeringTwoToneIcon { ...props } />,
    },
    address: {
      icon: <RoomIcon { ...props } />,
    },
    asset: {
      icon: <EmojiTransportationIcon { ...props } />,
    },
    classification: {
      icon: <ClassIcon { ...props } />,
    },
    contact: {
      icon: <ContactMailIcon { ...props } />,
    },
    control: {
      icon: <ControlCameraTwoToneIcon { ...props } />,
    },
    document: {
      icon: <ArticleTwoToneIcon { ...props } />,
    },
    incident: {
      icon: <SyncProblemIcon { ...props } />,
    },
    job: {
      icon: <WorkIcon { ...props } />,
    },
    mechanism: {
      icon: <PrecisionManufacturingTwoToneIcon { ...props } />,
      type: {
        physical: <LockTwoToneIcon { ...props } />,
        technical: <DeveloperBoardTwoToneIcon { ...props } />,
        administrative: <SupervisorAccountTwoToneIcon { ...props } />,
      },
      goals: {
        prevent: <DangerousTwoToneIcon { ...props } />,
        detect: <RadarTwoToneIcon { ...props } />,
        correct: <HandymanTwoToneIcon { ...props } />,
      },
    },
    organization: {
      icon: <BusinessIcon { ...props } />,
    },
    policy: {
      icon: <PolicyTwoToneIcon { ...props } />,
    },
    request: {
      icon: <QuestionAnswerTwoToneIcon { ...props } />,
    },
    response: {
      icon: <FireExtinguisherIcon { ...props } />,
    },
    risk: {
      icon: <WarningTwoToneIcon { ...props } />,
    },
    role: {
      icon: <TopicTwoToneIcon { ...props } />,
    },
    service: {
      icon: <RoomServiceTwoToneIcon { ...props } />,
    },
    touch: {
      icon: <ChatBubbleTwoToneIcon { ...props } />,
    },
    training: {
      icon: <ModelTrainingTwoToneIcon { ...props } />,
    },
    user: {
      icon: <BadgeIcon { ...props } />,
    },
  }
  const result = category ? get(icons, [ collection, category, item ]) : get(icons, [ collection, item ]);
  return result;
}
type InfoProps = {
  tip: string;
  placement?: TooltipProps[ 'placement' ];
}
export const InfoIcon: FC<InfoProps> = ({ tip, placement, ...props }) => {
  placement = placement ? placement : 'top-start';
  return (
    <Tooltip title={ tip } placement={ placement }>
      <IconButton sx={ { ml: 1, py: 0 } } size='small'>
        <InfoTwoToneIcon fontSize='small' color='primary' { ...props } />
      </IconButton>
    </Tooltip>
  )
}
export const PageIcons: FC<IconProps> = ({ item, ...props }) => {
  const icons = {
    compliance: <BeachAccessIcon { ...props } />,
  }
  return get(icons, item)
}
