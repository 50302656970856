
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Address } from '../lib/classes';
import { AllAddresses } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';
import Waiting from '../Components/Partials/Waiting';

interface Props {
  addresses: Address[];
}

const Addresses: FC = () => {
  const { collection } = useParams();
  const [ addresses, setAddresses ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { addresses } = data;
      setAddresses(addresses);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <>
          <AllAddresses data={ addresses } />
        </>
        : <Waiting /> }
    </>
  )
}

export default Addresses;
