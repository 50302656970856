
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Address, AddressOptions } from '../../lib/classes';
import { InputText, InputSelect } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
// todo: input field for google maps to pull addresses, geolocation
interface Props {
  address: Address;
  options: AddressOptions | any;
}

const AddressForm: FC<Props> = ({ address, options }: any) => {
  const methods = useForm({ defaultValues: address, shouldUnregister: true });

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item md={ 4 } xs={ 12 }>
              <InputText name='name' label='Name' />
            </Grid>
            <Grid item md={ 3 } xs={ 12 }>
              <InputSelect
                name='type'
                label='Type'
                options={ options.type }
                tip
                value_as_label
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={ 12 } container spacing={ 2 }>
            <Grid item xs={ 4 }>
              <Stack spacing={1}>
                <InputText name='street1' label='Street Address' required />
                <InputText name='street2' label='Address Line 2' />
                <Grid item xs={12} container spacing={0}>
                  <Grid item xs={6}>
                    <InputText name='city' label='City' required />
                  </Grid>
                  <Grid item xs={3}>
                    <InputSelect
                      name='state'
                      label='State'
                      options={ options.state }
                      tip
                      value_as_label
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputText name='zip' label='Zip Code' required />
                  </Grid>
                </Grid>
                    <InputSelect
                      name='country'
                      label='Country'
                      options={ options.country }
                      required
                    />
              </Stack>
            </Grid>
            <Grid item xs={ 8 }>
              <Typography variant='body1'>Put a map in this box...</Typography>
            </Grid>
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default AddressForm;
