
import { FC } from 'react';
import { InputSelect, InputText, InputCheckbox } from './_Input';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Options } from '../../lib/classes';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import { FormIcons } from '../../Helpers/IconMap.helper';


type EmailsProps = {
  name: string;
  options: Options[];
}
const InputEmails: FC<EmailsProps> = ({ name, options }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name,
  })
  return (
    <Grid item container spacing={ 1 } sx={ { m: 1 } }>
      <Grid id={ `${name}-header` } item xs={ 12 } container spacing={ 2 }>
        <Typography variant='body1'>
          { startCase(name) }
          <span>
            <IconButton color='success' onClick={ () => append({ type: '', address: '', preferred: false }) }>
              <FormIcons item='add' />
            </IconButton>
          </span>
        </Typography>
      </Grid>
      <Grid id={ `${name}-table` } item xs={ 12 } container spacing={ 2 }>
        <Table size='small' sx={ { width: '100%' } }>
          <TableHead>
            <TableRow>
              <TableCell sx={ { px: 1, py: 0 } }>Type</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }>Address</TableCell>
              <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>Preferred</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { fields.map((item: any, i: number) => {
              return (
                <TableRow key={ `${name}-${i}` }>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <InputSelect
                      name={ `${name}[${i}].type` }
                      label=''
                      options={ options }
                      table
                      value_as_label
                    />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <InputText name={ `${name}[${i}].address` } label='' table />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>
                    <InputCheckbox name={ `${name}[${i}.preferred` } />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <IconButton color='error' size='small' onClick={ () => remove(i) }>
                      <FormIcons item='nuke' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
export default InputEmails
