
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import pluralize from 'pluralize';
import { TableIcons } from '../../Helpers/IconMap.helper';
import { oddCaps } from '../../Helpers/Tools.helper';

interface IProps {
  qty?: number;
  total?: number;
  title?: string | boolean;
  add?: boolean;
}

const TableToolbar: FC<IProps> = ({ qty, total, title, add }) => {
  const { collection } = useParams();
  const navigate = useNavigate();
  const tableName = () => {
    if (typeof title === 'string') return startCase(title);
    return pluralize(oddCaps(collection!));
  }
  const qtyText = () => {
    if (qty === total) return `${total} ${pluralize(startCase(collection), total)}`;
    return `${qty} of ${total} ${pluralize(startCase(collection), total)}`;
  }
  const onClick = () => {
    return navigate(`/${collection}/add`)
  }
  return (
    <Box display='inline'>
      { add ?
      <Tooltip title={ `Add ${startCase(collection)}` }>
        <IconButton onClick={ onClick }>
          <TableIcons item='add' fontSize='small' />
        </IconButton>
      </Tooltip>
      : null }
      { title ?
      <Typography sx={ { pl: 5, textAlign: 'right', display: 'inline flow-root', fontSize: '2rem' } } variant='h1' color='primary'>{ tableName() }</Typography>
      : null }
      <Typography >{ qtyText() }</Typography>
    </Box>
  )
}

export default TableToolbar;
