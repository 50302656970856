
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import User from './Lists/User.list';
import Policy from './Lists/Policy.list';
import Control from './Lists/Control.list';
import Mechanism from './Lists/Mechanism.list';
import Document from './Lists/Document.list';
import Response from './Lists/Response.list';
import Asset from './Lists/Asset.list';
import Services from './Lists/Service.list';
import Request from './Lists/Request.list';
import Address from './Lists/Address.list';
import Activity from './Lists/Activity.list';
import Job from './Lists/Job.list';
import Organization from './Lists/Organization.list';
import Classification from './Lists/Classification.list';
import Contact from './Lists/Contact.list';
import Role from './Lists/Role.list';
import Box from '@mui/material/Box';

const List: FC = () => {
  const { collection } = useParams();
  return (
    <Box sx={ { m: 1 } }>
      {
        collection === 'user' ?
          <User />
          : collection === 'policy' ?
            <Policy />
            : collection === 'control' ?
              <Control />
              : collection === 'mechanism' ?
                <Mechanism />
                : collection === 'document' ?
                  <Document />
                  : collection === 'request' ?
                    <Request />
                    : collection === 'response' ?
                      <Response />
                      : collection === 'asset' ?
                        <Asset />
                        : collection === 'service' ?
                          <Services />
                          : collection === 'address' ?
                            <Address />
                            : collection === 'activity' ?
                              <Activity />
                              : collection === 'role' ?
                                <Role />
                                : collection === 'job' ?
                                  <Job />
                                  : collection === 'organization' ?
                                    <Organization />
                                    : collection === 'classification' ?
                                      <Classification />
                                      : collection === 'contact' ?
                                        <Contact />
                                        : null
      }
    </Box>
  )
}

export default List;
