
import { control, API_Options } from '../Helpers/Axios.helper';
import { User } from '../lib/classes';
import { Approval } from '../lib/classes';
// todo: figure out how to get the right interface from the collection
// hint = it's not Interfaces[collection]  - maybe pass it from the onSubmit?

export const Authorities = async () => {
  const request: API_Options = {
    url: '/authorities',
    method: 'GET',
  }
  const res = await control(request);
  return res.data;
}
export const ProfileRead = async () => {
  const request: API_Options = {
    url: '/profile',
    method: 'get',
  }
  const res = await control(request);
  return res.data;
}
export const ProfileUpdate = async (data: User) => {
  const request: API_Options = {
    url: '/profile',
    method: 'post',
    data,
  }
  const res = await control(request);
  return res.data;
}
export const Approve = async (data: Approval) => {
  const request: API_Options = {
    url: `/approval`,
    method: 'post',
    data: data,
  }
  const res = await control(request);
  return res.data;
}
export const Utility = async () => {
  const request: API_Options = {
    url: '/utility',
    method: 'get',
  }
  const res = await control(request);
  return res.data;
}
