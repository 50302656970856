
import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Organization } from './lib/classes';
import { Authorities } from './Services/General.service';
import { Link, List, ListItem, ListItemText } from '@mui/material';

const AuthoritiesPage: FC = () => {
  const [ authorities, setAuthorities ] = useState<Organization[]>([]);
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);

  useEffect(() => {
    Authorities().then((data) => {
      setAuthorities(data);
      setIsLoaded(true);
    });
  }, []);

  return (
    <Box sx={ { m: 2 } }>
      <Typography variant='h1' noWrap>Authorities</Typography>
      <List dense disablePadding>
        { authorities.map((authority: Organization) => {
          return (
            <ListItem>
              <Link href={ `/organization/${authority._id}` }>
                <ListItemText
                  primary={ authority.name }
                  secondary={ authority.notes }
                />
              </Link>
            </ListItem>
          )
        }) }
      </List>
    </Box>
  )
}

export default AuthoritiesPage;
