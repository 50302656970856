
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CompliancePage: FC = () => {
    return (
        <Box sx={{ mt: 1 }}>
            <Typography variant='h1' noWrap>Compliance Page</Typography>
            <Typography variant='body1'>Only available to the Governance steering commmittee?  </Typography>
        </Box>
    )
}

export default CompliancePage;
