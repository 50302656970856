
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Policy, PolicyOptions } from '../../lib/classes';
import { InputText, InputSelect } from './_Input';
import FormLayout from './_form.Layout';
import Grid from '@mui/material/Grid';

interface Props {
  policy: Policy;
  options: PolicyOptions | any;
}

const PolicyForm: FC<Props> = ({ policy, options }: any) => {
  const methods = useForm({ defaultValues: policy, shouldUnregister: true });

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormProvider { ...methods }>
        <FormLayout>
          <Grid item md={ 4 } xs={ 8 }>
            <InputText label='Name' name='name' />
          </Grid>
          <Grid item md={ 2 } xs={ 4 }>
            <InputText label='Code' name='code' inputProps={ { maxLength: 3 } } />
          </Grid>
          <Grid item md={ 3 } xs={ 12 }>
            <InputSelect
              name='framework'
              label='Compliance Framework'
              options={ options.framework }
              tip
              required
              value_as_label
            />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='policy' label='Policy' multiline minRows={ 4 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='management_intent' label='Management Intent' multiline minRows={ 2 } />
          </Grid>
          <Grid item xs={ 12 }>
            <InputText name='notes' label='Notes' multiline minRows={ 3 } />
          </Grid>
        </FormLayout>
      </FormProvider>
    </Grid >
  )
}

export default PolicyForm;
