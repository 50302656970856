
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AllPolicies } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';
import { Policy } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';

interface Props {
  policies: Policy[];
}

const Policies: FC = () => {
  const { collection } = useParams();
  const [ policies, setPolicies ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { policies } = data;
      setPolicies(policies);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <AllPolicies data={ policies } />
        : <Waiting /> }
    </>
  )
}

export default Policies;
