
import { control, API_Options } from '../Helpers/Axios.helper';
// todo: figure out how to get the right interface from the collection
// hint = it's not Interfaces[collection]  - maybe pass it from the onSubmit?

interface Props {
  [ key: string ]: any;
}
export const ListAll = async (collection: string) => {
  const request: API_Options = {
    url: `/crud/${collection}`,
    method: 'get',
  }
  const res = await control(request);
  return res.data;
}
export const Create = async (data: Props) => {
  const collection = Object.keys(data)[ 0 ];
  const request: API_Options = {
    url: `/crud/${collection}/add`,
    method: 'put',
    data: data[ collection ]
  }
  const res = await control(request);
  return res.data;
}
export const Read = async (collection: string, id: string) => {
  const request: API_Options = {
    url: `/crud/${collection}/${id}`,
    method: 'get',
  }
  const res = await control(request);
  return res.data;
}
export const Update = async (id: string, data: Props) => {
  const collection = Object.keys(data)[ 0 ];
  const request: API_Options = {
    url: `/crud/${collection}/${id}`,
    method: 'post',
    data: data[ collection ]
  }
  const res = await control(request);
  return res.data;
}
export const Nuke = async (collection: string, id: string) => {
  const request: API_Options = {
    url: `/crud/${collection}/${id}`,
    method: 'delete',
  }
  const res = await control(request);
  return res.data;
}
export const DocumentCreate = async (data: Props) => {
  const request: API_Options = {
    url: '/document',
    method: 'put',
    data: data,
  }
  const res = await control(request);
  return res.data;
}
export const DocumentUpdate = async (id: string, data: Props) => {
  const request: API_Options = {
    url: `/document/${id}`,
    method: 'post',
    data: data,
  }
  const res = await control(request);
  return res.data;
}
