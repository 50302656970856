
import { FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import DocumentForm from '../Components/Forms/Document.form';
import Grid from '@mui/material/Grid';
import { Document, DocumentOptions } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';
import PagerHeader from './_PageHeader';
import Paper from '@mui/material/Paper';
import { Box, Button, SxProps, Typography } from '@mui/material';
import DocPrintButton from '../Components/Buttons/DocPrint.button';
import parse from 'html-react-parser';
import { oddCaps } from '../Helpers/Tools.helper';
import { CssBaseline } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import PrintTwoTone from '@mui/icons-material/PrintTwoTone';

const DocumentPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Document>(new Document());
  const [ edit, setEdit ] = useState<Document>(new Document());
  const [ options, setOptions ] = useState<DocumentOptions>(new DocumentOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const docStyle: SxProps = {
    backgroundColor: 'white',
    color: 'black',
    aspectRatio: 1 / 1.294,
    m: 2,
    p: 8,
  }
  const contentRef = useRef(null);
  const printStyle = `
    @page {
      size: letter;
      margin: 25mm;
      color: black;
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `;
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: read.title,
    pageStyle: printStyle,
  })

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ read.title } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item md={ 5 } xs={ 12 }>
                  <Typography variant='h3'>{ oddCaps(read.type) }: { read.title } <Box fontSize='.7rem'>(v{ read.version })</Box></Typography>
                </Grid>
                <Grid item md={ 7 } xs={ 12 } sx={ { textAlign: 'right' } }>
                  <Typography variant='h4' sx={ { mr: 2 } }>
                    <Typography variant='overline' sx={ { mr: 1 } }>Submitted by:</Typography>
                    { read.author.display_name.full }
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item xs={ 10 }>
                  <Typography sx={ { mb: 2 } }>{ read.description }</Typography>
                </Grid>
                <Grid item xs={ 2 } sx={ { textAlign: 'right', pr: 2 } }>
                  {/* <Button onClick={ handlePrint } startIcon={ <PrintTwoTone /> }>Print</Button> */}
                  <DocPrintButton document={read.content} />
                </Grid>
              </Grid>
              { read.content ?
                <Grid item xs={ 12 }>
                  <Paper className='content' sx={ docStyle } elevation={ 5 }>
                    <CssBaseline />
                    <div ref={ contentRef }>
                      { parse(read.content) }
                    </div>
                  </Paper>
                </Grid>
                : null }
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <DocumentForm document={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default DocumentPage;
