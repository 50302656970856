
import { FC } from 'react';
import { Grid } from '@mui/material';
import { InputSelect, InputMultiSelectTags } from './_Input';
import Typography from '@mui/material/Typography';
import { AssetFormProps } from './Asset.form';

const AssetData: FC<AssetFormProps> = ({ options }) => {
  return (
    <Grid id='data' item container xs={ 12 } spacing={ 2 }>
      <Grid id='data_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>Data Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='data.type'
              label='Data Type'
              required
              options={ options.data.type }
              tip
            />
          </Grid>
          <Grid item xs={ 12 } md={ 9 }>
            <InputMultiSelectTags
              name='data.hosts'
              label='Assets Hosting this Data'
              options={ options.assets }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetData;
