
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import RoleForm from '../Components/Forms/Role.form';
import Waiting from '../Components/Partials/Waiting';
import Grid from '@mui/material/Grid';
import { Asset, Role, RoleOptions, User } from '../lib/classes';
import Paper from '@mui/material/Paper';
import PagerHeader from './_PageHeader';
import { Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import { oddCaps } from '../Helpers/Tools.helper';
import { compliance_roles } from '../Helpers/StaticData.helper';
import { orderBy } from 'lodash';

type Props = {
  read: Role;
  edit: Role;
  options: RoleOptions;
}

const RolePage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Role>(new Role());
  const [ edit, setEdit ] = useState<Role>(new Role());
  const [ options, setOptions ] = useState<RoleOptions>(new RoleOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: Props) => {
      // console.log(data)
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  const headerTitle = (role: Role) => {
    switch (role.type) {
      default: return startCase(role.type);
      case 'compliance': return `${startCase(role.type)} - ${role.compliance!.work}`;
      case 'keyholder': return `${startCase(role.type)} - ${role.keyholder!.type}`;
    }
  }
  const getComplianceValue = (field: 'category' | 'specialty') => {
    const getOptions = options.compliance[ field ];
    const result: any = getOptions.find((option: any) => option.value === read.compliance[ field ]);
    return `${result.label} (${read.compliance[ field ].toUpperCase()})`;
  }
  const getKeytype = () => {
    const result: any = options.keyholder.type.find((type: any) => type.value === read.keyholder.type);
    return result.label
  }
  const getDescription = (field: 'category' | 'specialty') => {
    const getOptions = compliance_roles[ field ];
    const result: any = getOptions.find((option: any) => option.code === read.compliance[ field ]);
    return result.description;
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid container>
          <PagerHeader title={ headerTitle(read) } handler={ readEditHandler } hideForm={ hideForm } />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Typography variant='h2'>Role Type: { oddCaps(read.type) }</Typography>
                <Typography color='primary' variant='body1'>{ read.description }</Typography>
              </Grid>
              { read.type === 'compliance' ?
                <Grid item xs={ 12 } container spacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <Typography variant='overline'><small>Compliance Role ID</small></Typography>
                    <Typography variant='h4'>{ read.compliance.role_id }</Typography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Typography variant='overline'><small>Category</small></Typography>
                    <Typography variant='h4'>{ getComplianceValue('category') }</Typography>
                    <Typography variant='body1'>{ getDescription('category') }</Typography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Typography variant='overline'><small>Specialty</small></Typography>
                    <Typography variant='h4'>{ getComplianceValue('specialty') }</Typography>
                    <Typography variant='body1'>{ getDescription('specialty') }</Typography>
                  </Grid>
                </Grid>
                : read.type === 'keyholder' ?
                  <Grid item xs={ 12 } container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                      <Typography variant='h3'>Key Type: { getKeytype() }</Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                      <Typography color='primary' variant='body1'>{ read.keyholder.description }</Typography>
                    </Grid>
                    { read.keyholder.locations && read.keyholder.locations.length > 0 ?
                      <List dense>
                        { read.keyholder.locations.map((location: Asset) => {
                          <ListItem>
                            <ListItemText
                              primary={ location.name }
                            />
                          </ListItem>
                        }) }
                      </List>
                      : null }
                  </Grid>
                  : null }
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item xs={ 12 }>
                  <Typography variant='h3'>Users in this Role ({ read.users.length })</Typography>
                  <List>
                    { read.users.map((user: User) => {
                      const job: any = orderBy(user.jobs, [ 'start' ], [ 'desc' ])[ 0 ];
                      return (
                        <Link href={ `/user/${user._id}` }>
                          <ListItem>
                            <ListItemText primary={ user.display_name.def } secondary={ user.title } />
                          </ListItem>
                        </Link>
                      )
                    }) }
                  </List>
                </Grid>
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <RoleForm role={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default RolePage;
