
import { useState, useEffect, FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Read } from '../Services/Form.service';
import MechanismForm from '../Components/Forms/Mechanism.form';
import { Control, Mechanism, MechanismOptions, Document, Approval } from '../lib/classes';
import Waiting from '../Components/Partials/Waiting';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import pluralize from 'pluralize';
import { oddCaps } from '../Helpers/Tools.helper';
import PagerHeader from './_PageHeader';
import { CollectionIcons, InfoIcon } from '../Helpers/IconMap.helper';
import ApprovalPart from '../Components/Partials/Approval.partial';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, CssBaseline, SxProps } from '@mui/material';
import parse from 'html-react-parser';
import { useReactToPrint } from 'react-to-print';
import DocPrintButton from '../Components/Buttons/DocPrint.button';
import { ExpandMoreTwoTone, PlusOneTwoTone } from '@mui/icons-material';

const MechanismPage: FC = () => {
  const { collection, id } = useParams();
  const [ read, setRead ] = useState<Mechanism>(new Mechanism());
  const [ edit, setEdit ] = useState<Mechanism>(new Mechanism());
  const [ options, setOptions ] = useState<MechanismOptions>(new MechanismOptions());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ hideForm, setHideForm ] = useState<boolean>(true);

  useEffect(() => {
    Read(collection!, id!).then((data: any) => {
      const { read, edit, options } = data;
      if (read) setRead(read);
      if (edit) setEdit(edit);
      if (options) setOptions(options);
      setIsLoaded(true);
    });
  }, [ collection, id ]);
  const approval: any = read.approval || new Approval();
  const readEditHandler = () => {
    setHideForm(!hideForm);
  }
  // const printStyle = `
  //   @page {
  //     size: letter;
  //     margin: 25mm;
  //     color: black;
  //   }
  //   @media print {
  //     .pagebreak {
  //       page-break-before: always;
  //     }
  //   }
  // `;
  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  //   // documentTitle: read.document.title,
  //   pageStyle: printStyle,
  // })
  const contentRef = useRef(null);
  // console.log('contentRef', contentRef.current)
  const docStyle: SxProps = {
    backgroundColor: 'white',
    color: 'black',
    aspectRatio: 1 / 1.294,
    m: 2,
    p: 8,
  }

  return (
    <Grid container>
      { isLoaded ?
        <Grid item container>
          <PagerHeader
            title={ read.name }
            handler={ readEditHandler }
            hideForm={ hideForm }
            approval={ edit.approval }
            options={ options.approval }
          />
          { hideForm && id !== 'add' ?
            <Grid id='page-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2, pr: 1 } }>
              <Grid item xs={ 12 }>
                <Typography variant='body1' whiteSpace='pre-wrap' color='primary'>{ read.description }</Typography>
                <Typography variant='body2' whiteSpace='pre-wrap'>{ read.notes }</Typography>
              </Grid>
              <Grid item xs={ 12 } container spacing={ 2 }>
                <Grid item md={ 8 } xs={ 12 } container spacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <Typography variant='h3'>
                      Protection Modalities & Aims
                      {/* <InfoIcon tip='test text for info icon' /> */ }
                    </Typography>
                    <Typography variant='body2'>Protection modalities and aims are the 'how' and the 'what', respectively, of a Control Mechanism.  Click on the Information icon above for more information.</Typography>
                  </Grid>
                  <Grid item md={ 6 } xs={ 12 }>
                    <Typography variant='h4'>Modalities</Typography>
                    <List dense disablePadding>
                      { read.type.map((item) => {
                        const object: any = options.type.find((line: any) => line.value === item);
                        const line = { ...object, icon: <CollectionIcons collection={ collection! } category='type' item={ item } fontSize='small' /> };
                        return (
                          <ListItem key={ `type-${line.value}` }>
                            <ListItemIcon>
                              { line.icon }
                            </ListItemIcon>
                            <ListItemText
                              sx={ { my: 0 } }
                              primary={ `${oddCaps(line.value)}` }
                              secondary={ line.label }
                            />
                          </ListItem>
                        )
                      }) }
                    </List>
                  </Grid>
                  <Grid item md={ 6 } xs={ 12 }>
                    <Typography variant='h4'>Aims</Typography>
                    <List dense disablePadding>
                      { read.goals.map((item) => {
                        const object: any = options.goals.find((line: any) => line.value === item);
                        const line = { ...object, icon: <CollectionIcons collection={ collection! } category='goals' item={ item } fontSize='small' /> };
                        return (
                          <ListItem key={ `goals-${line.value}` }>
                            <ListItemIcon>
                              { line.icon }
                            </ListItemIcon>
                            <ListItemText
                              sx={ { my: 0 } }
                              primary={ `${oddCaps(line.value)}` }
                              secondary={ line.label }
                            />
                          </ListItem>
                        )
                      }) }
                    </List>
                  </Grid>
                </Grid>
                <Grid item md={ 4 } xs={ 12 }>
                  { read.documents.length > 0 ?
                    <>
                      <Typography variant='h3'>{ pluralize('Attachment', read.documents.length) } <small>({ read.documents.length })</small></Typography>
                      <List dense disablePadding>
                        { read.documents.map((document: Document) => {
                          return (
                            <ListItem key={ document._id }>
                              <ListItemText
                                sx={ { my: 0 } }
                                primary={ document.title }
                                secondary={ document.description }
                              />
                            </ListItem>
                          )
                        }) }
                      </List>
                    </>
                    : null }
                </Grid>
              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Typography variant='h3'>Controls</Typography>
                <Typography variant='body2'>
                  This Control Mechanism supports { read.controls.length } { pluralize('Control', read.controls.length) }:
                </Typography>
                <List sx={ { pr: 2 } } dense>
                  { read.controls.map((control: Control) => {
                    return (
                      <Link key={ control._id } href={ `/control/${control._id}` } underline='hover'>
                        <ListItem dense disablePadding>
                          <ListItemIcon>
                            <CollectionIcons collection='control' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                            primary={ `${control.name} (${control.control_id})` }
                            secondary={ control.objective }
                          />
                        </ListItem>
                      </Link>
                    )
                  }) }
                </List>
              </Grid>
              { read.documents ?
                <Grid item xs={ 12 }>
                  <Typography variant='h3'>Additional Documentation</Typography>
                  { read.documents.map((document: Document) => {
                    return (
                      <Accordion key={ document.title }>
                        <AccordionSummary expandIcon={ <ExpandMoreTwoTone /> }>
                          <Typography>{ document.title }</Typography>
                        </AccordionSummary>
                        {/* <AccordionActions>
                          <DocPrintButton document={ document } />
                        </AccordionActions> */}
                        <AccordionDetails>
                          <Paper className='content' sx={ docStyle } elevation={ 5 }>
                            <CssBaseline />
                            { parse(document.content) }
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    )
                  }) }
                </Grid>
                : null }
              <Grid item xs={ 12 }>
                <Typography variant='h3'>Approval</Typography>
                <ApprovalPart approval={ approval } />
              </Grid>
            </Grid>
            : null }
          { !hideForm || id === 'add' ?
            <Grid id='form-grid' item xs={ 12 } container spacing={ 2 } component={ Paper } sx={ { mx: 1, pb: 2 } }>
              <MechanismForm mechanism={ edit } options={ options } />
            </Grid>
            : null }
        </Grid>
        : <Waiting /> }
    </Grid>
  )
}

export default MechanismPage;
