
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useUserContext } from './Context/User.context';

const HomePage: FC = () => {
  const { user } = useUserContext();

  return (
    <Box sx={ { m: 2 } }>
      <Typography variant='h1'>{ user.name.first }'s Home Page</Typography>
      <Typography variant='body1'>Need to come up with a dynamic dashboard page based on primary role and maybe job...</Typography>
    </Box>
  )
}

export default HomePage;
