
import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Create, Update, DocumentCreate, DocumentUpdate } from '../../Services/Form.service';
import FormAppbar from './_form.Appbar';

const FormLayout: FC = ({ children }) => {
  const navigate = useNavigate();
  const { collection, id } = useParams();
  const { handleSubmit } = useFormContext();
  const onSubmit = (data: any) => {
    if (id === 'add') {
      return Create({ [ `${collection}` ]: data }).then(data => {
        const { msg } = data;
        navigate(`/${collection}`);
      });
    }
    return Update(id!, { [ `${collection}` ]: data }).then(data => {
      const { msg } = data;
      navigate(`/${collection}`);
    });
  }
  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <FormAppbar />
      <form id={ `${collection}-form` } onSubmit={ handleSubmit(onSubmit) } style={ { width: '100%' } }>
        <Grid sx={ { padding: 1 } } container spacing={ 2 }>
          <Grid item xs={ 12 } container spacing={ 2 }>
            { children }
          </Grid>
        </Grid>
      </form >
    </Grid >
  )
}

export default FormLayout;
