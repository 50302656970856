
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect, FC } from 'react';
import Waiting from '../Components/Partials/Waiting';
import { Response } from '../lib/classes';
// import { AllResponses } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';

interface Props {
  responses?: Response[] | undefined;
}

const Responses: FC = () => {
  const { collection } = useParams();
  const [ responses, setResponses ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { responses } = data;
      setResponses(responses);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <Typography variant='h1'>Responses Page</Typography>
        // <AllResponses data={responses} />
        : <Waiting /> }
    </>
  )
}

export default Responses;
