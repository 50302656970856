
import { useState, FC, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { useFormState, useForm, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import { NukeDialog } from '../Dialogs/Nuke.dialog';
import { FormIcons } from '../../Helpers/IconMap.helper';

// todo: make this sticky

const FormAppbar: FC = () => {
  const { collection, id } = useParams();
  const [ show, setShow ] = useState<boolean>(false);
  const onClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setShow(true);
  }
  const formName = `${collection}-form`;
  const { isDirty } = useFormState();

  return (
    <Grid item xs={ 12 } container spacing={ 2 }>
      <AppBar position='sticky' sx={ { background: 'transparent', boxShadow: 'none' } }>
        <Grid item xs={ 12 } container spacing={ 1 }>
          <Grid item>
            <Button
              sx={ { mx: 1, my: .5 } }
              type='submit'
              form={ formName }
              color='success'
              startIcon={ <FormIcons item='save' /> }
              disabled={ !isDirty }
            >
              Save
            </Button>
          </Grid>
          <Grid item sx={ { ml: 'auto' } }>
            <Button
              sx={ { mr: 3, my: .5 } }
              type='button'
              onClick={ onClick }
              color='error'
              endIcon={ <FormIcons item='nuke' /> }
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        { id && id !== 'add' ?
          <NukeDialog show={ show } setShow={ setShow } />
          : null }
      </AppBar>
    </Grid>
  )
}

export default FormAppbar;
