
import { FC } from 'react';
import { InputSelect, InputPhone, InputCheckbox, InputText } from './_Input';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Options } from '../../lib/classes';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import { FormIcons } from '../../Helpers/IconMap.helper';
import { Box } from '@mui/material';

type PhonesProps = {
  name: string;
  options: Options[];
  type?: 'user' | 'contact';
}
const InputPhones: FC<PhonesProps> = ({ name, options, type }) => {
  type = type ? type : 'user';
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <Grid item container spacing={ 1 } sx={{ m: 1 }}>
      <Grid id={ `${name}-header` } item xs={ 12 } container spacing={ 2 }>
        <Typography variant='body1'>
          { startCase(name) }
        <span>
            <IconButton color='success' onClick={ () => append({ type: '', number: '', preferred: false }) }>
            <FormIcons item='add' />
          </IconButton>
        </span>
        </Typography>
      </Grid>
      <Grid id={ `${name}-table` } item xs={ 12 } container spacing={ 2 }>
        <Table size='small' sx={ { width: '100%', px: 1 } }>
          <TableHead>
            <TableRow>
              <TableCell sx={ { px: 1, py: 0 } }>Type</TableCell>
              <TableCell sx={ { px: 1, py: 0 } }>Number</TableCell>
              <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>Text OK</TableCell>
              { type === 'contact' ? <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>Preferred</TableCell> : null }
              <TableCell sx={ { px: 1, py: 0 } }></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { fields.map((item: any, i: number) => {
              return (
                <TableRow key={ `${name}-${i}` }>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <Box>
                    <InputSelect
                      name={`${name}[${i}].type`}
                      label=''
                      options={ options }
                      table
                      value_as_label
                    />
                    { watch(`${name}[${i}].type`) === 'other' ?
                      <InputText name={`${name}[${i}].other_type`} label='' table />
                    : null}
                    </Box>
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <InputPhone name={`${name}[${i}].number`} label='' table />
                  </TableCell>
                  <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>
                    { watch(`${name}[${i}].type`) === 'mobile' ?
                      <InputCheckbox name={`${name}[${i}].sms_ok`} />
                    : null}
                  </TableCell>
                  { type === 'contact' ?
                  <TableCell sx={ { px: 1, py: 0, textAlign: 'center' } }>
                    <InputCheckbox name={`${name}[${i}].preferred`} />
                  </TableCell>
                  : null }
                  <TableCell sx={ { px: 1, py: 0 } }>
                    <IconButton color='error' size='small' onClick={ () => remove(i) }>
                      <FormIcons item='nuke' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
export default InputPhones;
