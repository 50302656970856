
import { FC } from 'react';
import { InputText, InputSelect } from './_Input';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { startCase } from 'lodash';
import { AssetFormProps } from './Asset.form';
import { useFormContext } from 'react-hook-form';


const AssetSoftware: FC<AssetFormProps> = ({ options }) => {
  const { watch } = useFormContext();
  return (
    <Grid id='software' item xs={ 12 } container spacing={ 2 }>
      <Grid id='software_basics' item container>
        <Grid item xs={ 12 } sx={ { pb: 1 } }>
          <Typography variant='h2'>{ startCase(watch('category')) } Details</Typography>
        </Grid>
        <Grid item xs={ 12 } container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='software.type'
              label='Software Type'
              required={ watch('category') === 'software' }
              options={ options.software.type }
              tip
              value_as_label
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputSelect
              name='software.manufacturer'
              label='Manufacturer'
              required
              options={ options.manufacturer }
              tip
            />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputText name='software.version' label='Version' />
          </Grid>
          <Grid item xs={ 12 } md={ 3 }>
            <InputText name='software.license_key' label='Serial or License Key' />
          </Grid>
          { watch('software.type') === 'operating_system' ?
            <Grid item xs={ 12 } container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 3 }>
                <InputSelect
                  name='software.os.family'
                  label='OS Family'
                  required={ watch('category') === 'software' }
                  options={ options.software.os.family }
                  tip
                  value_as_label
                />
              </Grid>
              <Grid item xs={ 12 } md={ 3 }>
                <InputSelect
                  name='software.os.name'
                  label='OS Name'
                  required={ watch('category') === 'software' }
                  options={ options.software.os.name }
                  tip
                  value_as_label
                />
              </Grid>
            </Grid>
            : null }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetSoftware;
