
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';
import Waiting from '../Components/Partials/Waiting';
import { Service } from '../lib/classes';
// import { AllServices } from '../Components/Tables/Collections.table';
import { ListAll } from '../Services/Form.service';

interface Props {
  services: Service[];
}

const Services: FC = () => {
  const { collection } = useParams();
  const [ services, setServices ] = useState<Props>();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  useEffect(() => {
    ListAll(collection!).then((data: any) => {
      const { services } = data;
      setServices(services);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      { isLoaded ?
        <Typography variant='h1'>Services Page</Typography>
        // <AllServices data={services} />
        : <Waiting /> }
    </>
  )
}

export default Services;
